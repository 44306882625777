import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap"; // Assuming you are using Bootstrap for layout

import "./footer.css";
import facebookIcon from "./icons/facebook.png";
import instagramIcon from "./icons/instagram.png";
import youtubeIcon from "./icons/youtube.png";
import twitterIcon from "./icons/twitter.png";
import linkedinIcon from "./icons/linkedin.png";
import squareLogo from "../pages/student/dashboard/img/pic_3.png";
import appStore from "./icons/appStore.png";
import googlePlay from "./icons/googlePlay.png";
import locationIcon from "./icons/locationIcon.png";

function Footer({ refs }) {
  const dt = new Date();
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#dc3545",
        fontFamily: "'Montserrat', sans-serif",
        color: "#f4f4f4",
      }}
    >
      <section className="mainFooter">
        <div>
          <img src={squareLogo} width="200" alt="TheRightGuru Logo White" />
        </div>
        <div className="my-3" sm={4}>
          <ul>
            <li>
              <Link to="/aboutus" style={{ color: "white" }}>
                About us
              </Link>
            </li>
            <li className="footer_link">
              <Link to="/internship-programme" style={{ color: "white" }}>
                Internship Programme
              </Link>
            </li>
            <li className="footer_link">
              <Link to="/trg-franchise" style={{ color: "white" }}>
                TRG Franchise
              </Link>
            </li>
            <li className="footer_link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://therightguru-website.s3.ap-south-1.amazonaws.com/TnC.pdf"
                style={{ color: "white" }}
              >
                Terms & Conditions
              </a>
            </li>
            <li className="footer_link">
              <Link to="/privacy" style={{ color: "white" }}>
                Privacy Policy
              </Link>
            </li>
            <li className="footer_link">
              <Link to="/refund-cancellation-policy" style={{ color: "white" }}>
                Refund Policy
              </Link>
            </li>
            <li className="footer_link">
              {/* <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeVVpq96KWOQBdLYbkz5pT83ztSu_en6Z5cTqJanG-BZ1VX1w/viewform"
                  style={{ color: "white" }}
                >
                  Careers
                </a> */}
              <Link to="/career" style={{ color: "white" }}>
                Career
              </Link>
            </li>
            <li className="footer_link">
              <Link to="/faqs" style={{ color: "white" }}>
                FAQs
              </Link>
            </li>
            <li>
              <Link to="/contact-us" style={{ color: "white" }}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="my-3" sm={2}>
          <h3 style={{ fontWeight: "600", color: "#fff" }}>Courses</h3>

          <li className="footer_link">
            <Link to="/cbse-dbse-icse-others" style={{ color: "white" }}>
              CBSE/DBSE/ICSE/OTHERS BOARDS
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/ssc" style={{ color: "white" }}>
              SSC
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/cuet" style={{ color: "white" }}>
              CUET
            </Link>
          </li>

          <li className="footer_link">
            <Link to="/clat" style={{ color: "white" }}>
              CLAT
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/dsssb" style={{ color: "white" }}>
              DSSSB
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/nda-cds" style={{ color: "white" }}>
              NDA & CDS
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/acturial-science" style={{ color: "white" }}>
              ACTURIAL SCIENCE
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/gre-gmat-sat" style={{ color: "white" }}>
              GRE, GMAT & SAT
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/police-services" style={{ color: "white" }}>
              POLICE SERVICES
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/english-speaking" style={{ color: "white" }}>
              ENGLISH SPEAKING COURSE
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/health-recreation" style={{ color: "white" }}>
              HEALTH RECREATION
            </Link>
          </li>
          <li className="footer_link">
            <Link to="/other-competitive-services" style={{ color: "white" }}>
              OTHER COMPETITIVE SERVICES
            </Link>
          </li>
        </div>

        <div className="my-3" sm={2}>
          <h3 style={{ fontWeight: "600", color: "#fff" }}>Follow Us</h3>
          <div style={{ marginTop: "24px" }}>
            <a
              href="https://www.facebook.com/The-Right-Guru-101006872301721"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="footer_icons"
                src={facebookIcon}
                width="20"
                alt="Facebook Icon"
              />
            </a>
            <a
              href="https://www.instagram.com/therightguru/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="footer_icons"
                src={instagramIcon}
                width="20"
                alt="Instagram Icon"
              />
            </a>
            <a
              href="https://twitter.com/The_Right_Guru"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="footer_icons"
                src={twitterIcon}
                width="20"
                alt="Twitter Icon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/the-right-guru/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="footer_icons"
                src={linkedinIcon}
                width="20"
                alt="Linkedin Icon"
              />
            </a>
            <div style={{ marginTop: "20px" }}>
              <li>
                <a
                  href="https://bit.ly/trg-playstore"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={googlePlay}
                    width="130"
                    alt="Google Play Store Link"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://bit.ly/trg-appstore"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={appStore}
                    width="130"
                    height="40"
                    style={{ marginTop: "10px" }}
                    alt="App Store Link"
                  />
                </a>
              </li>
            </div>
          </div>
        </div>
      </section>
      <div
        className="d-flex justify-content-evenly pl-5 pr-5 pt-4 pb-4"
        style={{ fontWeight: "500" }}
      >
        <div>
          <span>&copy;</span> {dt.getFullYear()} Right Guru.com Private Limited,
          All Rights Reserved.
        </div>
        <div>
          Made with <span style={{ color: "#f4f4f4" }}>&#9829;</span> in India.
        </div>
      </div>
    </Container>
  );
}

export default Footer;
