import React, { useState, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import { Button } from "react-bootstrap";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import CustomNav from "../../../components/header/Navbar";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import Footer from "../../../components/Footer";

Modal.setAppElement("#root");

function Lead() {
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;

  const [activeForm, setActiveForm] = useState(
    currentPath.includes("lead-form") ? "lead" : "trial"
  );

  const switchToLeadForm = () => {
    setActiveForm("lead");
    history.push("/lead-form");
  };

  const switchToTrialForm = () => {
    setActiveForm("trial");
    history.push("/trial-student-form");
  };

  // Form state
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("New");
  const [exam, setExam] = useState("");
  const [qualification, setQualification] = useState("");

  const handleSubmit = async () => {
    // Form validation
    if (!validator.isEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!name || !phone) {
      toast.error("Name and Phone are required fields.");
      return;
    }

    if (phone.length !== 10 || !/^\d+$/.test(phone)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }

    const leadData = {
      name,
      phone,
      email,
      status,
      exam,
      qualification,
    };

    try {
      // Submit the form data to the API
      const response = await axios.post(
        "https://api.therightguru.com/api/lead-form",
        leadData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Success toast notification
      toast.success("Lead form submitted successfully!");
      console.log("API Response:", response.data);

      // Redirect or reset form after successful submission
      setName("");
      setPhone("");
      setEmail("");
      setStatus("New");

      setExam("");
      setQualification("");
    } catch (error) {
      // Error toast notification
      toast.error("Failed to submit the lead form. Please try again.");
      console.error("Error submitting lead form:");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <section>
          <CustomNav Open={closeModal} />
        </section>
        <section
          style={{
            width: "100%",
            display: "flex",
            paddingTop: "40px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <button
              className={`toggleButton ${
                activeForm === "trial" ? "activeButton" : ""
              }`}
              onClick={switchToTrialForm}
            >
              School
            </button>
            <button
              className={`toggleButton ${
                activeForm === "lead" ? "activeButton" : ""
              }`}
              onClick={switchToLeadForm}
            >
              Competitive exams
            </button>
          </div>
        </section>

        <div className="card-011-1">
          <div className="row">
            <h1>TRIAL FORM</h1>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <input
                style={{ height: "45px" }}
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <input
                style={{ height: "45px" }}
                type="text"
                placeholder="Phone"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "").slice(0, 10);
                  setPhone(value);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <input
                style={{ height: "45px" }}
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <input
                style={{ height: "45px" }}
                type="text"
                placeholder="Exam"
                value={exam}
                onChange={(e) => setExam(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <input
                style={{ height: "45px" }}
                type="text"
                placeholder="Qualification"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-danger submit"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontWeight: "600",
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <ToastContainer
          style={{
            fontFamily: "'Montserrat', Helvetica",
            fontWeight: 500,
            padding: "10px",
          }}
        />
        <section id="footer">
          <Footer />
        </section>
        <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
        <Popup2 />
      </div>
    </>
  );
}

export default Lead;
