import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import vector_1_2x from "./img/vector-1@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";
import axios from "axios";
import CalenderStatus from "../dashboard/CalenderComponent/calanderComponent";
import {
  teacherPreviousClasses,
  upcomingClass,
} from "../../../crud/teacher.crud";
import moment from "moment";
import pic_2 from "../../student/dashboard/img/pic_2.png";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";
import { notification } from "antd";




const ClassTable = ({ title, data }) => {
  return (
    <div className="table-container">
      <h3 className="table-title">{title}</h3>
      <div className="table-wrapper">
        <table className="styled-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Class Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.class_type}</td>
                  <td>{moment(item.datetime).format("DD-MM-YYYY")}</td>
                  <td>{moment(item.datetime).format("HH:mm:ss")}</td>
                  <td>{item.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="no-data">No previous classes found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

  );
};

const ClassTableWithAction = ({ title, data, setIsUpdated }) => {


  const startClass = (upcomingClasses) => {
    console.log("Upcoming Class Data:", upcomingClasses);
  
    if (!upcomingClasses || !upcomingClasses.datetime) {
      alert("No class data available.");
      return;
    }
  
    let classDate = moment.utc(upcomingClasses.datetime).subtract(10, "minutes");
  
    console.log("Class Start Time (UTC):", upcomingClasses.datetime);
    console.log("Class Can Start After (UTC):", classDate.format());
    console.log("Current Time (UTC):", moment.utc().format());
  
    if (moment.utc().isBefore(classDate)) {
      alert("Can't start class before 10 minutes of start time.");
      return;
    }
  
    if (upcomingClasses.meeting_link && upcomingClasses.meeting_link.startsWith("http")) {
      window.open(upcomingClasses.meeting_link, "_blank");
    } else {
      alert("Invalid or missing meeting link.");
    }
  };
  
  const handleAction = async (item, action) => {
    try {
      // Ensure required data exists before making the request
      if (!item.class_type) {
        throw new Error("Missing class_type in item");
      }
      if (action === "Join") {
        startClass(item);
      } else {


        const classData = {
          class_type: item.class_type,
          teacher_id: item.teacher_id,
          datetime: item.datetime,
          status: action,
          students: item.students,
          meeting_link: item.meetingLink,
        };

        const response = await axios.put(
          `https://therightguru.com/api/class-student/${item.id}`,
          classData
        );

        if (response.status === 200) {
          setIsUpdated(true);
          notification.success({ message: "Class status updated successfully!" });
        } else {
          notification.error({ message: "Failed to update class status" });
        }

      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred";
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.error || JSON.stringify(error.response.data);
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else {
        errorMessage = error.message;
      }

      console.error("Error updating class status:", errorMessage);
      notification.error({
        message: "Failed to update class status",
        description: errorMessage,
      });
    }
  };


  return (
    <div className="table-container">
      <h3 className="table-title">{title}</h3>
      <div className="table-wrapper">
        <table className="styled-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Class Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={item.id || index}>
                  <td>{index + 1}</td>
                  <td>{item.class_type}</td>
                  <td>{moment(item.datetime).format("DD-MM-YYYY")}</td>
                  <td>{moment(item.datetime).format("HH:mm:ss")}</td>
                  <td>{item.status}</td>
                  <td>
                    <button
                      className="action-btn complete-btn"
                      onClick={() => handleAction(item, "Join")}
                    >
                      Join
                    </button>
                    <button
                      className="action-btn cancel-btn"
                      onClick={() => handleAction(item, "Cancelled")}
                    >
                      Cancel
                    </button>
                    <button
                      className="action-btn complete-btn"
                      onClick={() => handleAction(item, "Completed")}
                    >
                      Complete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-data">No UpComing classes found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function Teacherpreviousclasses(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const [classes, setClasses] = useState([]);
  const [upClass, setUpClass] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    teacherPreviousClasses(reduxUser.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setClasses(false);
        } else setClasses(res);
      })
      .catch((err) => console.log(err));

    upcomingClass(reduxUser.teacher_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setUpClass(false);
        } else setUpClass(res);
      })
      .catch((err) => console.log(err));

    setIsUpdated(false);
  }, [isUpdated]);

  const user = useSelector((state) => state.teacherDetails.teacher);
  let upcomingClasses = [];

  if (Array.isArray(upClass)) {
    upcomingClasses = upClass.filter((cls) => {
      const classTime = moment(cls.datetime);
      return classTime.isAfter(moment().subtract(10, "minutes"));
    });
  }
  const startClass = () => {
    if (!upcomingClasses.length) {
      alert("No class data available.");
      return;
    }

    console.log(moment(upcomingClasses[0].datetime));

    // Subtract 10 minutes from class start time
    let class_date = moment(upcomingClasses[0].datetime).subtract(10, "minutes");

    console.log(class_date);

    if (moment().isBefore(class_date)) {
      alert("Can't start class before 10 minutes of start time.");
    } else {
      if (upcomingClasses[0].meeting_link) {
        window.open(upcomingClasses[0].meeting_link, "_blank");
      }
    }
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacherpreviousclasses"
      />
      <div className="container-center-horizontal">
        <div className="teacher-previousclasses-original screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon data-tip="Scheduler" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color-2"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>

          <div className="flex-col-C61RwL">
            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img
                  alt="image"
                  src={pic_2}
                  style={{ height: "115px", marginTop: "-8%" }}
                />
              </div>
              <div className="group-115-GyJUNH">
                <div className="group-22-3boKt2">
                  <div className="flex-col-R6Ddt1">
                    <div className="biba-sobti-2jrdHj">
                      {user.first_name + " " + user.last_name}
                    </div>
                    <div className="bibasobtigmailcom-2jrdHj">
                      {user.emailID}
                    </div>
                  </div>
                  <img className="vector-R6Ddt1" src={vector_1_2x} />
                </div>
              </div>
            </div>
            <div className="flex-row-oV5UrE">
              <div className="flex-col-QkTteQ">
                <div className="flex-row-Fjgz1C">
                  <div className="group-126-A6SpMB">
                    <div className="group-43-UDxQs2">
                      <div className="group-41-gGlKTJ">
                        <div className="group-40-yr0SVl">
                          <div className="group-39-gLH3zI">
                            <div className="group-38-j7ZAdN"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {upcomingClasses.length > 0 && (
                      <>
                        <div className="upcoming-class-UDxQs2">Upcoming Class</div>
                        <div className="mathematics-section-g-UDxQs2">
                          <span className="span0-68314r">
                            {upcomingClasses[0].subject_id} -{" "}
                            <span style={{ color: "#F98131" }}>
                              {upcomingClasses[0].class_type}
                            </span>
                          </span>
                        </div>
                        <div className="friday-UDxQs2">
                          {moment(upcomingClasses[0].datetime).format("dddd").toUpperCase()}
                        </div>
                        <div className="x15-january-UDxQs2">
                          {moment(upcomingClasses[0].datetime).format("LL").toUpperCase()}
                        </div>
                        <div className="x0800-am-0900-am-UDxQs2" style={{ marginTop: "16px" }}>
                          {moment(upcomingClasses[0].datetime).format("HH:mm")}
                        </div>
                        <div className="join-class-UDxQs2" onClick={() => startClass()}>
                          Join Class
                        </div>
                      </>
                    )}

                  </div>
                </div>


                <ClassTableWithAction title="Upcoming Classes" data={upClass} setIsUpdated={setIsUpdated} />
                <ClassTable title="Previous Classes" data={classes} />

              </div>
              <div className="group-114-QkTteQ">
                <CalenderStatus />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacherpreviousclasses screen">
          <div className="flex-row-C61RwL">
            <div className="component-1-dXBp89">
              <img
                alt="image"
                src={pic_2}
                style={{ height: "90px", marginTop: "-8%" }}
              />
            </div>

            {/* this is the Navbar */}

            <SideDrawer
              open={displaySideDrawer}
              closeSideDrawer={sideDrawerToggler}
            />
            <div className="component-6-dXBp89" onClick={sideDrawerToggler}>
              <div className="rectangle-48-X4qzjs"></div>
              <div className="rectangle-49-X4qzjs"></div>
              <div className="rectangle-50-X4qzjs"></div>
            </div>
          </div>
          <div className="group-125-C61RwL">
            <div className="group-124-kWlObw">
              <div className="group-41-e1mbBS">
                <div className="group-40-7vmrSC">
                  <div className="group-39-agxh6K">
                    <div className="group-38-fXlfDP"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="total-classes-kWlObw montserrat-semi-bold-blue-zodiac-20px">Total Classes -</div>
            <div className="x43-kWlObw">43</div>
            <div className="average-rating-kWlObw montserrat-semi-bold-blue-zodiac-20px">Average Rating -</div>
            <div className="x43-5-kWlObw"><span className="span0-KlKwew">4.3</span><span className="span1-KlKwew"> / 5</span></div>
            <div className="attendance-kWlObw montserrat-semi-bold-blue-zodiac-20px">Attendance -</div>
            <div className="x97-kWlObw">97%</div>
            <div className="number-of-classes-per-day-kWlObw montserrat-semi-bold-blue-zodiac-20px">
              Number of Classes Per Day -
          </div>
            <div className="x7-kWlObw">7</div> */}
          </div>
          <div className="group-83-C61RwL">
            {upcomingClasses.length > 0 && (
              <>
                <div className="group-15-jQ4qbJ">
                  <div className="rectangle-30-V5d4cE"></div>

                  <div className="mathematcs-section-g-V5d4cE">
                    <span className="span0-Xx53BE">
                      {upcomingClasses && upcomingClasses[0].subject_id} -{" "}
                      <span style={{ color: "#F98131" }}>
                        {upcomingClasses && upcomingClasses[0].class_type}
                      </span>
                    </span>
                    {/* <span className="span1-Xx53BE">Section G</span> */}
                  </div>
                  <div className="upcoming-class-V5d4cE montserrat-medium-blue-zodiac-18px">
                    Upcoming Class
                  </div>

                </div>

                <div className="friday-jQ4qbJ">
                  {upcomingClasses && moment(upcomingClasses[0].datetime).format("dddd").toUpperCase()}
                </div>
                <div className="x15-january-jQ4qbJ">
                  {upcomingClasses && moment(upcomingClasses[0].datetime).format("LL").toUpperCase()}
                </div>
                <div className="x0800-am-0900-am-jQ4qbJ">
                  {upcomingClasses && moment(upcomingClasses[0].datetime).format("HH:mm")}
                </div>
                <div className="rectangle-51-jQ4qbJ border-1px-blue-zodiac"></div>
                <div className="join-class-jQ4qbJ" onClick={() => startClass()}>
                  Join Class
                </div>
              </>
            )}
          </div>
          <ClassTableWithAction title="Upcoming Classes" data={upClass} setIsUpdated={setIsUpdated} />
          <ClassTable title="Previous Classes" data={classes} />
          <div className="group-135-C61RwL">
            <CalenderStatus />
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

export default Teacherpreviousclasses;
