import React, { useLayoutEffect, useState } from "react";
import styles from "./policy.module.css";
import { Link } from "react-router-dom";

import CustomNav from "../../../components/header/Navbar";
import Footer from "../../../components/Footer";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";

const RefundPolicy = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }
  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  return (
    <div style={{ width: "100%" }}>
      <section style={{ width: "100%" }}>
        <CustomNav Open={closeModal} />
      </section>
      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>REFUND & CANCELLATION POLICY</h1>
        </div>
        <div className={styles.sections}>
          <h2>INTRODUCTION</h2>
          <p>
            At TRG Hybrid Tuition Platform (TRG), we strive to provide
            high-quality educational services and would not wish to get to the
            juncture of refund. However, we understand and appreciate sometimes
            plans change inevitably, and you may need to cancel or request a
            refund for our untaken services. This policy outlines our terms and
            conditions for cancellations and refunds.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>CANCELLATION POLICY</h2>
          <div>
            <h3>BEFORE COURSE COMMENCEMENT</h3>

            <li>
              You can cancel your enrolment at any time before the course
              begins-meaning you have not taken any class from TRG.
            </li>
            <li>
              For point a. above you need to cancel at least 7 days before the
              start date of the course and you are eligible for a full refund.
            </li>
            <li>
              Cancellations made within 7 days of the course start date will
              incur a cancellation fee of 10% of the total course fee (not just
              of the payment you made).
            </li>
          </div>
          <div>
            <h3>AFTER COURSE COMMENCEMENT</h3>

            <li>
              Cancellations will not be possible (because TRG would have
              incurred expenses and made subsequent engagements) once the course
              has commenced.
            </li>
            <li>
              In exceptional cases (e.g., medical emergencies), partial refunds
              may be considered at the discretion of TRG management- will be
              decided on case-to-case basis.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>REFUND POLICY</h2>
          <div>
            <h3>ELIGIBILITY FOR REFUNDS:</h3>

            <li>
              Refunds are only available for cancellations made before the
              commencement of course as per the cancellation policy above.
            </li>
          </div>
          <div>
            <h3>PROCESS FOR REFUNDS:</h3>

            <li>
              To request a refund, please contact our customer support team at
              contact@therightguru.com with your enrolment details and clearly
              describing reason for cancellation- with acceptable evidence.
            </li>
            <li>
              Upon receipt of a valid request- refund request will normally be
              processed within 14 business days from the date of request.
            </li>
            <li>
              Refunds will only be issued in the original payment method used
              during enrolment.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>TERMS AND CONDITIONS</h2>
          <div>
            <h3>NON-REFUNDABLE FEES</h3>

            <li>
              Registration fees, if applicable, are non-refundable in any
              circumstances.
            </li>
            <li>
              Any materials or resources provided (electronically or physically)
              and used during the course are non-refundable.
            </li>
          </div>
          <div>
            <h3>PARTIAL ATTENDANCE</h3>

            <li>
              No refunds shall be provided for partial attendance or incomplete
              use of the course.
            </li>
          </div>
          <div>
            <h3>COURSE CHANGES AND RESCHEDULING</h3>
            <li>
              Where circumstances dictate, TRG reserves the right to reschedule
              or cancel courses if inevitably necessary. In such cases, you will
              be offered an alternative course or a prorated refund.
            </li>
          </div>
          <div>
            <h3>TRANSFER OF ENROLMENT</h3>
            <li>
              Enrolments are non-transferable to other individuals or different
              courses once confirmed.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CONTACT INFORMATION</h2>
          <p>
            For any questions or concerns regarding our refund and cancellation
            policy, please contact us at email{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@therightguru.com"
            >
              contact@therightguru.com
            </a>
            .
          </p>
        </div>
        <div className={styles.sections}>
          <h2>CHANGES TO THIS POLICY</h2>
          <p>
            TRG reserves the right to update or change this refund and
            cancellation policy at any time. Any changes will be posted on our
            platform, and you will be notified via email.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>EFFECTIVE DATE</h2>
          <div>
            <p>
              This Refund & Cancellation Policy is effective from 01 April 2024.
            </p>
            <p>
              This policy ensures transparency and clarity regarding our refund
              and cancellation procedures, helping to manage expectations and
              provide a fair resolution process.
            </p>
          </div>
        </div>
      </section>
      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
};

export default RefundPolicy;
