import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import styles from "./common_css.module.css";
Modal.setAppElement("#root");

function EngSpeak() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>TRG HYBRID ENGLISH SPEAKING COURSES</h1>
        </div>
        <div className={styles.sections}>
          <h3 style={{ textAlign: "center" }}>
            Enroll in English Speaking Courses for Comprehensive Preparation!
          </h3>
        </div>
        <div className={styles.sections}>
          <div className={styles.infoBox}>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Class Timing
                </h2>
              </div>
              <p style={{ fontWeight: "600", textAlign: "center" }}>
                8:00 AM to 8:00 PM
              </p>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Hybrid English Classes
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  margin: "auto",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "600" }}>Any Level</p>
                <p style={{ fontWeight: "600" }}>(Home Tuition)</p>
                <p style={{ fontWeight: "600" }}>Any time</p>
              </div>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Contact Us
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  margin: "auto",
                }}
              >
                <p style={{ fontWeight: "600" }}>9570-424242</p>
                <p style={{ fontWeight: "600" }}>74-1919-7878</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sections} style={{ alignItems: "center" }}>
          <div className={styles.btnDiv}>
            <Link to="/trial-student-form">
              <h2 style={{ color: "white" }} className={styles.book_btn}>
                Book Free Trial
              </h2>
            </Link>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>TRG Hybrid Classes: English Speaking Courses</h2>
          <div className={styles.innerSection}>
            <p>
              TRG Hybrid Classes offer comprehensive English-Speaking Courses
              designed to enhance your proficiency in speaking, listening,
              reading, and writing. Available at beginner, intermediate, and
              advanced levels, the courses use a hybrid model combining online
              interactive sessions with offline support. Experienced instructors
              provide personalized learning paths, focusing on practical usage
              and real-life communication scenarios.{" "}
              <span className={styles.bold}>
                SPECIAL PROGRAMS INCLUDE BASIC ENGLISH SPEAKING, BUSINESS
                ENGLISH, ACADEMIC ENGLISH, AND CONVERSATIONAL ENGLISH,{" "}
              </span>{" "}
              catering to diverse needs. Flexible scheduling and certification
              upon completion make these courses ideal for students,
              professionals, and anyone aiming to improve their English skills.
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Overview of English-Speaking Courses at TRG Hybrid Classes</h3>
          </div>
          <div className={styles.innerSection}>
            <h3>Course Objectives</h3>
            <p>
              The English-Speaking Courses at TRG Hybrid Classes are designed to
              help learners improve their English language proficiency. The
              courses aim to enhance listening, speaking, reading, and writing
              skills, focusing on practical usage for daily communication,
              professional interactions, and academic purposes.
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Course Levels</h3>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>1. Beginner Level:</p>
            <li>
              Focus: Basic grammar, vocabulary building, and simple
              conversational skills.
            </li>
            <li>
              Ideal for: Learners with little to no prior knowledge of English.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>2. Intermediate Level:</p>
            <li>
              Focus: Intermediate grammar, expanded vocabulary, and more complex
              conversational skills.
            </li>
            <li>
              Ideal for: Learners with a basic understanding of English looking
              to improve fluency and accuracy.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>3. Advanced Level:</p>
            <li>
              Focus: Advanced grammar, extensive vocabulary, and high-level
              conversational and writing skills.
            </li>
            <li>
              Ideal for: Learners with a good command of English aiming for
              proficiency and nuance in communication.
            </li>
          </div>
          <div className={styles.innerSection}>
            <h3>Focal Points</h3>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>1. Hybrid Learning Model:</p>
            <li>
              Online Classes: Live interactive sessions, recorded lectures, and
              online resources.
            </li>
            <li>
              Offline Support: Regular in-person workshops and practice sessions
              for personalized guidance.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>2. Interactive Lessons:</p>
            <li>
              Role-plays, group discussions, and presentations to enhance
              speaking confidence.
            </li>
            <li>
              Listening exercises using multimedia resources to improve
              comprehension skills.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>3. Personalized Learning:</p>
            <li>
              Individual assessments to tailor the learning experience according
              to the student's level and goals.
            </li>
            <li>
              Regular feedback and progress reports to monitor improvement.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>4. Experienced Instructors:</p>
            <li>
              Highly qualified and experienced teachers with expertise in
              teaching English as a second language.
            </li>
            <li>
              Focus on practical usage and real-life communication scenarios.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>5. Supplementary Materials:</p>
            <li>
              Access to a vast library of e-books, articles, and practice
              exercises.
            </li>
            <li>Interactive quizzes and assignments to reinforce learning.</li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>6. Flexible Scheduling:</p>
            <li>Multiple batch timings to accommodate different schedules.</li>
            <li>
              Option to switch between online and offline modes as per
              convenience.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>7. Assessment and Certification:</p>
            <li>Regular assessments to evaluate progress.</li>
            <li>
              Certification upon successful completion of the course, recognized
              by educational institutions and employers.
            </li>
          </div>
          <div className={styles.innerSection}>
            <h3>Special Programs</h3>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>1. Business English:</p>
            <li>
              Focus on professional communication skills, including email
              writing, presentations, and meetings.
            </li>
            <li>
              Ideal for working professionals looking to improve their English
              for career advancement.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>2. Academic English:</p>
            <li>
              Preparation for academic settings, including essay writing,
              research papers, and presentations.
            </li>
            <li>
              Suitable for students preparing for higher education or
              international exams like IELTS and TOEFL.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>3. Conversational English:</p>
            <li>
              Emphasis on everyday conversation, cultural nuances, and social
              interactions.
            </li>
            <li>
              Great for learners aiming to enhance their speaking skills for
              travel or social purposes.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>
              4. English for Specific Purposes (ESP):
            </p>
            <li>
              Customized courses for specific fields like medicine, law, or
              engineering.
            </li>
            <li>
              Tailored content to meet the specific language needs of different
              professions.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>
              By enrolling in the English-Speaking Courses at TRG Hybrid
              Classes, learners can expect a comprehensive and flexible learning
              experience that effectively builds their English language skills
              for various contexts and purposes.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>FEES STRUCTURE</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td colSpan={3} style={{ fontWeight: "600" }}>
                GENERAL ENGLISH PROGRAM
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: "600" }}>Levels</td>
              <td style={{ fontWeight: "600" }}>Duration</td>
              <td style={{ fontWeight: "600" }}>Fees</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "600" }}>Basic</td>
              <td>3 months/ 60 classes</td>
              <td>14999 /- (4999/- per month)</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "600" }}>Intermediate</td>
              <td>3 months/ 60 classes</td>
              <td>14999 /- (4999/- per month)</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "600" }}>Advanced</td>
              <td>1.5 months/ 30 classes</td>
              <td>7499/-</td>
            </tr>
            <tr>
              <td colSpan={3} style={{ fontWeight: "600" }}>
                Study material also Available
              </td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default EngSpeak;
