import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { setToken } from "../../../utils";
import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import styles from "./faq.module.css";

import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import Accordion from "react-bootstrap/Accordion";
Modal.setAppElement("#root");

export default function Faqs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [reasonValue, setReasonValue] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  const handleLogin = () => {
    setLoading(true);
    setIsErrorShown(false);

    if (!email || !password) {
      setLoading(false);
      setIsErrorShown(true);
    } else {
      login(email, password)
        .then(({ data: { access, refresh, email, user_type } }) => {
          setLoading(false);
          dispatch(getStudent({ access, email, user_type }, history));
          setToken(access, refresh);
          // history.replace("/student-dashboard");
        })
        .catch(() => {
          setLoading(false);
          setIsErrorShown(true);
        });
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <CustomNav Open={closeModal} />

      <section className={styles.faq_main}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            textAlign: "justify",
          }}
        >
          <h1 className={styles.faq_heading}>
            Frequently Asked Questions (FAQs)
          </h1>
          <div className={styles.sections}>
            <h2 className={styles.faq_title}>General Questions</h2>
            <div
              style={{ fontSize: "22", fontWeight: "500", marginBottom: "3%" }}
            >
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingOne"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "rgb(220, 53, 69)",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What is the TRG Hybrid Tuition Platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        The TRG Hybrid Tuition Platform is an innovative
                        learning system that combines online and offline
                        teaching methods to provide a flexible and comprehensive
                        educational experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingTwo"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What subjects and courses does the platform offer?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        The platform offers a wide range of subjects including
                        Math, Science, English, GK, Reasoning, and preparation
                        courses for competitive exams like CLAT and DSSSB.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingThree"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      How does the hybrid model work?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        The hybrid model integrates online classes, interactive
                        sessions, and offline support, allowing students to
                        benefit from the flexibility of online learning and the
                        personal interaction of traditional classroom settings.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingfour"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsefour"
                      aria-expanded="false"
                      aria-controls="flush-collapsefour"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What are the benefits of using the TRG Hybrid Tuition
                      Platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapsefour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingfour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Benefits include personalized learning paths, access to
                        high-quality educational content, flexibility in
                        scheduling, and continuous support from experienced
                        teachers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingfive"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsefive"
                      aria-expanded="false"
                      aria-controls="flush-collapsefive"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Who are the instructors on the platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapsefive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingfive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Instructors are experienced educators and subject matter
                        experts with a proven track record in teaching and
                        mentoring students.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingSix"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Can I access the platform on my mobile device?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, the TRG Hybrid Tuition Platform is fully
                        mobile-responsive and can be accessed on smartphones,
                        tablets, and other mobile devices.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingSeven"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      How are classes conducted on the platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Classes are conducted through live video sessions,
                        recorded lectures, interactive quizzes, and assignments.
                        There are also offline sessions for additional support.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingEight"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseEight"
                      aria-expanded="false"
                      aria-controls="flush-collapseEight"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What technology is required to use the platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingEight"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        A stable internet connection, a computer or mobile
                        device, and a webcam for interactive sessions are
                        recommended.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingNine"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Is there a trial period available?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, the platform offers a free trial period for new
                        users to experience the features and teaching methods
                        before enrolling.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingTen"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTen"
                      aria-expanded="false"
                      aria-controls="flush-collapseTen"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      How can I contact customer support?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTen"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Customer support can be contacted through email, phone,
                        or live chat available on the platform’s website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sections}>
            <h2 className={styles.faq_title}>Admission Related Questions</h2>
            <div
              style={{ fontSize: "22", fontWeight: "500", marginBottom: "3%" }}
            >
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingthirteen"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "rgb(220, 53, 69)",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsethirteen"
                      aria-expanded="false"
                      aria-controls="flush-collapsethirteen"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      How do I enrol in a course on the TRG Hybrid Tuition
                      Platform?
                    </button>
                  </h2>
                  <div
                    id="flush-collapsethirteen"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingthirteen"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        You can enroll by visiting the platform’s website,
                        selecting the desired course, and following the
                        registration process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingA"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseA"
                      aria-expanded="false"
                      aria-controls="flush-collapseA"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What are the eligibility criteria for enrolling in
                      courses?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseA"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingA"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Eligibility criteria vary by course. Basic requirements
                        typically include an interest in the subject and a
                        willingness to learn. Specific courses may have
                        additional prerequisites.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingB"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseB"
                      aria-expanded="false"
                      aria-controls="flush-collapseB"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What documents are required for admission?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseB"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingB"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Generally, you need to provide a government-issued ID,
                        proof of previous educational qualifications, and any
                        specific documents required for the course.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingC"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseC"
                      aria-expanded="false"
                      aria-controls="flush-collapseC"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Is there an entrance exam for admission?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseC"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingC"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Some competitive exam preparation courses may require an
                        entrance test. Details will be provided during the
                        application process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingD"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseD"
                      aria-expanded="false"
                      aria-controls="flush-collapseD"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Can I switch courses after enrolling?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseD"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingD"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, course switching policies vary. It is best to
                        contact the admissions office for specific details.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingE"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseE"
                      aria-expanded="false"
                      aria-controls="flush-collapseE"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Are there any discounts or scholarships available?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseE"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingE"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, the platform offers various discounts and
                        scholarships based on merit and need. Details can be
                        found on the website or by contacting the admissions
                        office.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingF"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseF"
                      aria-expanded="false"
                      aria-controls="flush-collapseF"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      How can I check the status of my application?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseF"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingF"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        You can check your application status by logging into
                        your account on the platform or contacting the
                        admissions office.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingG"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseG"
                      aria-expanded="false"
                      aria-controls="flush-collapseG"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What is the refund policy if I decide to withdraw from a
                      course?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseG"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingG"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        The refund policy varies by course. Generally, partial
                        refunds are available within a certain period after
                        enrollment. Specific details are provided during the
                        registration process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingH"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseH"
                      aria-expanded="false"
                      aria-controls="flush-collapseH"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Can I attend a demo class before enrolling?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseH"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingH"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, demo classes are available. You can sign up for a
                        demo class on the platform’s website.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingI"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseI"
                      aria-expanded="false"
                      aria-controls="flush-collapseI"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Is there a limit to the number of courses I can enrol in
                      simultaneously?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseI"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingI"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        There is no strict limit, but it is advisable to choose
                        a manageable number of courses to ensure effective
                        learning.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sections}>
            <h2 className={styles.faq_title}>Payment Related Questions</h2>
            <div
              style={{ fontSize: "22", fontWeight: "500", marginBottom: "3%" }}
            >
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingL"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "rgb(220, 53, 69)",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseL"
                      aria-expanded="false"
                      aria-controls="flush-collapseL"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What are the available payment methods?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseL"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingL"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Payment methods include credit/debit cards, net banking,
                        UPI, and digital wallets.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingM"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseM"
                      aria-expanded="false"
                      aria-controls="flush-collapseM"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Is there an instalment payment option available?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseM"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingM"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, the platform offers installment payment options for
                        certain courses. Details are available during the
                        payment process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingN"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseN"
                      aria-expanded="false"
                      aria-controls="flush-collapseN"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Are there any additional fees apart from the course fee?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseN"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingN"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Generally, the course fee covers all materials and
                        resources. However, some courses may have additional
                        fees for specific materials or exams.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingO"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseO"
                      aria-expanded="false"
                      aria-controls="flush-collapseO"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      How can I get a receipt for my payment?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseO"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingO"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Receipts are automatically generated and emailed to you
                        upon successful payment. You can also download them from
                        your account dashboard.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingP"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseP"
                      aria-expanded="false"
                      aria-controls="flush-collapseP"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Is my payment information secure?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseP"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingP"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, the platform uses secure payment gateways and
                        encryption to protect your payment information.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingQ"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseQ"
                      aria-expanded="false"
                      aria-controls="flush-collapseQ"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Can I get a refund if I am not satisfied with the course?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseQ"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingQ"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Refund policies vary by course. Typically, partial
                        refunds are available within a specified period after
                        enrollment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingR"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseR"
                      aria-expanded="false"
                      aria-controls="flush-collapseR"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Are there any discounts for early bird registration?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseR"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingR"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, early bird discounts are often available. Details
                        can be found on the course registration page.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingS"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseS"
                      aria-expanded="false"
                      aria-controls="flush-collapseS"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      What should I do if my payment fails?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseS"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingS"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        If your payment fails, try again using a different
                        method or contact customer support for assistance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingT"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseT"
                      aria-expanded="false"
                      aria-controls="flush-collapseT"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Can I pay for multiple courses at once?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseT"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingT"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, you can add multiple courses to your cart and make
                        a single payment for all of them, but it is always a
                        better practice to make a separate payment for each
                        course/item so that payments are allocated accurately.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="flush-headingU"
                    style={{ paddingTop: "1%", paddingBottom: "1%" }}
                  >
                    <button
                      className="accordion-button collapsed accordion-personalize-header"
                      style={{
                        color: "#dc3545",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseU"
                      aria-expanded="false"
                      aria-controls="flush-collapseU"
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fab fa-acquisitions-incorporated"
                      ></i>
                      Are there any financial aid options available? process?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseU"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingU"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div
                      className="accordion-body accordion-personalize-body"
                      style={{ textAlign: "justify", padding: "12px 8px" }}
                    >
                      <p>
                        Yes, financial aid options such as scholarships and
                        need-based assistance are available. Details can be
                        found on the platform’s website or by contacting the
                        admissions office.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sections}>
            <h2 className={styles.faq_title}>FOR ANY OTHER QUERY</h2>
            <div>
              <p className={styles.content_text}>
                <span className={styles.bold}>Mobile No: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="tel:+919570424242"
                >
                  9570-424242
                </a>
              </p>
              <p className={styles.content_text}>
                <span className={styles.bold}>WhatsApp: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/917419197878"
                >
                  74-1919-7878
                </a>
              </p>
              <p className={styles.content_text}>
                <span className={styles.bold}>Email: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:contact@therightguru.com"
                >
                  contact@therightguru.com
                </a>
              </p>
              <p className={styles.content_text}>
                <span className={styles.bold}>Website: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://therightguru.com"
                >
                  https://therightguru.com
                </a>
              </p>
              <p className={styles.content_text}>
                For a more personalized and detailed understanding, we encourage
                you to visit our centers and consult our dedicated counselors.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}
