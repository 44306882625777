import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import CountUp from "react-countup";
import { Icon, Image } from "semantic-ui-react";
import "./styles.css";
import CustomNav from "../../../components/header/Navbar";
import Welcome from "../../../components/Welcome";
import Footer from "../../../components/Footer";

import Popup2 from "../../non_auth/new_popup/popup2";

import LoginModal from "../../../components/Login_modal/loginModal";
import GuideModal from "../../../components/Guide_modal/guideModal";
import DownloadStrip from "../../../components/header/DownloadStrip";

import OurToppers from "./OurToppers";
import TrgApp from "./TrgApp";
import AddressInfo from "./AddressInfo";
import Corosal from "./corosal_pic/Corosal";

import Popup from "../../non_auth/popup/Popup";
import Guru from "../../non_auth/faculty_details/Guru";
import Background from "../../../components/icons/bgcanvas.png";
import OurServices_Courses from "./OurServices_Courses";
import StudentReviews from "./StudentReviews";
import axios from "axios";

Modal.setAppElement("#root");

function Landing() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const aboutUsRef = useRef(null);
  const visionRef = useRef(null);

  const executeAboutUsScroll = () => aboutUsRef.current.scrollIntoView();
  const executeVisionScroll = () => visionRef.current.scrollIntoView();

  const [reasonValue, setReasonValue] = useState(0);
  const [showStudentGuide, setShowStudentGuide] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  function closeGuideModal() {
    setShowStudentGuide(false);
  }

  const [timePop, setTimePop] = useState(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // State to handle loading

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.therightguru.com/api/announcement-entry/"
      );
      setData(
        response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
      console.log("Announcemnt Data", response.data);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTimePop(true);
    }, 3000);
  }, []);

  return (
    <>
      <div className="landing-page-layout-100">
        <div style={{ zIndex: 1050, position: "relative" }}>
          <CustomNav Open={closeModal} />
        </div>
        <section id="carousal_Section">
          <Corosal />
        </section>

        <section id="announcemet">
          <div style={{ width: "100%" }} className="divannounce">
            <h2 className="headers" style={{ backgroundColor: "#dc3545" }}>
              ANNOUNCEMENTS
            </h2>
            <div
              className="annnouce"
              style={{
                margin: 10,
                display: "flex",
                flexDirection: "column",
                lineHeight: 2,
                gap: "8px",
              }}
            >
              {data &&
                data.map((item, index) => (
                  <a href={item.rediectLink} target="_blank" key={index}>
                    <p>
                      <span>&#9635; &nbsp;</span>
                      {item.title}
                    </p>
                  </a>
                ))}
            </div>
          </div>
        </section>

        <section id="services">
          <h2
            className="headers"
            style={{
              padding: "40px 0px 16px",
            }}
          >
            OUR PROGRAMS
          </h2>
          <OurServices_Courses />
        </section>

        <section id="gurus">
          <h2
            className="headers"
            style={{
              padding: "40px 0px 16px",
              color: "#dc3545",
            }}
          >
            OUR GURUS
          </h2>
          <Guru />
        </section>

        <section id="our-toppers">
          <h1
            className="headers"
            style={{
              padding: "40px 0px 16px",
              color: "#dc3545",
            }}
          >
            TRG TOPPERS
          </h1>

          <OurToppers />
        </section>

        <section id="trg-mobile-feature">
          <TrgApp />
        </section>

        <section id="reviews">
          <div
            style={{
              width: "100%",
              padding: "0px 40px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "24px",
            }}
          >
            <h1
              className="headers"
              style={{
                padding: "40px 0px 16px",
                color: "#dc3545",
              }}
            >
              REVIEWS
            </h1>
            {/* <h2 style={{ fontWeight: "600", fontSize: "16px" }}>4.9</h2> */}
          </div>

          <StudentReviews />
        </section>

        <section id="footer">
          <Footer refs={[executeAboutUsScroll, executeVisionScroll]} />
        </section>
        {/* <Popup trigger={timePop} setTrigger={setTimePop} /> */}
      </div>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
      {/* <GuideModal isModalOpen={showStudentGuide} closeModal={closeGuideModal} /> */}
    </>
  );
}

export default Landing;
