import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Modal from 'react-modal';
import Select from 'react-dropdown-select';
import DropdownOption from '../../higher_committee/dropdown/dropdown_grade';
import { useDispatch } from "react-redux";
import { getStudent } from "../../../redux/actions/student";
import { login } from "../../../crud/auth.crud";
import { bookTrialClass } from "../../../crud/student.crud";
import { setToken } from "../../../utils";
import navLogo from "../../student/dashboard/img/pic_4.png";
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Dropdown } from "semantic-ui-react";

import "./style.css";
import { Divider } from 'antd';
import { trialStudentDetails, enrolledStudentPayment, createEnrolledStudent, registerStudent, addStudentTransaction } from "../../../crud/student.crud";

Modal.setAppElement('#root');

const countryOptions = [
  {
    value: "India",
    text: "India",
    key: "India"
  },
  {
    value: "United Kingdom",
    text: "United Kingdom",
    key: "UnitedKingdom"
  },
  {
    value: "USA",
    text: "USA",
    key: "USA"
  },
  {
    value: "Canada",
    text: "Canada",
    key: "Canada"
  },
  {
    value: "New Zealand",
    text: "New Zealand",
    key: "NewZealand"
  },
  {
    value: "Australia",
    text: "Australia",
    key: "Australia"
  }
];

const usaStateOptions = [
  {
    value: "Alabama",
    text: "Alabama",
    key: "ALB"
  },
  {
    value: "Alaska",
    text: "Alaska",
    key: "ALS"
  },
  {
    value: "Arizona",
    text: "Arizona",
    key: "AR"
  },
  {
    value: "Arkansas",
    text: "Arkansas",
    key: "ARK"
  },
  {
    value: "California",
    text: "California",
    key: "CA"
  },
  {
    value: "Colorado",
    text: "Colorado",
    key: "CO"
  },
  {
    value: "Connecticut",
    text: "Connecticut",
    key: "CT"
  },
  {
    value: "Delaware",
    text: "Delware",
    key: "DE"
  },
  {
    value: "Florida",
    text: "Florida",
    key: "FL"
  },
  {
    value: "Georgia",
    text: "Georgia",
    key: "GA"
  },
  {
    value: "Hawaii",
    text: "Hawaii",
    key: "HA"
  },
  {
    value: "Idaho",
    text: "Idaho",
    key: "ID"
  },
  {
    value: "Illinois",
    text: "Illinois",
    key: "IL"
  },
  {
    value: "Indiana",
    text: "Indiana",
    key: "IN"
  },
  {
    value: "Iowa",
    text: "Iowa",
    key: "IO"
  },
  {
    value: "Kansas",
    text: "Kansas",
    key: "KA"
  },
  {
    value: "Kentucky",
    text: "Kentucky",
    key: "KT"
  },
  {
    value: "Louisiana",
    text: "Louisiana",
    key: "LO"
  },
  {
    value: "Maine",
    text: "Maine",
    key: "MN"
  },
  {
    value: "Maryland",
    text: "Maryland",
    key: "MR"
  },
  {
    value: "Massachusetts",
    text: "Massachusetts",
    key: "MA"
  },
  {
    value: "Michigan",
    text: "Michigan",
    key: "MI"
  },
  {
    value: "Minnesota",
    text: "Minnesota",
    key: "MNS"
  },
  {
    value: "Mississippi",
    text: "Mississippi",
    key: "MSP"
  },
  {
    value: "Missouri",
    text: "Missouri",
    key: "MS"
  },
  {
    value: "Montana",
    text: "Montana",
    key: "MO"
  },
  {
    value: "Nebraska",
    text: "Nebraska",
    key: "NB"
  },
  {
    value: "Nevada",
    text: "Nevada",
    key: "NE"
  },
  {
    value: "New Hampshire",
    text: "New Hampshire",
    key: "NH"
  },
  {
    value: "New Jersey",
    text: "New Jersey",
    key: "NJ"
  },
  {
    value: "New Mexico",
    text: "New Mexico",
    key: "NM"
  },
  {
    value: "New York",
    text: "New York",
    key: "NY"
  },
  {
    value: "North Carolina",
    text: "North Carolina",
    key: "NC"
  },
  {
    value: "North Dakota",
    text: "North Dakota",
    key: "ND"
  },
  {
    value: "Ohio",
    text: "Ohio",
    key: "OH"
  },
  {
    value: "Oklahoma",
    text: "Oklahoma",
    key: "OK"
  },
  {
    value: "Oregon",
    text: "Oregon",
    key: "OR"
  },
  {
    value: "Pennsylvania",
    text: "Pennsylvania",
    key: "PA"
  },
  {
    value: "Rhode Island",
    text: "Rhode Island",
    key: "RI"
  },
  {
    value: "South Carolina",
    text: "South Carolina",
    key: "SC"
  },
  {
    value: "South Dakota",
    text: "South Dakota",
    key: "SD"
  },
  {
    value: "Tennessee",
    text: "Tennessee",
    key: "TN"
  },
  {
    value: "Texas",
    text: "Texas",
    key: "TA"
  },
  {
    value: "Utah",
    text: "Utah",
    key: "UT"
  },
  {
    value: "Vermont",
    text: "Vermont",
    key: "VE"
  },
  {
    value: "Virginia",
    text: "Virginia",
    key: "VA"
  },
  {
    value: "Washington",
    text: "Washington",
    key: "WA"
  },
  {
    value: "West Virginia",
    text: "West Virginia",
    key: "WV"
  },
  {
    value: "Wisconsin",
    text: "Wisconsin",
    key: "WI"
  },
  {
    value: "Wyoming",
    text: "Wyoming",
    key: "WY"
  },
]

const ausStateOptions = [
  {
    value: "New South Wales",
    text: "New South Wales",
    key: "NSW"
  },
  {
    value: "Queensland",
    text: "Queensland",
    key: "QN"
  },
  {
    value: "South Australia",
    text: "South Australia",
    key: "SA"
  },
  {
    value: "Tasmania",
    text: "Tasmania",
    key: "TA"
  },
  {
    value: "Victoria",
    text: "Victoria",
    key: "VI"
  },
  {
    value: "Western Australia",
    text: "Western Australia",
    key: "WA"
  },
  {
    value: "Australian Capital Territory",
    text: "Australian Capital Territory",
    key: "ACT"
  },
  {
    value: "Northern Territory",
    text: "Northern Territory",
    key: "NT"
  },
]

const stateOptions = [
  {
    value: "Andaman and Nicobar Islands",
    text: "Andaman and Nicobar Islands",
    key: "AN"
  },
  {
    value: "Andhra Pradesh",
    text: "Andhra Pradesh",
    key: "AP"
  },
  {
    value: "Arunachal Pradesh",
    text: "Arunachal Pradesh",
    key: "AR"
  },
  {
    value: "Assam",
    text: "Assam",
    key: "AS"
  },
  {
    value: "Bihar",
    text: "Bihar",
    key: "BR"
  },
  {
    value: "Chandigarh",
    text: "Chandigarh",
    key: "CG"
  },
  {
    value: "Chhattisgarh",
    text: "Chhattisgarh",
    key: "CH"
  },
  {
    value: "Dadra and Nagar Haveli",
    text: "Dadra and Nagar Haveli",
    key: "DH"
  },
  {
    value: "Daman and Diu",
    text: "Daman and Diu",
    key: "DD"
  },
  {
    value: "Delhi",
    text: "Delhi",
    key: "DL"
  },
  {
    value: "Goa",
    text: "Goa",
    key: "GA"
  },
  {
    value: "Gujarat",
    text: "Gujarat",
    key: "GJ"
  },
  {
    value: "Haryana",
    text: "Haryana",
    key: "HR"
  },
  {
    value: "Himachal Pradesh",
    text: "Himachal Pradesh",
    key: "HP"
  },
  {
    value: "Jammu and Kashmir",
    text: "Jammu and Kashmir",
    key: "JK"
  },
  {
    value: "Jharkhand",
    text: "Jharkhand",
    key: "JH"
  },
  {
    value: "Karnataka",
    text: "Karnataka",
    key: "KA"
  },
  {
    value: "Kerala",
    text: "Kerala",
    key: "KL"
  },
  {
    value: "Lakshadweep",
    text: "Lakshadweep",
    key: "LD"
  },
  {
    value: "Madhya Pradesh",
    text: "Madhya Pradesh",
    key: "MP"
  },
  {
    value: "Maharashtra",
    text: "Maharashtra",
    key: "MH"
  },
  {
    value: "Manipur",
    text: "Manipur",
    key: "MN"
  },
  {
    value: "Meghalaya",
    text: "Meghalaya",
    key: "ML"
  },
  {
    value: "Mizoram",
    text: "Mizoram",
    key: "MZ"
  },
  {
    value: "Nagaland",
    text: "Nagaland",
    key: "NL"
  },
  {
    value: "Odisha",
    text: "Odisha",
    key: "OR"
  },
  {
    value: "Puducherry",
    text: "Puducherry",
    key: "PY"
  },
  {
    value: "Punjab",
    text: "Punjab",
    key: "PB"
  },
  {
    value: "Rajasthan",
    text: "Rajasthan",
    key: "RZ"
  },
  {
    value: "Sikkim",
    text: "Sikkim",
    key: "SK"
  },
  {
    value: "Tamil Nadu",
    text: "Tamil Nadu",
    key: "TN"
  },
  {
    value: "Telangana",
    text: "Telangana",
    key: "TS"
  },
  {
    value: "Tripura",
    text: "Tripura",
    key: "TR"
  },
  {
    value: "Uttar Pradesh",
    text: "Uttar Pradesh",
    key: "UP"
  },
  {
    value: "Uttarakhand",
    text: "Uttarakhand",
    key: "UK"
  },
  {
    value: "West Bengal",
    text: "West Bengal",
    key: "WB"
  }
];

const gradeOptions = [
    {
      key: "Nur",
      text: "Nursery",
      value: "-1",
    },
    {
      key: "KG",
      text: "Kinder Garden",
      value: "0",
    },
    {
      key: "1",
      text: "1",
      value: "1",
    },
    {
      key: "2",
      text: "2",
      value: "2",
    },
    {
      key: "3",
      text: "3",
      value: "3",
    },
    {
      key: "4",
      text: "4",
      value: "4",
    },
    {
      key: "5",
      text: "5",
      value: "5",
    },
    {
      key: "6",
      text: "6",
      value: "6",
    },
    {
      key: "7",
      text: "7",
      value: "7",
    },
    {
      key: "8",
      text: "8",
      value: "8",
    },
    {
      key: "9",
      text: "9",
      value: "9",
    },
    {
      key: "10",
      text: "10",
      value: "10",
    },
    {
      key: "11",
      text: "11",
      value: "11",
    },
    {
      key: "12",
      text: "12",
      value: "12",
    },
  ];

const selectStyles = {
  width: "100%",
  paddingLeft: "10px",
  borderRadius: "5px",
  border: "2px solid rgba(217, 83, 79, 0.4)",
  fontSize: "15px",
  fontWeight: 400,
  fontFamily: "'Montserrat', Helvetica",
}

function Landing() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const { trialStudentId, amount } = useParams();
  const [trialStudent, setTrialStudent] = useState(undefined)

  useEffect(() => {
    trialStudentDetails(trialStudentId)
      .then(res => {
        setTrialStudent(res);
        setFirstName(res.first_name);
        setLastName(res.last_name);
        setFatherName(res.father_name);
        setFatherNumber(res.father_number);
        setMobileNumber(res.mobile_number);
        setEmail(res.email);
        setStudentState(res.state);
        setPostalCode(res.postal_code);
        setCountry(res.country);
        setCurrentGrade(res.current_grade);
      })
      .catch(err => console.log(err))
  }, []);

  useEffect(() => {
    console.log(amount, trialStudentId);
    enrolledStudentPayment({ amount, trialStudentId })
      .then(res => {
        setRzrpayOrder(res);
      })
      .catch(err => console.log(err))
  }, [])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [login_email, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const closeModal = () => setIsModalOpen(false);

  const handleLogin = () => {
    setLoading(true);
    setIsErrorShown(false);

    if (!login_email || !password) {
      setLoading(false);
      setIsErrorShown(true);
    } else {
      login(login_email, password)
        .then(({ data: { access, refresh, email, user_type } }) => {
          setLoading(false);
          dispatch(getStudent({ access, email, user_type }));
          setToken(access, refresh);
          history.replace("/student-dashboard");
        })
        .catch(() => {
          setLoading(false);
          setIsErrorShown(true);
        })
    }
  }

  //form state
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [mobile_number, setMobileNumber] = useState("")
  const [father_name, setFatherName] = useState("")
  const [father_number, setFatherNumber] = useState("")
  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("")
  const [state, setStudentState] = useState("")
  const [postal_code, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [landmark, setLandmark] = useState("")
  const [school, setSchool] = useState("")
  const [lastMarks, setLastMarks] = useState(0)
  const [current_grade, setCurrentGrade] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [rzrpayOrder, setRzrpayOrder] = useState({})

  const handleSubmit = async () => {
    if(newPassword != confirmPassword || newPassword == "") {
      alert("Passwords don't match or are empty.")
    } else if(mobile_number == "" || country == "" || state == "" || postal_code == "" || current_grade == "" ||
    city == "" || address == "" || landmark == "" || school == "" || lastMarks == "") {
      alert("Please fill all fields before submitting.");
    } else {
      // open razorpay
      const options = {
        key: process.env.REACT_APP_RZP_KEY,
        amount: rzrpayOrder.amount,
        currency: "INR",
        name: "TheRightGuru",
        description: "Student Enrollment",
        // image: "https://example.com/your_logo",
        order_id: rzrpayOrder.id,
        handler: async response => {
          // hit API to save payment details to database
          addStudentTransaction({
            student_transaction_id: response.razorpay_payment_id,
            trial_student_id: trialStudentId,
            student_name: first_name + " " + last_name,
            payment_for: "Student Enrollement",
            email: email,
            created_on: (new Date()).toISOString(),
            amount: (rzrpayOrder.amount)/100,
            method: "Online"
          })
            .then(() => console.log("Payment Successfull"))
            .catch(err => console.log(err))
          
          //hit APIs to create Enrolled Student in DB and register user
          createEnrolledStudent({
            first_name,
            last_name,
            mobile_number,
            father_name,
            father_number,
            email,
            country,
            state,
            postal_code,
            current_grade,
            city,
            address,
            landmark,
            current_school: school,
            last_marks: parseFloat(lastMarks),
            enrolled_student_id: "ES_" + email,
            trial_student_id: trialStudentId
          })
            .then(() => {
              // const password = generator.generate({
              //   length: 10,
              //   numbers: true,
              // });
              registerStudent({
                email,
                password: newPassword,
                user_type: 1
              })
                .then(res => {
                  alert(`Welcome to The Right Guru!\n 
                        You can contact sales regarding your further enrollment.Following are your credentials:-\n
                        email:${email}\n
                        password:${newPassword}\n
                        Please record this password and keep it safe.
                        You can use these credentials to login once sales team completes your enrollment.`)
                  history.replace("/");
                })
                .catch(err => {
                  console.log(err)
                })
            })
            .catch(err => {
              console.log(err)
            })
        },
        prefill: {
          "name": first_name + " " + last_name,
          "email": email
        },
        theme: {
          "color": "#ec3545"
        }
      }

      const rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        console.log({
          dexription: response.error.description,
          step: response.error.step,
          reason: response.error.reason,
          order_id: response.error.metadata.order_id
        })
      });
      rzp1.open();
    }
  }

  return (
    <div className="enrolled-student-form-1">
      <Navbar className="navbar-scroll py-4" style={{ "fontFamily": "'Montserrat', sans-serif" }} collapseOnSelect expand="lg" bg="light" variant="light">
        <Navbar.Brand style={{ "fontWeight": "600" }}>
          <img src={navLogo} width="172px" />
        </Navbar.Brand>
      </Navbar>

      <div className="card_cb">
        <div className="row">
          <div className="col-sm-4" >
            <input type="text" placeholder="Student's First Name" value={trialStudent && trialStudent.first_name} className="text-muted" disabled onChange={e => setFirstName(e.target.value)} />
          </div>
          <div className="col-sm-4" >
            <input type="text" placeholder="Student's Last Name" value={trialStudent && trialStudent.last_name} className="text-muted" disabled onChange={e => setLastName(e.target.value)} />
          </div>
          <div className="col-sm-4">
            <input type="text" placeholder="Father's Name" className="text-muted" value={trialStudent && trialStudent.father_name} disabled onChange={e => setFatherName(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6" >
            <input type="text" placeholder="Primary Email Address" value={trialStudent && trialStudent.email} disabled className="text-muted input2" onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="col-md-6">
            <input type="text" placeholder="Current School" className="text-muted input2" onChange={e => setSchool(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4" >
            <input type="text" placeholder="Mobile number" value={trialStudent && trialStudent.mobile_number} className="text-muted" onChange={e => setMobileNumber(e.target.value)} />
          </div>
          <div className="col-sm-4" >
            <input type="text" placeholder="Parent's Mobile Number" value={trialStudent && trialStudent.father_number} className="text-muted input2" onChange={e => setFatherNumber(e.target.value)} />
          </div>
          <div className="col-sm-4">
            {/* <Select
              className="text-muted mbottom"
              values={[{ value: trialStudent && parseInt(trialStudent.current_grade), label: trialStudent && parseInt(trialStudent.current_grade) }]}
              options={gradeOptions}
              placeholder="Student's Current Grade"
              onChange={value => setCurrentGrade(value[0].value)}
              color="#ec3545"
              style={selectStyles}
            /> */}
            <Dropdown
              placeholder="Student's Current Grade"
              value={trialStudent && trialStudent.current_grade}
              fluid
              search
              selection
              disabled
              color="#ec3545"
              options={gradeOptions}
              style={selectStyles}
              onChange={(e, { value }) => setCurrentGrade(value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4" >
            {/* <Select
              className="text-muted mbottom"
              values={[{ value: trialStudent && trialStudent.country, label: trialStudent && trialStudent.country }]}
              options={countryOptions}
              placeholder="Select Country"
              searchable
              onChange={value => setCountry(value[0].value)}
              color="#ec3545"
              style={selectStyles}
            /> */}
            <Dropdown
              placeholder="Select Country"
              value={trialStudent && trialStudent.country}
              fluid
              search
              selection
              disabled
              color="#ec3545"
              options={countryOptions}
              style={selectStyles}
              onChange={(e, { value }) => setCountry(value)}
            />
          </div>
          <div className="col-sm-4">
            <input type="text" placeholder="State" value={trialStudent && trialStudent.state} className="text-muted" onChange={e => setStudentState(e.target.value)} />
          </div>
          <div className="col-sm-4">
            <input type="text" placeholder="Postal Code" value={trialStudent && trialStudent.postal_code} className="text-muted" onChange={e => setPostalCode(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 smtext" >
            <input type="text" placeholder="Address" className="text-muted input2" onChange={e => setAddress(e.target.value)} />
          </div>
          <div className="col-md-6">
            <input type="text" placeholder="City" className="text-muted input2" onChange={e => setCity(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 smtext" >
            <input type="text" placeholder="Landmark" className="text-muted input2" onChange={e => setLandmark(e.target.value)} />
          </div>
          <div className="col-md-6">
            <input type="number" placeholder="Percentage scored in previous class" className="text-muted input2" onChange={e => setLastMarks(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 smtext" >
            <input type="password" placeholder="New Password" className="text-muted input2" onChange={e => setNewPassword(e.target.value)} />
          </div>
          <div className="col-md-6">
            <input type="password" placeholder="Confirm Password" className="text-muted input2" onChange={e => setConfirmPassword(e.target.value)} />
          </div>
        </div>
        {/* <div className="row">
                <div className="col-md-6" >
                  <input type="text" placeholder="Preferred Date" className="text-muted"/>
                </div>
                <div className="col-md-6">
                  <input type="text" placeholder="Preferred Time Slot" className="text-muted"/>
                </div>
              </div> */}
        <div style={{ width: '100%' }}>
          <Divider />
          <div className="div-amount-user-disp">
            <span>Amount To Be Paid</span>
            <span style={{ color: '#dc3545' }}>₹ {amount}</span>
          </div>
        </div>
        <button
          type="button"
          onClick={() => handleSubmit()}
          className="btn btn-danger submit"
          style={{
            fontFamily: "'Montserrat', Helvetica",
            fontWeight: "600",
            minHeight: '40px'
          }}
        >Submit and Pay</button>

      </div>

      <div style={{ height: "10px" }}></div>

    </div>
  )
}

export default Landing;
