import React, { useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import styles from "./contact_us.module.css";
import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";

Modal.setAppElement("#root");

const Contact_Us = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  return (
    <div className="contact-main">
      <div>
        <CustomNav Open={closeModal} />
        <section className={styles.main}>
          <div className={styles.sections}>
            <h1>RIGHT GURU.COM PVT LTD.</h1>
          </div>
          <div className={styles.sections}>
            <p>
              We are excited to help you achieve your academic goals through our
              innovative Hybrid Classroom Program. For more information or to
              enrol, please contact us on: -
            </p>
          </div>

          <div className={styles.sections}>
            <h2>Address:</h2>
            <div>
              <p>RIGHT GURU. COM PVT. LTD</p>
              <p>42 -SF, OMAXE GALLERIA, SECTOR -14</p>
              <p>JHAJJAR ROAD BAHDURGRAH</p>
              <p>HARYANA – 124507</p>
            </div>
          </div>

          <div className={styles.sections}>
            <p>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>
          </div>

          <div className={styles.sections}>
            <h2>Office hours:</h2>

            <div>
              <p>Monday to Friday: 9:00 AM - 05:30 PM</p>
              <p>Saturday: 10:00 AM - 4:00 PM</p>
              <p>Sunday: 10:00 AM – 4:00 PM</p>
            </div>
          </div>

          <div className={styles.sections}>
            <h2>
              <span className={styles.bold}> Social media:</span>
            </h2>
            <div>
              <p>
                <span className={styles.bold}>Facebook: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/people/The-Right-Guru/100063461899383/?mibextid=ZbWKwL"
                >
                  www.facebook.com/The-Right-Guru
                </a>
              </p>
              <p>
                <span className={styles.bold}>Twitter: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://x.com/The_Right_Guru"
                >
                  www.x.com/The_Right_Guru
                </a>
              </p>
              <p>
                <span className={styles.bold}>Instagram: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/therightguru"
                >
                  www.instagram.com/therightguru
                </a>
              </p>
              <p>
                <span className={styles.bold}>LinkedIn: </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/the-right-guru"
                >
                  www.linkedin.com/company/the-right-guru
                </a>
              </p>
            </div>
          </div>
          <div className={styles.sections}>
            <h2>Get in touch:</h2>
            <div>
              <p>
                Feel free to reach out to us with any questions or inquiries.
                Our team is here to assist you with:
              </p>
              <ul>
                <li> Detailed program information</li>
                <li> Enrolment procedures</li>
                <li> Scheduling and class timings</li>
                <li> Fee structure and payment options</li>
                <li> Any other queries</li>
              </ul>

              <p>
                Join us at TRG and take the next step towards a successful
                educational journey with our Hybrid Classroom Program. We look
                forward to hearing from you!
              </p>

              <p>
                For immediate assistance, please call us or send an email. Our
                dedicated team is ready to provide you with all.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </div>

      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
};

export default Contact_Us;
