import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./review.module.css";
import Carousel from "react-grid-carousel";
import { fontSize, fontStyle } from "@mui/system";

function ReviewModal(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Basic email regex pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[A-Za-z\s]+$/;

  const capitalizeFirstWord = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error and success messages
    setError("");
    setSuccess("");

    // Validation checks
    if (!name || !email || !title || !desc) {
      setError("All fields are required.");
      // Remove error automatically after 3 seconds
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!nameRegex.test(name)) {
      setError("Name should contain only letters and spaces.");
      // Remove error automatically after 5 seconds
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      // Remove error automatically after 3 seconds
      setTimeout(() => setError(""), 5000);
      return;
    }

    const formattedTitle = capitalizeFirstWord(title);
    const formattedDesc = capitalizeFirstWord(desc);

    try {
      const response = await axios.post(
        "https://api.therightguru.com/api/review-form/",
        {
          name,
          email,
          title: formattedTitle,
          desc: formattedDesc,
        }
      );

      if (response.status === 200) {
        setSuccess("Review submitted successfully!");
        setName("");
        setEmail("");
        setTitle("");
        setDesc("");

        // Hide modal after 2 seconds
        setTimeout(() => {
          props.onHide();
          setSuccess("");
        }, 3000);
      }
    } catch (err) {
      setError("Failed to submit review. Please try again.");
      // Remove error automatically after 3 seconds
      setTimeout(() => setError(""), 3000);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "700",
            fontSize: "24px",
            textAlign: "center",
            color: "#dc3545",
          }}
        >
          Write a review
        </h1>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} style={{ padding: "0 40px  0" }}>
          {error && (
            <p
              style={{
                textAlign: "center",
                color: "red",
                paddingBottom: "12px",
              }}
            >
              {error}
            </p>
          )}
          {success && (
            <p
              style={{
                textAlign: "center",
                color: "green",
                paddingBottom: "12px",
              }}
            >
              {success}
            </p>
          )}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            <input
              placeholder="Name"
              type="text"
              id="name"
              autoComplete="off"
              title="Enter your name here."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              placeholder="Email"
              type="text"
              id="email"
              autoComplete="off"
              title="Enter your email here."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              placeholder="Title"
              type="text"
              autoComplete="off"
              id="title"
              title="Enter your title here."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              style={{
                width: "100%",
                borderRadius: "5px",
                borderWidth: "2px",
                borderColor: "rgba(217, 83, 79, 0.4)",
                padding: "10px",
                fontWeight: "400",
              }}
              placeholder="Description"
              id="desc"
              autoComplete="off"
              title="Enter your Description here."
              value={desc}
              maxLength={200}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "40px 0",
            }}
          >
            <button
              onClick={props.onHide}
              type="button"
              style={{
                border: "2px solid ",
                padding: "8px",
                backgroundColor: "red",
                color: "white",
                fontWeight: 600,
                borderRadius: "5px",
              }}
            >
              Close
            </button>
            <button
              type="submit"
              style={{
                border: "2px solid ",
                padding: "8px",
                backgroundColor: "green",
                color: "white",
                fontWeight: 600,
                borderRadius: "5px",
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
function StudentReviews() {
  const [modalShow, setModalShow] = useState(false);
  const [allReviews, setAllReviews] = useState([]); // Store all fetched reviews

  const fetchReviews = async () => {
    try {
      const response = await axios.get(
        "https://api.therightguru.com/api/review-form/"
      );

      // Filter reviews where isVerified is anything except "Not-verified"
      const validReviews = response.data.filter(
        (review) => review.isVerified !== "Not-verified"
      );

      // Sort the valid reviews by latest createdAt first
      const sortedReviews = validReviews.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setAllReviews(sortedReviews); // Set valid reviews in state
      console.log("Filtered Reviews: ", sortedReviews);
    } catch (error) {
      console.error(error);
    }
  };

  // Use effect to fetch reviews on component mount
  useEffect(() => {
    fetchReviews();
  }, []); // Empty dependency array means this runs once on mount

  return (
    <>
      <div className={styles.main}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginBottom: "40px",
          }}
        >
          <div
            onClick={() => setModalShow(true)}
            style={{
              backgroundColor: "#dc3545",
              alignSelf: "center",
              maxWidth: "fit-content",
              borderRadius: "6px",
              cursor: "pointer",
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              padding: "8px 16px",
            }}
          >
            Create a Review
          </div>
        </div>

        <Carousel
          cols={4}
          rows={1}
          gap={10}
          responsiveLayout={[
            {
              breakpoint: "1024px",
              cols: 4,
            },
            {
              breakpoint: "720px",
              cols: 3,
            },
          ]}
          mobileBreakpoint={480}
          scroll={1}
        >
          {allReviews.length > 0 &&
            allReviews.map((item, index) => (
              <Carousel.Item key={index}>
                <div className={styles.card}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {item.name}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {new Date(item.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className={styles.textbox}>
                    <p style={{ fontSize: "15px", fontWeight: "500" }}>
                      {item.title}
                    </p>
                    <p style={{ fontSize: "14px", fontWeight: "400" }}>
                      {item.desc}
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
        </Carousel>

        <ReviewModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </>
  );
}

export default StudentReviews;
