import React, { useLayoutEffect, useState, useRef } from "react";
import styles from "./career.module.css";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "./footer.css";
import axios from "axios";

const position = [
  {
    value: "Teacher",
    label: "Teacher",
  },
  {
    value: "Digital marketing",
    label: "Digital marketing",
  },
  {
    value: "Teaching Management",
    label: "Teaching Management",
  },
  {
    value: "Content Design",
    label: "Content Design",
  },
  {
    value: "Designing",
    label: "Designing",
  },
  {
    value: "Customer Service",
    label: "Customer Service",
  },
  {
    value: "Logistics",
    label: "Logistics",
  },
  {
    value: "Technology",
    label: "Technology",
  },
  {
    value: "Finance and Accounting",
    label: "Finance and Accounting",
  },
  {
    value: "Sales",
    label: "Sales",
  },
  {
    value: "Tele-call Marketing",
    label: "Tele-call Marketing",
  },
];
Modal.setAppElement("#root");

export default function Career() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [emaill, setEmaill] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [positionSelected, setPositionSelected] = useState("");
  const [additional, setAdditional] = useState("");
  const [file, setFile] = useState(null);
  const formRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Check if a file is selected
    if (selectedFile) {
      // Check if the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        // Check the file size (e.g., limit to 5 MB)
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
        if (selectedFile.size <= maxSizeInBytes) {
          // File is within size limit, handle it
          setFile(selectedFile);
        } else {
          // File exceeds size limit, handle accordingly
          alert("File size exceeds the limit (5 MB)");
        }
      } else {
        // File is not a PDF, handle accordingly
        alert("Please select a PDF file");
      }
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("fname", fName);
      formData.append("lname", lName);
      formData.append("email", emaill);
      formData.append("phone", mobile);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", country);
      formData.append("position", positionSelected);
      formData.append("additional", additional);
      formData.append("file", file);
      await axios.post(
        `https://api.therightguru.com/api/career-form/`,
        formData
      );

      // Action to take when the form is submitted successfully
      alert("Form submitted successfully!");
      setFName("");
      setLName("");
      setEmaill("");
      setMobile("");
      setAddress("");
      setCity("");
      setCountry("");
      setPositionSelected("");
      setAdditional("");
      setFile(null);
      // Reset the form using the ref
      formRef.current.reset();
    } catch (err) {
      alert("Something went wrong. Please carefully fill out the form again.");
      console.log(err);
    }
  };

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div>
          <h1 className={styles.heading}>CAREERS AT TRG</h1>
        </div>
        <div>
          <p>
            TRG offers a variety of career opportunities across different
            domains. Here are the potential roles available at TRG:
          </p>
        </div>
        <div>
          <h2 className={styles.title}>Teaching Roles</h2>
          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>1. School Subjects:</span>
            </p>
            <li>
              Teaching various school subjects across different grade levels.
            </li>
            <li>
              Specialized subject teachers for Mathematics, Science, English,
              Social Studies, and Languages.
            </li>
          </div>
          <p>
            <span className={styles.bold}>2. Competitive Classes:</span>
          </p>
          <li>
            Instructors for General Knowledge, Reasoning, Mathematics, English,
            and Aptitude.
          </li>
          <li>
            Experienced faculty for competitive exam preparation (e.g., DSSSB,
            CLAT).
          </li>
        </div>
        <div>
          <h2 className={styles.title}>Administrative and Support Roles</h2>
          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>3. Office Coordinator</span>
            </p>
            <li> Managing office operations and administrative tasks.</li>
            <li>
              Coordination between different departments to ensure smooth
              functioning.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>4. Receptionist:</span>
            </p>
            <li> Handling front desk operations.</li>
            <li> Managing phone calls, visitor queries, and appointments.</li>
          </div>

          <p>
            <span className={styles.bold}>5. Tele Callers:</span>
          </p>
          <li>
            Conducting telephonic outreach to potential students and clients.
          </li>
          <p>- Providing information about courses and addressing inquiries.</p>
        </div>
        <div>
          <h2 className={styles.title}>Creative and Technical Roles</h2>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>6. Graphics Designing:</span>
            </p>
            <li>
              Creating visual content for marketing, educational materials, and
              online platforms.
            </li>
            <li>
              Proficiency in design software like Adobe Photoshop, Illustrator,
              etc.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>7. Content Writing:</span>
            </p>
            <li>
              Developing educational content, blogs, articles, and promotional
              materials.
            </li>
            <li>
              Strong command of language and ability to create engaging content.
            </li>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>8. Digital Marketing:</span>
            </p>
            <li> Managing online marketing campaigns.</li>
            <li>
              Expertise in SEO, social media marketing, email marketing, and
              analytics.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>9. Field Marketing:</span>
            </p>
            <li>
              Promoting TRG’s programs and services through on-ground
              activities.
            </li>
            <p> Engaging with potential clients and students directly.</p>
          </div>

          <p>
            <span className={styles.bold}>10. Counsellors:</span>
          </p>
          <li>Providing academic and career counselling to students.</li>
          <li>
            Assisting students in choosing the right courses and career paths.
          </li>
        </div>
        <div>
          <h2 className={styles.title}>Other Skills</h2>

          <li>
            Any Other Skills: TRG is open to individuals with unique skills that
            can contribute to the growth and success of the organization. This
            may include technical support, IT management, event coordination,
            etc.
          </li>
        </div>
        <div>
          <h2 className={styles.title}>
            Fill the form and book an appointment our team will contact you.
          </h2>
        </div>
        {/* Form starts from here --- */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <div className={styles.fromWraper}>
            <form ref={formRef} onSubmit={submitForm}>
              <div className="">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ fontWeight: 500 }}>First Name (Required): </p>
                  <TextField
                    style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                    id="outlined-basic"
                    placeholder="First Name "
                    variant="outlined"
                    onChange={(e) => setFName(e.target.value)}
                    required
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ fontWeight: 500 }}>Last Name:</p>
                  <TextField
                    style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                    id="outlined-basic"
                    placeholder="Last Name "
                    variant="outlined"
                    onChange={(e) => setLName(e.target.value)}
                  />
                </div>
              </div>

              <div className="">
                <p style={{ fontWeight: 500 }}>Email (Required):</p>
                <TextField
                  style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                  id="outlined-basic"
                  placeholder="Email "
                  variant="outlined"
                  onChange={(e) => setEmaill(e.target.value)}
                  required
                />
              </div>
              <div className="">
                <p style={{ fontWeight: 500 }}>Phone (Required):</p>
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "grey",
                    fontStyle: "italic",
                  }}
                >
                  Without country code
                </span>
                <TextField
                  style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                  id="outlined-basic"
                  placeholder="Phone "
                  variant="outlined"
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
              </div>
              <div className="">
                <p style={{ fontWeight: 500 }}>Address (Required):</p>
                <TextField
                  style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                  id="outlined-basic"
                  placeholder="Address "
                  variant="outlined"
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="">
                <p style={{ fontWeight: 500 }}>City (Required):</p>
                <TextField
                  style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                  id="outlined-basic"
                  placeholder="City "
                  variant="outlined"
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
              <div className="">
                <p style={{ fontWeight: 500 }}>Country (Required):</p>
                <TextField
                  style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                  id="outlined-basic"
                  placeholder="Country "
                  variant="outlined"
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />
              </div>

              <div className="">
                <p style={{ fontWeight: 500 }}>
                  Please choose desired position :
                </p>
                <TextField
                  style={{ marginTop: 10, width: "100%", marginBottom: 20 }}
                  id="standard-select-position"
                  select
                  label="Choose desired position"
                  variant="outlined"
                  value={positionSelected}
                  onChange={(e) => setPositionSelected(e.target.value)}
                  required
                >
                  {position.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className="">
                <p style={{ fontWeight: 500 }}>Additional information:</p>
                <TextField
                  style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                  id="outlined-basic"
                  placeholder="Additional Info "
                  variant="outlined"
                  onChange={(e) => setAdditional(e.target.value)}
                />
              </div>

              <div className="">
                <p style={{ fontWeight: 500 }}>Upload Resume (Required):</p>
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "grey",
                    fontStyle: "italic",
                  }}
                >
                  PDF only, and its size must be less than 5 MB.
                </span>
                <TextField
                  style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  inputProps={{ accept: "application/pdf" }}
                  onChange={handleFileChange}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2%",
                }}
              >
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#dc3545",
                    padding: 15,
                    borderRadius: 8,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    cursor: "pointer",
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <p>
            <span className={styles.bold}>For any query,</span>
          </p>

          <p className={styles.content_text}>
            <span className={styles.bold}>Mobile No: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="tel:+919570424242"
            >
              9570-424242
            </a>
          </p>
          <p>
            <span className={styles.bold}>WhatsApp: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/917419197878"
            >
              74-1919-7878
            </a>
          </p>
          <p>
            <span className={styles.bold}>Email: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@therightguru.com"
            >
              contact@therightguru.com
            </a>
          </p>
          <p>
            <span className={styles.bold}>Website: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://therightguru.com"
            >
              https://therightguru.com
            </a>
          </p>

          <p>
            For a more personalized and detailed understanding, we encourage you
            to visit our centers and consult our dedicated counselors.
          </p>
        </div>
      </section>

      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}
