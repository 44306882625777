import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-grid-carousel";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import styles from "./topper.module.css";
import BeatLoader from "react-spinners/BeatLoader";

function OurToppers() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to fetch data from the API
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.therightguru.com/api/student-entry/"
      );
      const sortedData = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setData(sortedData);
      sessionStorage.setItem("toppersData", JSON.stringify(sortedData));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    const storedData = sessionStorage.getItem("toppersData");
    if (storedData) {
      setData(JSON.parse(storedData));
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  if (loading) {
    return (
      <div className={styles.loader}>
        <div
          style={{
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
          }}
          role="status"
        >
          <BeatLoader color="#dc3545" />
          <span style={{ fontSize: "15px" }}>Loading Carousel...</span>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.main}`}>
      <Carousel
        cols={5}
        rows={1}
        gap={5} // Smaller gap
        loop={true}
        autoplay={6000}
        itemWidth={240} // Define item width to prevent overlap
        responsiveLayout={[
          {
            breakpoint: 1024,
            cols: 4,
            gap: 8, // Slightly increase gap on larger tablets
            itemWidth: 180, // Narrow item width for better fit
          },
          {
            breakpoint: 768, // Adjust for tablets
            cols: 2, // Fewer columns on smaller screens
            gap: 5,
            itemWidth: 160,
          },
        ]}
        mobileBreakpoint={480}
        scroll={1}
        scrollSnap={true}
      >
        {data &&
          data.map((item, index) => (
            <Carousel.Item key={index}>
              <div className={styles.card}>
                <img
                  style={{
                    width: "130px",
                    borderRadius: "50%",
                    border: "5px solid #FFD700",
                    maxWidth: "100%",
                  }}
                  src={item.imgUrl}
                  alt={item.name}
                />
                <div className={styles.textbox}>
                  <p style={{ marginTop: "10px", fontWeight: "600" }}>
                    {item.name}
                  </p>
                  <p style={{ marginTop: "10px" }}>{item.content1}</p>
                  <p style={{ marginTop: "10px" }}>{item.content2}</p>
                </div>
              </div>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}

export default OurToppers;
