import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";

import styles from "./common_css.module.css";

Modal.setAppElement("#root");

function Cbse_Icse() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  const scrollToCbseFeeSection = () => {
    const cbseFeeSection = document.getElementById("cbse-fee-section");
    if (cbseFeeSection) {
      cbseFeeSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <h1>CBSE/DBSE/ICSE/OTHER BOARDS</h1>
        <div className={styles.sections}>
          <div className={styles.infoBox}>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Class Timing
                </h2>
              </div>
              <p style={{ fontWeight: "600", textAlign: "center" }}>
                8:00 AM to 8:00 PM
              </p>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Home Tuitions
                </h2>
              </div>
              <p style={{ fontWeight: "600", textAlign: "center" }}>ANY TIME</p>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Contact Us
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  margin: "auto",
                }}
              >
                <p style={{ fontWeight: "600" }}>9570-424242</p>
                <p style={{ fontWeight: "600" }}>74-1919-7878</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sections} style={{ alignItems: "center" }}>
          <div className={styles.btnDiv}>
            <Link to="/trial-student-form">
              <h2 style={{ color: "white" }} className={styles.book_btn}>
                Book Free Trial
              </h2>
            </Link>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>The Right Guru HYBRID Classroom Programme</h2>
          <p>
            The TRG Hybrid Classroom Program is designed to help you achieve
            your academic and career goals through a comprehensive and flexible
            learning approach. Combining the strengths of traditional classroom
            instruction with modern online resources, we offer an effective and
            engaging educational experience.
          </p>
        </div>

        <div className={styles.sections}>
          <div style={{ width: "100%" }}>
            <table className={styles.verticalTable} style={{ width: "100%" }}>
              <tr>
                <th>Why Choose TRG?</th>
                <td>
                  <p>
                    At TRG, we are committed to nurturing academic growth,
                    fostering a deep understanding of subjects, and providing
                    personalized attention to every student. Our diverse
                    teaching methods and experienced educators create an
                    enriching learning environment.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Our Approach to Board Exam Preparation</th>
                <td>
                  <p>
                    At THE RIGHT GURU Tuition Centre, we believe in the power of
                    comprehensive learning. Our expert educators are committed
                    to providing you with top-notch study resources,
                    personalized guidance, and a transformative learning
                    experience.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Guidance for Class 10 and 12</th>
                <td>
                  <p>
                    Class 10 and 12 are crucial stages of your academic journey.
                    We are here to help you navigate them with confidence. Our
                    experienced faculty will empower you with the knowledge,
                    skills, and exam strategies needed to excel in board exams.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Mastering the Board Exam Pattern</th>
                <td>
                  <p>
                    Understanding the Board exam pattern is key to your success.
                    Our teaching methodology focuses on both scholastic and
                    co-scholastic areas. We believe in nurturing not only
                    academic excellence but also physical and mental well-being.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Personalized Learning</th>
                <td>
                  <p>
                    Education is not one-size-fits-all. We recognize your unique
                    learning needs and adapt our teaching methods to cater to
                    them. Our goal is to help you grasp concepts, develop
                    critical thinking skills, and ace your exams.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Beyond the Classroom</th>
                <td>
                  <p>
                    Education extends beyond textbooks. We encourage
                    participation in extracurricular activities, sports, arts,
                    and more. These experiences not only enhance your skills but
                    also shape you into a well-rounded individual.
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className={styles.sections}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "3px solid #dc3545",
              padding: "12px",
              gap: "10px",
            }}
          >
            <h2>TRG HYBRID / Home Tuition Program</h2>
            <p>
              Step onto the path of academic achievement by enrolling in our
              program. Choose the mode that suits you best—Offline Classroom
              Tuition/Home Tuition or Exclusive Online Content.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              border: "3px solid #dc3545",
              padding: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <h2>
                Unlock Your Academic Potential with TRG's Comprehensive All
                Subjects Program!
              </h2>

              <div
                style={{
                  backgroundColor: "#dc3545",
                  padding: "10px 20px",
                }}
              >
                <h2 style={{ textAlign: "center", color: "white" }}>
                  {" "}
                  Subjects Covered
                </h2>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  border: "1px solid #dc3545",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div className={styles.tableDiv}>
                  <p>English</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Physics</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Chemistry</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Mathematics</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Biology</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Accountancy</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Business Studies</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Economics</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Computer science</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Legal studies</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>History</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Political Science</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Geography</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>Psychology</p>
                </div>
                <div className={styles.tableDiv}>
                  <p>All Remaining Subjects.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sections}>
          <div style={{ width: "100%" }}>
            <table className={styles.verticalTable} style={{ width: "100%" }}>
              <tr>
                <th>Excellence Through Expertise</th>
                <td>
                  <p>
                    Experience the advantage of being guided by seasoned
                    educators dedicated to delivering top-notch education. Our
                    experienced teachers are committed to providing you with
                    quality learning that drives results.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Introducing the TRG Level 2 Teaching Advantage</th>
                <td>
                  <p>
                    Empower your child with the adept guidance of our
                    accomplished educators. Our seasoned teachers excel not only
                    in unravelling intricate concepts but also in providing
                    real-time doubt resolution, cultivating personalized 1-on-1
                    interactions with students.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Stay Ahead with Comprehensive Resources</th>
                <td>
                  <p>
                    Our program includes a wide range of resources to ensure
                    your success. Revision Notes & Weekly Tests: Stay organized
                    and assess your progress regularly with our comprehensive
                    study materials and weekly tests.
                  </p>
                </td>
              </tr>
              <tr>
                <th>In-Depth Concept Assimilation</th>
                <td>
                  <p>
                    Delve profoundly into challenging subjects through
                    captivating visual simulations and dynamic storytelling
                    techniques. Your child will confidently master even the most
                    intricate topics.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Exclusive Online Content</th>
                <td>
                  <p>
                    Access a treasure trove of valuable resources through our
                    student portal, designed to enhance your learning
                    experience. Prepare effectively with concise video lessons
                    for efficient last-minute studying.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Real-Time Doubt Mitigation</th>
                <td>
                  <p>
                    Our committed class educators are resolute in promptly
                    tending to doubts during lessons, guaranteeing an
                    uninterrupted learning experience.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Access to TRG Premium App</th>
                <td>
                  <p>
                    Elevate learning with unrestricted access to enriching
                    videos on the TRG Learning App. These resources augment pre
                    and post-class learning, transmuting education into a
                    holistic odyssey.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Regular Assessments and Assignments</th>
                <td>
                  <p>
                    Foster topic comprehension and retention with systematic
                    homework and assessments administered during classes. This
                    approach bolsters understanding and augments knowledge
                    retention.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Teacher’s Support</th>
                <td>
                  <p>
                    Clear your doubts and receive guidance from mentors
                    committed to your academic success.
                  </p>
                </td>
              </tr>
              <tr>
                <th>Student Counselling Available</th>
                <td>
                  <p>
                    We provide personalized counseling to help you overcome any
                    academic challenges you might encounter.
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  <p>
                    Choose the{" "}
                    <span className={styles.bold}> Annual Course</span>, Join
                    our year-long course. EMI option is also available!
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Achieve Your Goals with THE RIGHT GURU HYBRID Tuition Centre</h2>
          <div className={styles.innerSection}>
            <p style={{ marginBottom: 0 }}>
              Choosing TRG Hybrid Tuition Centre for board exam preparation
              ensures students receive the highest quality education and
              support.
            </p>
            <p style={{ marginBottom: 0 }}>
              TRG have Expert Faculty, Completion of syllabus on time, blended
              Hybrid learning, Regular Assessment and Mock test of all subjects,
              Personalized attention, Advanced Learning tools as a result
              Holistic Development of a child including Critical Thinking
              Problem-solving and time management skills.
            </p>
            <p>
              With TRG’s Hybrid Classroom Program, students are well-prepared to
              excel in their board exams and achieve their academic goals.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Cbse_Icse;
