import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../pages/student/dashboard/img/pic_5.png";
import "./navbar.css";
import styles from "./nav.module.css";
import HamburgerIcon from "../icons/hamburger.png"; // Hamburger icon
import CloseIcon from "../icons/close.png"; // Close icon
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import Headroom from "react-headroom";

const CustomNav = (props) => {
  const [openOP, setOpenOP] = useState(false); // Dropdown state
  const [isNavbarOpen, setIsNavbarOpen] = useState(false); // Navbar open/close state

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen); // Toggle navbar state
  };

  return (
    <>
      <Headroom
        style={{
          height: isNavbarOpen ? "auto" : "81px",
          transition: "height 0.3s ease-in-out",
        }}
        calcHeightOnResize={true}
      >
        <div className={styles.main}>
          <Navbar
            style={{ width: "100%" }}
            expand="lg"
            className={styles.navbar}
          >
            <Navbar.Brand as={Link} to="/">
              <img className={styles.logo} src={logo} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              className={`${styles.hamicon} icon-nav`}
              aria-controls="basic-navbar-nav"
              onClick={handleToggle} // Toggle navbar state on click
              style={{
                border: "none",
                display: "flex",
                justifyContent: "center",
                outline: "none",
              }}
            >
              <img
                className="icon-nav"
                width={24}
                src={isNavbarOpen ? CloseIcon : HamburgerIcon} // Show dynamic icon
                alt="icon"
              />
            </Navbar.Toggle>

            <Navbar.Collapse
              id="basic-navbar-nav"
              className={isNavbarOpen ? "show" : ""} // Add Bootstrap's show class
            >
              <Nav className={`${styles.innerNav} ms-auto`}>
                <Nav.Link className="navv-item" as={Link} to="/">
                  <p>Home</p>
                </Nav.Link>
                <Nav.Link
                  className="navv-item"
                  as={Link}
                  to={{ pathname: "https://materials.therightguru.com/" }}
                  target="_blank"
                >
                  <p>Study Material</p>
                </Nav.Link>

                <NavDropdown
                  title={
                    <div className={styles.our_program}>
                      <p>Our Programs</p>
                      {openOP ? (
                        <TiArrowSortedUp
                          style={{ paddingTop: "3px" }}
                          size={20}
                          color="white"
                        />
                      ) : (
                        <TiArrowSortedDown
                          style={{ paddingTop: "3px" }}
                          size={20}
                          color="white"
                        />
                      )}
                    </div>
                  }
                  id="basic-nav-dropdown"
                  onToggle={(isOpen) => setOpenOP(isOpen)} // Dropdown toggle handler
                >
                  <NavDropdown.Item as={Link} to="/cbse-dbse-icse-others">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      CBSE/DBSE/ICSE/OTHERS BOARDS
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/ssc">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>SSC</p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cuet">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>CUET</p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/dsssb">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>DSSSB</p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/clat">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>CLAT</p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/nda-cds">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      NDA & CDS
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/acturial-science">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      ACTUARIAL SCIENCE
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/gre-gmat-sat">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      GRE, GMAT & SAT
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/police-services">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      POLICE SERVICES
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/english-speaking">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      ENGLISH SPEAKING
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/health-recreation">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      HEALTH RECREATION
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/other-competitive-services">
                    <p style={{ color: "#dc3545", fontWeight: "500" }}>
                      OTHER COMPETITIVE SERVICES
                    </p>
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/contact-us">
                  <p>Contact us</p>
                </Nav.Link>
                <Nav.Link as={Link} to="/faqs">
                  <p>FAQs</p>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/trial-student-form">
                    <div className={styles.login_btn}>Book Trial</div>
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link onClick={props.Open}>
                    <div className={styles.login_btn}>Login/Register</div>
                  </Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Headroom>
    </>
  );
};

export default CustomNav;
