import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import styles from "./common_css.module.css";
Modal.setAppElement("#root");

function Clat() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>CLAT</h1>
        </div>
        <div className={styles.sections}>
          <p>
            The Common Law Admission Test (CLAT) is a national-level entrance
            exam in India for admission to undergraduate (UG) and postgraduate
            (PG) law programs at 24 National Law Universities (NLUs) and other
            institutions. The exam tests English, General Knowledge,
            Mathematics, Legal Aptitude, and Logical Reasoning for UG courses,
            and specific areas of law for PG courses.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>TRG HYBRID CLAT Programme Highlights</h2>
          <div>
            The TRG Hybrid CLAT Program offers a blend of online and offline
            coaching tailored for CLAT aspirants. Here are some highlights:
          </div>
          <div className={styles.pSection}>
            <p>
              1. Comprehensive Curriculum: Covers all CLAT subjects, including
              English, General Knowledge, Mathematics, Legal Aptitude, and
              Logical Reasoning.
            </p>
            <p>
              2. Flexible Learning: Combination of live online classes and
              in-person sessions, allowing students to learn at their own pace.
            </p>

            <p>
              3. Experienced Faculty: Expert instructors with years of
              experience in CLAT coaching.
            </p>
            <p>
              4. Mock Tests and Practice Papers: Regular assessments to track
              progress and improve exam readiness.
            </p>
            <p>
              5. Doubt Clearing Sessions: Personalized attention to address
              individual queries and difficulties.
            </p>
            <p>
              6. Study Material: Access to extensive study resources, including
              books, notes, and online materials.
            </p>
            <p>
              7. Performance Analysis: Detailed feedback and performance
              analysis to help students improve their scores.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Official CLAT Website:</h2>
          <p>
            The official website for CLAT is{" "}
            <span>
              <a
                href="https://consortiumofnlus.ac.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                consortiumofnlus.ac.in
              </a>
            </span>
            . All aspects of CLAT, including the submission of application
            forms, publication of results, merit lists, and counseling
            procedures, are exclusively conducted online through the official
            CLAT website.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>CLAT Information</h2>
          <div style={{ width: "100%" }}>
            <table className={styles.normalTable}>
              <tr style={{ width: "100%" }}>
                <th style={{ width: "30%" }}>Exam Name</th>
                <th style={{ width: "70%" }}>
                  CLAT (Common Law Admission Test)
                </th>
              </tr>
              <tr>
                <td>Conducting Body </td>
                <td>The Consortium of NLUs</td>
              </tr>
              <tr>
                <td>Application Mode </td>
                <td>Online</td>
              </tr>
              <tr>
                <td>Exam Frequency </td>
                <td>Once a year</td>
              </tr>
              <tr>
                <td>Exam Mode </td>
                <td>Offline</td>
              </tr>
              <tr>
                <td>Language </td>
                <td>English</td>
              </tr>
              <tr>
                <td>Exam Duration </td>
                <td>2 Hours</td>
              </tr>
              <tr>
                <td>Total Marks </td>
                <td>120</td>
              </tr>
              <tr>
                <td>Total Number of Questions </td>
                <td>120 (MCQ Based)</td>
              </tr>
              <tr>
                <td>Participating institutes </td>
                <td>24 NLUs and 61 affiliated colleges</td>
              </tr>
              <tr>
                <td>Official website </td>
                <td>
                  <a
                    href="https://consortiumofnlus.ac.in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    consortiumofnlus.ac.in
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CLAT UG Eligibility</h2>
          <div className={styles.pSection}>
            <li>
              There is no prescribed age limit set by the conducting body, the
              Consortium of NLUs, for appearing in the CLAT UG exam.
            </li>
            <li>
              To be eligible for CLAT UG, candidates must have obtained a
              minimum of 45% in their class 12 examinations. However, for
              candidates belonging to the reserved categories such as SC/ST, a
              minimum of 40% marks in class 12 from a recognized board is the
              requisite qualification.
            </li>
            <li>
              Candidates appearing for CLAT 2024 are also eligible, but they
              must furnish their qualifying examination certificate at the time
              of admission; failure to provide this certificate will result in
              the cancellation of the candidate's candidature.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CLAT PG Eligibility</h2>
          <div className={styles.pSection}>
            <li>
              For CLAT PG, candidates falling under the
              General/OBC/PWD/NRI/PIO/OCI categories must have scored at least
              50% in their LLB degree course. SC/ST candidates, on the other
              hand, are required to have a minimum of 45% in their LLB.
            </li>
            <li>
              There is no upper age limit for eligibility to appear for the CLAT
              PG exam.
            </li>
            <li>
              Final year students or those in the process of completing their
              LLB degree are eligible to apply for CLAT.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CLAT Registration</h2>
          <div className={styles.pSection}>
            <li>
              Visit the official website of CLAT at consortiumofnlus.ac.in.
            </li>
            <li>Locate and click on the registration link.</li>
            <li>
              Complete the CLAT registration form by providing essential details
              such as name, mobile number, and email address.
            </li>
            <li>
              Subsequently, use the login credentials generated during CLAT
              registration to access the CLAT portal and complete the
              application form.
            </li>
            <li>
              While filling out the application, ensure all required information
              is provided, and upload the necessary documents in the specified
              format.
            </li>
            <li>
              Conclude the application process by making the payment through the
              online payment gateway.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CLAT Required Documents</h2>
          <table className={styles.normalTable}>
            <tr>
              <th style={{ width: "33.33%" }}>Document Required for CLAT</th>
              <th style={{ width: "33.33%" }}>Image size (maximum)</th>
              <th style={{ width: "33.33%" }}>Format</th>
            </tr>
            <tr>
              <td>Photograph</td>
              <td>500 KB</td>
              <td>jpg/jpeg</td>
            </tr>
            <tr>
              <td>Signature</td>
              <td>100 KB</td>
              <td>jpg/jpeg</td>
            </tr>
            <tr>
              <td>State domicile certificate</td>
              <td>2 MB</td>
              <td>PDF</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>CLAT Reservation</h2>
          <p>
            CLAT reservation criteria may vary among NLUs and typically
            encompass various categories.
          </p>
          <table className={styles.normalTable}>
            <tr>
              <th style={{ width: "50%" }}>Scheduled Caste (SC)</th>
              <th style={{ width: "50%" }}>Scheduled Tribe (ST)</th>
            </tr>
            <tr>
              <td>OBC</td>
              <td>Physically Disabled Candidates (PWD)</td>
            </tr>
            <tr>
              <td>State Quota for the candidates in which NLU is located</td>
              <td>NRI/Foreign National candidates</td>
            </tr>
            <tr>
              <td>Kashmiri Migrants</td>
              <td>Candidates belonging to the North-Eastern States</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>CLAT Exam Pattern</h2>
          <p>
            The CLAT exam pattern is the same for both UG and PG levels,
            featuring uniform details like the total number of questions, exam
            mode, and more.
          </p>
          <table className={styles.normalTable}>
            <tr>
              <th style={{ width: "33.33%" }}>CLAT Exam Features</th>
              <th style={{ width: "33.33%" }}>Five-year LLB</th>
              <th style={{ width: "33.33%" }}>One-year LLM</th>
            </tr>
            <tr>
              <td>Exam duration</td>
              <td>2 hours</td>
              <td>2 hours</td>
            </tr>
            <tr>
              <td>Exam mode</td>
              <td>Pen-and-paper mode</td>
              <td>Pen-and-paper mode</td>
            </tr>
            <tr>
              <td>Total questions</td>
              <td>120 questions</td>
              <td>120 questions</td>
            </tr>
            <tr>
              <td>Maximum marks</td>
              <td>120 marks</td>
              <td>120 marks</td>
            </tr>
            <tr>
              <td>Type of questions</td>
              <td>Multiple-Choice Questions (MCQs)</td>
              <td>Multiple-Choice Questions (MCQs)</td>
            </tr>
            <tr>
              <td>Test language</td>
              <td>English</td>
              <td>English</td>
            </tr>
            <tr>
              <td>Marking scheme</td>
              <td>+1 for correct answer -0.25 for incorrect answer</td>
              <td>+1 for correct answer -0.25 for incorrect answer</td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>CLAT Strategy</h2>
          <p>
            To excel in CLAT, students should implement a strategic plan for
            comprehensive syllabus coverage. Here are suggested CLAT preparation
            tips.
          </p>
          <div>
            <h3>Create a Study Routine</h3>
            <li>
              Establish a study routine that aligns with your preferences. This
              ensures adequate time allocation for all syllabus sections.
              Reserve time for revision and relaxation when scheduling.
            </li>
          </div>
          <div>
            <h3>Understand Syllabus and Exam Pattern</h3>
            <li>
              Begin your CLAT preparation by thoroughly grasping the syllabus
              and exam pattern.
            </li>
            <li>
              Familiarize yourself with syllabus topics and identify areas
              requiring more attention.
            </li>
            <li>Review CLAT exam pattern to identify focus areas.</li>
          </div>
          <div>
            <h3>Analyze Previous Question Papers</h3>
            <li>
              Analyze CLAT previous years’ question papers to gain insights into
              question types.
            </li>
            <li>
              Understand the sectional weightage and question difficulty levels
              through this analysis.
            </li>
          </div>
          <div>
            <h3>Utilize Mock Tests</h3>
            <li>Incorporate mock tests into your preparation.</li>
            <li>
              Mock tests provide a realistic exam experience, aiding in time
              management, speed, and accuracy.
            </li>
            <li>
              Initially, take 1 or 2 mock tests, including sectional and
              full-length, gradually increasing frequency.
            </li>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>CLAT Exam Analysis</h2>
          <p>
            The CLAT Exam Analysis for 2024 will be made available after the
            examination has concluded. Exam analysis offers valuable insights
            into the nature and difficulty level of the CLAT exam. The table
            below provides an overview of the exam analysis from the previous
            year's CLAT exam for reference.
          </p>
          <table className={styles.normalTable}>
            <tr>
              <th style={{ width: "33.33%" }}>CLAT Exam Subjects</th>
              <th style={{ width: "33.33%" }}>Number of Questions</th>
              <th style={{ width: "33.33%" }}>Difficulty Level</th>
            </tr>
            <tr>
              <td>English</td>
              <td>30</td>
              <td>English</td>
            </tr>
            <tr>
              <td>Legal Reasoning</td>
              <td>40</td>
              <td>Moderate</td>
            </tr>
            <tr>
              <td>Logical Reasoning</td>
              <td>30</td>
              <td>Easy</td>
            </tr>
            <tr>
              <td>Quantitative Aptitude</td>
              <td>15</td>
              <td>Moderate to Difficult</td>
            </tr>
            <tr>
              <td>Current Affairs (Including GK)</td>
              <td>35</td>
              <td>Moderate to Difficult</td>
            </tr>
            <tr>
              <td>Overall</td>
              <td>150</td>
              <td>Moderate to Difficult</td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>PSU Recruitment through CLAT</h2>
          <p>
            Recruitment to Public Sector Undertakings (PSUs) through CLAT is
            based on candidates' CLAT exam scores. Aspiring individuals can
            leverage their CLAT scorecard to apply for various positions in
            renowned PSU corporations such as BHEL, IOCL, Oil India Limited,
            ONGC, and others. These positions typically include Trainee Legal
            Advisor, Legal Executive Trainee, and Assistant Legal Advisor.
          </p>
        </div>

        <div className={styles.sections}>
          <h2>CLAT 2024 Participating Colleges</h2>
          <table className={styles.normalTable}>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25621-national-law-school-of-india-university-nlsiu-bangalore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Law School of India University
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25585-national-university-of-study-and-research-in-law-nusrl-ranchi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National University of Study and Research in Law
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25366-nalsar-university-of-law-nalsar-hyderabad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Academy of Legal Studies and Research
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25400-national-law-university-and-judicial-academy-nlujaa-guwahati"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Law University and Judicial Academy
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25686-national-law-institute-university-nliu-bhopal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Law Institute University
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/55048-tamil-nadu-national-law-university-tnnlu-tiruchirappalli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tamil Nadu National Law School
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/26016-the-west-bengal-national-university-of-juridical-sciences-nujs-kolkata"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  West Bengal National University of Juridical Sciences
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25347-damodaram-sanjivayya-national-law-university-dsnlu-visakhapatnam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Damodaram Sanjivayya National Law University
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25834-national-law-university-nlu-jodhpur"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Law University Jodhpur
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/55943-maharashtra-national-law-university-mumbai-mnlu-mumbai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Maharashtra National Law University, Mumbai
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25431-hidayatullah-national-law-university-hnlu-raipur"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hidayatullah National Law University
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/59076-maharashtra-national-law-university-mnlu-nagpur"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Maharashtra National Law University, Nagpur
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25489-gujarat-national-law-university-gnlu-gandhinagar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gujarat National Law University
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/college/59487-maharashtra-national-law-university-mnlua-aurangabad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Maharashtra National Law University, Aurangabad
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25936-dr-ram-manohar-lohiya-national-law-university-rmlnlu-lucknow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ram Manohar Lohiya National Law University
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/59920-dharmashastra-national-law-university-mpdnlu-jabalpur"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dharmashastra National Law University, Jabalpur
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25791-rajiv-gandhi-national-university-of-law-rgnul-patiala"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rajiv Gandhi National University of Law
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25652-national-university-of-advanced-legal-studies-nuals-ernakulam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National University of Advanced Legal Studies
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25408-chanakya-national-law-university-cnlu-patna"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chanakya National Law University
                </a>
              </td>
              <td style={{ width: "50%" }}>
                <a
                  href="https://collegedunia.com/university/25762-national-law-university-odisha-nluo-cuttack"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Law University Odisha
                </a>
              </td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>Legal Government Jobs</h2>
          <div className={styles.innerSection}>
            <h3>Central Government Legal Jobs</h3>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Union Public Service Commission (UPSC)
              </li>
              <div>
                <li>Legal Advisor</li>
                <li>Assistant Legal Advisor</li>
                <li>Law Officer</li>
              </div>
            </div>

            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Staff Selection Commission (SSC)
              </li>
              <div>
                <li>Junior Legal Officer</li>
                <li>Legal Assistant</li>
                <li>Law Clerk</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Public Sector Undertakings (PSUs)
              </li>
              <div>
                <li>Legal Advisor</li>
                <li>Assistant Law Officer</li>
                <li>Law Manager</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Ministry of Law and Justice
              </li>
              <div>
                <li>Legal Consultant</li>
                <li>Law Officer</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Parliament of India
              </li>
              <div>
                <li>Legal/Legislative Assistant</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Reserve Bank of India (RBI)
              </li>
              <div>
                <li>Legal Officer</li>
                <li>Assistant Legal Advisor</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Indian Railways
              </li>
              <div>
                <li>Legal Consultant</li>
                <li>Legal Advisor</li>
              </div>
            </div>
          </div>
          <div className={styles.innerSection}>
            <h3>State Government Legal Jobs</h3>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                State Public Service Commissions (PSC)
              </li>
              <div>
                <li>Legal Officer</li>
                <li>Assistant Public Prosecutor</li>
                <li>Legal Assistant</li>
                <li>Stenographer</li>
              </div>
            </div>

            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                State High Courts
              </li>
              <div>
                <li>Judicial Magistrate</li>
                <li>Law Clerk</li>
                <li>Legal Researcher</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                State Law Departments
              </li>
              <div>
                <li>Public Prosecutor</li>
                <li>Assistant District Attorney</li>
                <li>Legal Advisor</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                State Public Sector Undertakings (PSUs)
              </li>
              <div>
                <li>Legal Officer</li>
                <li>Assistant Law Officer</li>
              </div>
            </div>
          </div>
          <div className={styles.innerSection}>
            <h3>Judiciary</h3>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                District Courts
              </li>
              <div>
                <li>Judge</li>
                <li>Magistrate</li>
                <li>Legal Clerk</li>
                <li>Stenographer</li>
              </div>
            </div>

            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>High Courts</li>
              <div>
                <li>Judge</li>
                <li>Law Clerk</li>
                <li>Legal Researcher</li>
                <li>Stenographer</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Supreme Court
              </li>
              <div>
                <li>Judge</li>
                <li>Magistrate</li>
                <li>Legal Clerk</li>
                <li>Registrar</li>
              </div>
            </div>
          </div>
          <div className={styles.innerSection}>
            <h3>Other Legal Jobs</h3>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                National Human Rights Commission (NHRC)
              </li>
              <div>
                <li>Legal Officer</li>
                <li>Legal Assistant</li>
              </div>
            </div>

            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Central Bureau of Investigation (CBI)
              </li>
              <div>
                <li>Legal Advisor</li>
                <li>Public Prosecutor</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Enforcement Directorate (ED)
              </li>
              <div>
                <li>Legal Advisor</li>
                <li>Assistant Legal Advisor</li>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <li style={{ marginLeft: 0, listStyle: "none" }}>
                Various Tribunals and Regulatory Bodies
              </li>
              <div>
                <li>Legal Advisor</li>
                <li>Legal Assistant</li>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>Achieve Your Goals with THE RIGHT GURU HYBRID Tuition Centre</h2>
          <div className={styles.innerSection}>
            <p style={{ marginBottom: 0 }}>
              The TRG Hybrid Classroom Program for CLAT (Common Law Admission
              Test) preparation combines flexible online and offline classes,
              access to expert faculty, and comprehensive study materials. It
              includes interactive sessions, regular mock tests, and
              personalized doubt-solving, ensuring a thorough and effective
              preparation for all sections of the CLAT exam.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Clat;
