import React from "react";
import "./dropdown2.css";
import { Dropdown } from "semantic-ui-react";

const dropdown_samplePpr = (props) => {
  // console.log(props);
  const subjectOptions = [
    {
      key: "English",
      text: "English",
      value: "English",
    },
    {
      key: "Hindi",
      text: "Hindi",
      value: "Hindi",
    },
    {
      key: "EnglishLiterature",
      text: "English Literature",
      value: "EnglishLiterature",
    },
    {
      key: "HindiLiterature",
      text: "Hindi Literature",
      value: "HindiLiterature",
    },
    {
      key: "EnglishGrammer",
      text: "English Grammer",
      value: "EnglishGrammer",
    },
    {
      key: "HindiGrammer",
      text: "Hindi Grammer",
      value: "HindiGrammer",
    },
    {
      key: "History",
      text: "History",
      value: "History",
    },
    {
      key: "Geography",
      text: "Geography",
      value: "Geography",
    },
    {
      key: "Mathematics",
      text: "Mathematics",
      value: "Mathematics",
    },
    {
      key: "Physics",
      text: "Physics",
      value: "Physics",
    },
    // {
    //   key: "EnvironmentalStudies",
    //   text: "Environmental Studies",
    //   value: "EnvironmentalStudies",
    // },
    // {
    //   key: "RhymesHindi",
    //   text: "Rhymes Hindi",
    //   value: "Rhymes Hindi",
    // },
    // {
    //   key: "RhymesEnglish",
    //   text: "Rhymes English",
    //   value: "Rhymes English",
    // },
    {
      key: "Computer",
      text: "Computer",
      value: "Computer",
    },
    // {
    //   key: "GeneralKnowledge",
    //   text: "General Knowledge",
    //   value: "GK",
    // },
    {
      key: "Chemistry",
      text: "Chemistry",
      value: "Chemistry",
    },
    {
      key: "Biology",
      text: "Biology",
      value: "Biology",
    },
    {
      key: "Civics",
      text: "Civics",
      value: "Civics",
    },
    // {
    //   key: "Sanskrit",
    //   text: "Sanskrit",
    //   value: "Sanskrit",
    // },
    {
      key: "SanskritLiterature",
      text: "Sanskrit Literature",
      value: "SanskritLiterature",
    },
    // {
    //   key: "SanskritGrammar",
    //   text: "Sanskrit Grammar",
    //   value: "SanskritGrammar",
    // },
    // {
    //   key: "French",
    //   text: "French",
    //   value: "French",
    // },
    // {
    //   key: "MathematicsAbacus",
    //   text: "Mathematics Abacus",
    //   value: "Mathematics Abacus",
    // },
    // {
    //   key: "VedicMaths",
    //   text: "Vedic Maths",
    //   value: "Vedic Maths",
    // },
    // {
    //   key: " MathematicsHOTS",
    //   text: " Mathematics HOTS",
    //   value: " Mathematics HOTS",
    // },
    // {
    //   key: "UrduLiterature",
    //   text: "Urdu Literature",
    //   value: "Urdu Literature",
    // },
    // {
    //   key: "UrduGrammar",
    //   text: "Urdu Grammar",
    //   value: "Urdu Grammar",
    // },
    // {
    //   key: "PunjabiLiterature",
    //   text: "Punjabi Literature",
    //   value: "Punjabi Literature",
    // },
    // {
    //   key: "PunjabiGrammar",
    //   text: "Punjabi Grammar",
    //   value: "Punjabi Grammar",
    // },
    {
      key: "InformationTechnology",
      text: "Information Technology",
      value: "InformationTechnology",
    },
    // {
    //   key: "Drawing",
    //   text: "Drawing",
    //   value: "Drawing",
    // },
    // {
    //   key: "FrenchLiterature",
    //   text: "French Literature",
    //   value: "French Literature",
    // },
    // {
    //   key: "FrenchGrammar",
    //   text: "French Grammar",
    //   value: "French Grammar",
    // },
    // {
    //   key: "GKCurrentAffairs",
    //   text: "GK Current Affairs",
    //   value: "GK Current Affairs",
    // },
    // {
    //   key: "GKFactsandotherInformation",
    //   text: "GK Facts and other Information",
    //   value: "GK Facts and other Information",
    // },
    // {
    //   key: "LogicalreasoningPuzzles ",
    //   text: "Logical reasoning Puzzles ",
    //   value: "Logical reasoning Puzzles ",
    // },
    // {
    //   key: "LogicalreasoningBrainteasers",
    //   text: "Logical reasoning Brain teasers",
    //   value: "Logical reasoning Brain teasers",
    // },
    // {
    //   key: "HomeScience",
    //   text: "Home Science",
    //   value: "HomeScience",
    // },
    {
      key: "Economics",
      text: "Economics",
      value: "Economics",
    },
    {
      key: "PhysicalEducation",
      text: "Physical Education",
      value: "PhysicalEducation",
    },
    {
      key: "Psychology",
      text: "Psychology",
      value: "Psychology",
    },
    // {
    //   key: "Yoga",
    //   text: "Yoga",
    //   value: "Yoga",
    // },
    // {
    //   key: "Spanish",
    //   text: "Spanish",
    //   value: "Spanish",
    // },
    {
      key: "Sociology",
      text: "Sociology",
      value: "Sociology",
    },
    {
      key: "Accountancy",
      text: "Accountancy",
      value: "Accountancy",
    },
    {
      key: "BusinessStudies",
      text: "Business Studies",
      value: "BusinessStudies",
    },
    // {
    //   key: "EntrepreneurshipDevelopment",
    //   text: "Entrepreneurship Development",
    //   value: "Entrepreneurship Development",
    // },
    // {
    //   key: "German",
    //   text: "German",
    //   value: "German",
    // },
    {
      key: "Science",
      text: "Science",
      value: "Science",
    },
    {
      key: "SocialStudies",
      text: "Social Studies",
      value: "SocialStudies",
    },
  ];

  const gradeOptions = [
    // {
    //   key: "Nur",
    //   text: "Nursery",
    //   value: "Nursery",
    // },
    // {
    //   key: "KG",
    //   text: "Kinder Garden",
    //   value: "KG",
    // },
    // {
    //   key: "Interest",
    //   text: "Interest",
    //   value: "Interest",
    // },
    // {
    //   key: "1",
    //   text: "1",
    //   value: "1",
    // },
    // {
    //   key: "2",
    //   text: "2",
    //   value: "2",
    // },
    // {
    //   key: "3",
    //   text: "3",
    //   value: "3",
    // },
    // {
    //   key: "4",
    //   text: "4",
    //   value: "4",
    // },
    // {
    //   key: "5",
    //   text: "5",
    //   value: "5",
    // },
    // {
    //   key: "6",
    //   text: "6",
    //   value: "6",
    // },
    // {
    //   key: "7",
    //   text: "7",
    //   value: "7",
    // },
    // {
    //   key: "8",
    //   text: "8",
    //   value: "8",
    // },
    {
      key: "9",
      text: "9",
      value: "9",
    },
    {
      key: "10",
      text: "10",
      value: "10",
    },
    {
      key: "11",
      text: "11",
      value: "11",
    },
    {
      key: "12",
      text: "12",
      value: "12",
    },
  ];

  return (
    <div>
      <div className="status-2_iis">
        {/* <div className="Dropdown-lec-notes-2">
          <Dropdown
            placeholder="Choose Subject"
            fluid
            search
            selection
            options={subjectOptions}
            onChange={(e, { value }) => props.handleSubjectSelect(value)}
          />
        </div> */}
        <div className="Dropdown-lec-notes-2">
          <Dropdown
            placeholder="Choose Grade"
            fluid
            search
            selection
            options={gradeOptions}
            onChange={(e, { value }) => props.handleGradeSelect(value)}
          />
        </div>
        {/* Position changed later on for study material page */}
        <div className="Dropdown-lec-notes-2">
          <Dropdown
            placeholder="Choose Subject"
            fluid
            search
            selection
            options={subjectOptions}
            onChange={(e, { value }) => props.handleSubjectSelect(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default dropdown_samplePpr;
