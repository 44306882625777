import React, { useLayoutEffect, useState, useRef } from "react";
import styles from "./franchise.module.css";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Nav2 from "../../../components/header/Nav2";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import TextField from "@mui/material/TextField";
import axios from "axios";

Modal.setAppElement("#root");

function TrgFranchise() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  const [name, setName] = useState("");
  const [emaill, setEmaill] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const formRef = useRef(null);

  const getFormData = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://api.therightguru.com/api/franchise-form/`, {
        name: name,
        email: emaill,
        phone: phoneno,
        city: city,
        state: state,
      });

      // Action to take when the form is submitted successfully
      alert(
        "Form submitted successfully! We will contact you in the next 48 hours."
      );
      setName("");
      setEmaill("");
      setPhoneno("");
      setCity("");
      setState("");
      // Reset the form using the ref
      formRef.current.reset();
    } catch (err) {
      alert("Something went wrong. Please carefully fill out the form again.");
      console.log(err);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>
      <section className={styles.main}>
        <div>
          <h1 className={styles.heading}>RIGHT GURU. COM PVT. LTD.</h1>
          <h1 className={styles.heading2}>FRANCHISE</h1>
          <h2 className={styles.heading3}>
            (Minimum Investment-Maximum Return)
          </h2>
        </div>
        <div>
          <p>
            TRG Hybrid is an innovative educational platform that combines
            traditional classroom teaching with cutting-edge online resources to
            provide a comprehensive and flexible learning experience.
            Established by esteemed education professionals in 2021, TRG Hybrid
            is designed to cater to the diverse needs of students from 1st to
            12th grade and those preparing for competitive exams.
          </p>
        </div>
        <div>
          <h2 className={styles.title}>Main Features</h2>
          <p>
            <span className={styles.bold}>Expert Faculty:</span> Learn from
            highly qualified and experienced educators.
          </p>
          <p>
            <span className={styles.bold}>Blended Learning:</span> Benefit from
            the synergy of in-person classes and online resources for flexible
            and effective learning.
          </p>
          <p>
            <span className={styles.bold}>Personalized Attention:</span> Small
            class sizes ensure individualized support and guidance.
          </p>
          <p>
            <span className={styles.bold}>Comprehensive Curriculum:</span>{" "}
            Thorough coverage of all subjects and topics aligned with
            educational standards and competitive exam requirements.
          </p>
          <p>
            <span className={styles.bold}> Advanced Technology:</span> Utilize
            smart classrooms, multimedia resources, and an interactive online
            platform for an engaging learning experience.
          </p>
          <p>
            <span className={styles.bold}> Regular Assessments:</span>{" "}
            Continuous evaluation through tests and quizzes to monitor progress
            and identify areas for improvement.
          </p>
        </div>
        <div>
          <p>
            <span className={styles.bold}>
              Join TRG Hybrid classroom model to earn maximum return on minimum
              investment.
            </span>
          </p>
        </div>
        <div>
          <h2 className={styles.title}>
            Benefits to Franchisee of TRG Hybrid Tuition Center
          </h2>
          <p>
            Becoming a franchisee of TRG Hybrid Tuition Center offers numerous
            advantages, ensuring a successful and profitable partnership. Here
            are the key benefits:
          </p>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>
                1. Established Brand Recognition
              </span>
            </p>
            <li>
              Reputable Brand: Leverage the credibility and reputation of TRG,
              known for its quality education and innovative teaching methods.
            </li>
            <li>
              Market Trust: Benefit from the trust and loyalty TRG has built
              with students and parents.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>
                2. Comprehensive Training and Support
              </span>
            </p>
            <li>
              Initial Training: Comprehensive training programs for franchisees
              and staff to ensure smooth operations.
            </li>
            <li>
              Ongoing Support: Continuous support in academic, operational, and
              marketing aspects to ensure franchise success.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>3. Proven Business Model</span>
            </p>
            <li>
              Effective Systems: Utilize a tried-and-tested business model with
              established operational procedures.
            </li>
            <li>
              Risk Mitigation: Reduce risks associated with new business
              ventures by adopting a proven framework.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>
                4. Marketing and Advertising Assistance
              </span>
            </p>
            <li>
              National Campaigns: Benefit from national marketing campaigns and
              brand promotions.
            </li>
            <li>
              Local Marketing Support: Assistance with local advertising and
              promotional strategies to attract students.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>5. Curriculum and Content</span>
            </p>
            <li>
              Comprehensive Curriculum: Access to a well-researched and updated
              curriculum that aligns with educational standards.
            </li>
            <li>
              Innovative Content: Use of cutting-edge educational technology and
              multimedia resources to enhance learning.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>6. Technological Integration</span>
            </p>
            <li>
              Smart Classrooms: Equipped with advanced technology to facilitate
              interactive and engaging learning experiences.
            </li>
            <li>
              Online Platform: Access to a robust online platform for
              supplementary lessons, recorded sessions, and digital resources.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>7. Continuous Improvement</span>
            </p>
            <li>
              Regular Updates: Continuous updates to curriculum and teaching
              methods to stay ahead of educational trends.
            </li>
            <li>
              Professional Development: Opportunities for professional
              development and training for staff to maintain high teaching
              standards.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>8. Community and Network</span>
            </p>
            <li>
              Franchise Network: Join a network of like-minded franchisees,
              providing opportunities for collaboration and support.
            </li>
            <li>
              Community Engagement: Engage with the local community through
              educational initiatives and events.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>9. Financial Benefits</span>
            </p>
            <li>
              Cost Efficiency: Economies of scale in purchasing educational
              materials and resources.
            </li>
            <li>
              Community Engagement: Engage with the local community through
              educational initiatives and events.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>10. Student-Centric Approach</span>
            </p>
            <li>
              Holistic Education: Focus on overall development of students,
              including academic excellence and personal growth.
            </li>
            <li>
              Parental Trust: Build strong relationships with parents by
              providing quality education and transparent communication.
            </li>
          </div>

          <p style={{ marginBottom: "10px", marginTop: "10px" }}>
            By partnering with TRG Hybrid Tuition Center, franchisees can
            achieve business success while contributing to the educational
            development of their community. Join us to become part of a thriving
            educational network and make a meaningful impact.
          </p>

          <p style={{ marginBottom: "10px", marginTop: "10px" }}>
            Franchise of{" "}
            <span className={styles.bold}>RIGHT GURU. COM PVT. LTD.</span> is a
            lucrative opportunity for entrepreneurs passionate about education,
            offering the dual benefit of a noble cause and a profitable
            business.
          </p>
        </div>
        <div>
          <h2 className={styles.title}>
            STEPS TO AQUIRE FRANCHISE OF RIGHT GURU.COM PVT LTD.
          </h2>

          <p>
            Acquiring a TRG Hybrid Tuition Center franchise involves a
            structured process to ensure mutual alignment and preparedness. Here
            are the steps to become a TRG franchisee:
          </p>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>1. Initial Inquiry</span>
            </p>
            <li>
              Contact Us: Reach out to our franchise development team through
              our website, email, or phone to express your interest in acquiring
              a franchise.
            </li>
            <li>
              Information Request: Receive detailed information about the TRG
              franchise model, including requirements, benefits, and investment
              details.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>2. Application Submission</span>
            </p>
            <li>
              Franchise Application: Fill out and submit the franchise
              application form provided by TRG. This form includes personal
              details, financial background, and your interest in education.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>3. Initial Screening</span>
            </p>
            <li>
              Application Review: Our team reviews your application to ensure it
              meets our basic criteria.
            </li>
            <li>
              Preliminary Discussion: Participate in an initial discussion or
              interview to discuss your background, goals, and alignment with
              TRG’s vision.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>
                4. Franchise Disclosure Document (FDD)
              </span>
            </p>
            <li>
              Receive FDD: Obtain the Franchise Disclosure Document, which
              includes detailed information about the franchise agreement,
              financial performance, and legal obligations.
            </li>
            <li>
              Review Period: Take time to review the FDD thoroughly, possibly
              with legal or financial advisors.
            </li>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>
                5. Site Selection and Evaluation
              </span>
            </p>
            <li>
              Location Analysis: Work with our team to identify and evaluate
              potential locations for the franchise. Factors include
              demographics, competition, and accessibility.
            </li>
            <li>
              Site Approval: TRG provides guidance and approval for the selected
              location.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>6. Financial Planning</span>
            </p>
            <li>
              Investment Planning: Discuss the investment required, including
              franchise fees, setup costs, and working capital.
            </li>
            <li>
              Funding Arrangements: Arrange necessary funding or financing for
              the investment.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>7. Franchise Agreement</span>
            </p>
            <li>
              Signing Agreement: Review and sign the franchise agreement,
              formalizing the partnership with TRG.
            </li>
            <li>
              Initial Payment: Make the initial franchise fee payment as
              outlined in the agreement.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>8. Training and Onboarding</span>
            </p>
            <li>
              Comprehensive Training: Attend training programs designed for
              franchisees and their staff. Training covers operations,
              curriculum, marketing, and technology use.
            </li>
            <li>
              Operational Support: Receive ongoing support from TRG in setting
              up the center, hiring staff, and launching the program.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>9. Center Setup</span>
            </p>
            <li>
              Infrastructure Development: Develop the infrastructure as per
              TRG’s guidelines, including classrooms, technology setup, and
              branding.
            </li>
            <li>
              Resource Provision: Equip the center with necessary educational
              materials, resources, and technology tools.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>10. Launch and Marketing</span>
            </p>
            <li>
              Pre-Launch Marketing: Implement marketing and promotional
              strategies to create awareness and attract enrollments.
            </li>
            <li>
              Grand Opening: Conduct a grand opening event with support from
              TRG’s marketing team.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>11. Ongoing Support</span>
            </p>
            <li>
              Continuous Assistance: Receive ongoing operational, academic, and
              marketing support to ensure the smooth running of the franchise.
            </li>
            <li>
              Regular Evaluations: Participate in regular evaluations and
              updates to maintain high standards and adapt to new developments.
            </li>
          </div>
          <p style={{ marginBottom: "10px", marginTop: "10px" }}>
            Follow these steps, you can successfully acquire and operate a TRG
            Hybrid Tuition Center franchise, benefiting from our established
            brand, comprehensive support, and proven educational methodologies.
          </p>
        </div>
        <div>
          <h2 className={styles.title}>
            Fill the form and book an appointment our team will contact you.
          </h2>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <div className={styles.fromWraper}>
              <form ref={formRef} onSubmit={getFormData}>
                <TextField
                  style={{ marginBottom: "30px" }}
                  id="outlined-basic"
                  label="Name"
                  type="text"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginBottom: "30px" }}
                  id="outlined-basic"
                  type="email"
                  label="Email"
                  variant="outlined"
                  onChange={(e) => setEmaill(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginBottom: "30px" }}
                  type="number"
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => setPhoneno(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginBottom: "30px" }}
                  id="outlined-basic"
                  label="City"
                  type="text"
                  variant="outlined"
                  onChange={(e) => setCity(e.target.value)}
                  fullWidth
                  required
                />
                <TextField
                  style={{ marginBottom: "30px" }}
                  id="outlined-basic"
                  label="State"
                  type="text"
                  variant="outlined"
                  onChange={(e) => setState(e.target.value)}
                  fullWidth
                  required
                />
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#dc3545",
                    padding: 15,
                    borderRadius: 8,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    cursor: "pointer",
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <p>
            <span className={styles.bold}>For any query,</span>
          </p>

          <p className={styles.content_text}>
            <span className={styles.bold}>Mobile No: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="tel:+919570424242"
            >
              9570-424242
            </a>
          </p>
          <p>
            <span className={styles.bold}>WhatsApp: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/917419197878"
            >
              74-1919-7878
            </a>
          </p>
          <p>
            <span className={styles.bold}>Email: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@therightguru.com"
            >
              contact@therightguru.com
            </a>
          </p>
          <p>
            <span className={styles.bold}>Website: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://therightguru.com"
            >
              https://therightguru.com
            </a>
          </p>

          <p>
            For a more personalized and detailed understanding, we encourage you
            to visit our centers and consult our dedicated counselors.
          </p>
        </div>
      </section>
      <section id="footer">
        <Footer />
      </section>

      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default TrgFranchise;
