import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { Link } from "react-router-dom";
import pic_3 from "./img/pic_3.png";
import pic_2 from "./img/pic_2.png";
import { Button } from "react-bootstrap";
// import ReactTooltip from "react-tooltip";
import moment from "moment";
import {
  FaRegQuestionCircle,
  FaCalendarAlt,
  FaFileAlt,
  FaChartPie,
  FaStickyNote,
  FaPowerOff,
} from "react-icons/fa";
import { MdAssignment, MdDashboard } from "react-icons/md";
// import { BiSupport } from "react-icons/bi";
import { RiQuestionnaireFill } from "react-icons/ri";
import Popup from "../../teacher/popup/popup";
import { removeToken } from "../../../utils";
import { deleteStudent } from "../../../redux/actions/student";

// images
// import line5_1_2x from "./img/line-5-1@2x.png";
// import line5_2x from "./img/line-5@2x.png";
// import line6_2x from "./img/line-6@2x.png";
// import line6_1_2x from "./img/line-6-1@2x.png";
import img5834_1_1_2x from "./img/5834-1-1@2x.png";
import img5834_1_2x from "./img/5834-1@2x.png";
// import orange_ellipse from "./img/ellipse-2@2x.png";
import vector_5_2x from "./img/vector-5@2x.png";
// import rectangle_40_2x from "./img/rectangle-40@2x.png";
// import logo_new2 from "../../../logo_new_v2.png";
// import logo_new from "../../../logo_new_v.png";

// CRUD Requests
import {
  upcomingClass,
  dashboardDoubts,
  pendingAssignments,
} from "../../../crud/student.crud";

import SideDrawerStudent from "../../../UI/sideDrawer/sideDrawerStudent";

const ClassTable = ({ title, data }) => {

  const startClass = (upcomingClasses) => {
    console.log("Upcoming Class Data:", upcomingClasses);
  
    if (!upcomingClasses || !upcomingClasses.datetime) {
      alert("No class data available.");
      return;
    }
  
    let classDate = moment.utc(upcomingClasses.datetime).subtract(10, "minutes");
  
    console.log("Class Start Time (UTC):", upcomingClasses.datetime);
    console.log("Class Can Start After (UTC):", classDate.format());
    console.log("Current Time (UTC):", moment.utc().format());
  
    if (moment.utc().isBefore(classDate)) {
      alert("Can't start class before 10 minutes of start time.");
      return;
    }
  
    if (upcomingClasses.meeting_link && upcomingClasses.meeting_link.startsWith("http")) {
      window.open(upcomingClasses.meeting_link, "_blank");
    } else {
      alert("Invalid or missing meeting link.");
    }
  };
  return (
    <div className="table-container">
      <h3 className="table-title">{title}</h3>
      <div className="table-wrapper">
        <table className="styled-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Class Type</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody> 
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.class_type}</td>
                  <td>{moment(item.datetime).format("DD-MM-YYYY")}</td>
                  <td>{moment(item.datetime).format("HH:mm:ss")}</td>
                  <td>{item.status}</td>
                  <td>
                    <button
                      className="action-btn complete-btn"
                      onClick={() => startClass(item)}
                    >
                      Join
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="no-data">No Up Coming classes found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

  );
};


function Studentdashboard(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const reduxUser = useSelector((state) => state.studentDetails.student);
  const [user, setUser] = useState({});
  const [upClass, setUpClass] = useState({});
  const [doubts, setDoubts] = useState([]);
  const [penAssigns, setPenAssigns] = useState([]);
  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const dispatch = useDispatch();

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  useEffect(() => {
    setUser(reduxUser);
  }, [reduxUser]);

  useEffect(() => {
    upcomingClass(reduxUser.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setUpClass(false);
        } else setUpClass(res);
      })
      .catch((err) => console.log(err));
  }, []);



  let upcomingClasses = [];

  if (Array.isArray(upClass)) {
    upcomingClasses = upClass.filter((cls) => {
      const classTime = moment(cls.datetime);
      return classTime.isAfter(moment().subtract(10, "minutes"));
    });
  }
  useEffect(() => {
    dashboardDoubts(reduxUser.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setDoubts(false);
        } else setDoubts(res);
      })
      .catch((err) => console.log(err));

    pendingAssignments(reduxUser.enrolled_student_id)
      .then((res) => {
        if (res.hasOwnProperty("message")) {
          setPenAssigns(false);
        } else setPenAssigns(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleLogout = () => {
    dispatch(deleteStudent(reduxUser.enrolled_student_id));
    removeToken();
  };
  const joinClass = () => {
    if (!upcomingClasses.length) {
      alert("No class data available.");
      return;
    }

    console.log(moment(upcomingClasses[0].datetime));

    // Subtract 10 minutes from class start time
    let class_date = moment(upcomingClasses[0].datetime).subtract(10, "minutes");

    console.log(class_date);

    if (moment().isBefore(class_date)) {
      alert("Can't start class before 10 minutes of start time.");
    } else {
      if (upcomingClasses[0].meeting_link) {
        window.open(upcomingClasses[0].meeting_link, "_blank");
      }
    }
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="student-dashboard"
      />
      <div className="student-mobile-dashboard screen">
        <div className="component-1-C61RwL">
          <img
            alt="image"
            src={pic_2}
            style={{ height: "90px", marginTop: "-5%", marginLeft: "-15%" }}
          />
        </div>
        <SideDrawerStudent
          open={displaySideDrawer}
          closeSideDrawer={sideDrawerToggler}
        />

        <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
          <div className="rectangle-48-5mfU7d"></div>
          <div className="rectangle-49-5mfU7d"></div>
          <div className="rectangle-50-5mfU7d"></div>
        </div>
        <div className="group-14-C61RwL">
          <div className="rectangle-30-AX7pWk"></div>
          {/* <div className="your-have-5-pen-ere-last-active-AX7pWk">
          <span className="span0-xU0UMX">Your have </span><span className="span1-xU0UMX">{penAssigns.length > 0 && penAssigns.length}</span>
          <span className="span2-xU0UMX"> pending assignments.<br />You were working on&nbsp;&nbsp;</span>
          <span className="span3-xU0UMX">organic-4 notes </span>
          <span className="span4-xU0UMX">when you were last active.</span>
        </div> */}
        </div>
        <img alt="image" className="x5834-1-C61RwL" src={img5834_1_1_2x} />
        <div className="welcome-back-biba-C61RwL montserrat-medium-blue-zodiac-18px">
          <span className="span0-HCKTJj">Welcome back, </span>
          <span className="span1-HCKTJj">{user && user.first_name}!</span>
        </div>
        {upcomingClasses.length > 0 && (
          <>
            <div className="group-15-C61RwL">
              <div className="rectangle-30-wz60dm"></div>
              <div className="mathematcs-section-g-wz60dm">
                <span className="span0-Oq4Dwi">{upcomingClasses[0].subject_id} - </span>
                <span className="span1-Oq4Dwi">{upcomingClasses[0].class_type}</span>
              </div>
              {upcomingClasses.length > 0 ? (
                <div
                  className="upcoming-class-wz60dm montserrat-medium-blue-zodiac-18px"
                  style={{ color: "var(--gray)" }}
                >
                  Upcoming Class
                </div>
              ) : (
                <div
                  className="upcoming-class-wz60dm montserrat-medium-blue-zodiac-18px"
                  style={{ color: "var(--gray)" }}
                >
                  No Upcoming Class
                </div>
              )}
            </div>

            <div className="group-15-C61RwT">
            <ClassTable title="Upcoming Classes" data={upClass} />
            </div>

            <div className="friday-C61RwL">
              {moment(upcomingClasses[0].datetime).format("dddd").toUpperCase()}
            </div>
            <div className="x15-january-C61RwL">
              {moment(upcomingClasses[0].datetime).format("LL").toUpperCase()}
            </div>
            <div className="x0800-am-0900-am-C61RwL">
              {moment(upcomingClasses[0].datetime).format("LT")}
            </div>
            {/* <div className="rectangle-51-C61RwL"></div> */}
            {upcomingClasses.length > 0 ? (
              <Button
                variant="success"
                onClick={() => joinClass()}
                className="rectangle-40-kpakse"
                style={{ fontFamily: "'Montserrat', Helvetica", fontWeight: "600" }}
              >
                Join Class
              </Button>
            ) : (
              <div></div>
            )}

          </>
        )}


        {/* <ClassTable title="Upcoming Classes" data={upClass} /> */}
        {/* <div className="join-class-C61RwL">Join Class</div> */}
        {/* <div className="rectangle-42-C61RwL"></div> */}
        {/* <div className="your-have-no-upcoming-tests-C61RwL">Your have no upcoming tests.</div> */}
        {/* <div className="see-details-C61RwL montserrat-semi-bold-gray-12px">
          See details &gt;
        </div> */}
        <div className="rectangle-44-C61RwL"></div>
        <div className="group-30-C61RwL border-1px-coconut-2"></div>
        <div className="group-81-C61RwL">
          <div className="group-31-7olElM border-1px-coconut-2"></div>
          <div className="subject-7olElM montserrat-semi-bold-blue-zodiac-16px">
            Number: {user.mobile_number}
          </div>
          <div className="assignment-solved-7olElM montserrat-semi-bold-blue-zodiac-16px">
            Country: {user.country}
          </div>
          <div className="number-of-tests-taken-7olElM montserrat-semi-bold-blue-zodiac-16px">
            State: {user.state}
          </div>
          {/* <div className="number-of-tests-given-7olElM montserrat-semi-bold-blue-zodiac-16px">
            Number of Tests Given
          </div> */}
          <div className="assignment-provided-7olElM montserrat-semi-bold-blue-zodiac-16px">
            City: {user.city}
          </div>
        </div>
        {/* <div className="group-82-C61RwL">
          <div className="group-31-ojBfAf border-1px-coconut-2"></div>
          <div className="subject-ojBfAf montserrat-semi-bold-blue-zodiac-16px">
            Subject
          </div>
          <div className="assignment-solved-ojBfAf montserrat-semi-bold-blue-zodiac-16px">
            Assignment Solved
          </div>
          <div className="number-of-tests-taken-ojBfAf montserrat-semi-bold-blue-zodiac-16px">
            Number of Tests Taken
          </div>
          <div className="number-of-tests-given-ojBfAf montserrat-semi-bold-blue-zodiac-16px">
            Number of Tests Given
          </div>
          <div className="assignment-provided-ojBfAf montserrat-semi-bold-blue-zodiac-16px">
            Assignment Provided
          </div>
        </div> */}
        <div className="rectangle-44-VMr6Om">
          {doubts.length > 0 ? (
            doubts.slice(0, 4).map((doubt) => {
              return (
                <div className="doubt-row">
                  <FaRegQuestionCircle size={30} />
                  <Link>
                    <div className="montserrat-semi-bold-blue-zodiac-18px">
                      {doubt.doubt_text.length > 31
                        ? doubt.doubt_text.substring(0, 27) + "..."
                        : doubt.doubt_text}
                    </div>
                  </Link>
                  {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>
        <div className="doubt-forum-C61RwL montserrat-medium-blue-zodiac-18px">
          Doubt Forum
        </div>
        <Link to="/student-doubt-list">
          <div className="view-all-C61RwL montserrat-semi-bold-gray-12px">
            View all
          </div>
        </Link>
        <Link to="/student-assignment-list">
          <div className="pending-assignments-C61RwL montserrat-medium-blue-zodiac-18px">
            Pending Assignments
          </div>
        </Link>
        <div className="view-all-VMr6Om montserrat-semi-bold-gray-12px">
          View all
        </div>
        {/* <div className="rectangle-44-mzXdH9">
        <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
        <div className="assignment-rowCol">
          <div className="montserrat-semi-bold-blue-zodiac-18px">{penAssigns[0].assignment_name}</div>
          <div className="montserrat-semi-bold-gray-12px">Marks: {penAssigns[0].total_marks}</div>
        </div>
      </div> */}

        {penAssigns.length > 0 ? (
          <div className="rectangle-44-mzXdH9">
            <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
            <div className="assignment-rowCol">
              <div className="montserrat-semi-bold-blue-zodiac-18px">
                {penAssigns[0].assignment_name}
              </div>
              <div className="montserrat-semi-bold-gray-12px">
                Marks: {penAssigns[0].total_marks}
              </div>
            </div>
          </div>
        ) : (
          <div className="rectangle-44-mzXdH9">
            <div className="montserrat-semi-bold-blue-zodiac-18px">
              You have no pending assignment.
            </div>
          </div>
        )}

        {penAssigns.length > 1 ? (
          <div className="rectangle-52-C61RwL">
            <MdAssignment size={30} color="rgba(19,37,71,1.0)" />
            <div className="assignment-rowCol">
              <div className="montserrat-semi-bold-blue-zodiac-18px">
                {penAssigns[1].assignment_name}
              </div>
              <div className="montserrat-semi-bold-gray-12px">
                Marks: {penAssigns[1].total_marks}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="student-dashboard screen">
        <div className="rectangle-1-C61RwL">
          <div className="class-in_sdsds">
            <img
              alt="image"
              src={pic_3}
              style={{ height: "115px", marginTop: "-5%", marginLeft: "20%" }}
            />
          </div>

          <Link to="/student-dashboard">
            <div className="meneu-s-djksnsk12">
              <MdDashboard size={24} color="#dc3545" />
              <div className="dashboard-C61RwL">Dashboard</div>
            </div>
          </Link>

          <Link to="/student-date-calendar">
            <div className="meneu-s-djksnsk12">
              <FaCalendarAlt size={24} color="rgba(179,179,179,1.0)" />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Calendar
              </div>
            </div>
          </Link>

          <Link to="/student-assignment-list">
            <div className="meneu-s-djksnsk12">
              <MdAssignment size={24} color="rgba(179,179,179,1.0)" />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Assignments
              </div>
            </div>
          </Link>

          <Link to="/student-quiz-list">
            <div className="meneu-s-djksnsk12">
              <RiQuestionnaireFill size={24} color="rgba(179,179,179,1.0)" />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Quizzes
              </div>
            </div>
          </Link>

          <Link to="/student-curriculum-list">
            <div className="meneu-s-djksnsk12">
              <FaFileAlt size={24} color="rgba(179,179,179,1.0)" />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Curriculums
              </div>
            </div>
          </Link>

          <Link to="/student-doubt-list">
            <div className="meneu-s-djksnsk12">
              <FaRegQuestionCircle size={24} color="rgba(179,179,179,1.0)" />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Doubts
              </div>
            </div>
          </Link>

          <Link to="/student-performance">
            <div className="meneu-s-djksnsk12">
              <FaChartPie size={24} color="rgba(179,179,179,1.0)" />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Performance
              </div>
            </div>
          </Link>

          <Link to="/student-notes-list">
            <div className="meneu-s-djksnsk12">
              <FaStickyNote size={24} color="rgba(179,179,179,1.0)" />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Lecture Notes
              </div>
            </div>
          </Link>

          <Link to="/" onClick={() => handleLogout()}>
            <div className="meneu-s-djksnsk12">
              <FaPowerOff
                size={24}
                data-tip="Logout"
                color="rgba(179,179,179,1.0)"
              />
              <div
                className="montserrat-semi-bold-nobel-18px"
                style={{ color: "rgba(126,126,126,1.0)", marginLeft: "5px" }}
              >
                Logout
              </div>
            </div>
          </Link>
        </div>

        <div className="group-22-GyJUNH-student-dash-s">
          <div className="flex-col-vgli2x-student-dash-s">
            <div className="biba-sobti-h8V8ZR-student-dash-s">
              {user.first_name + " " + user.last_name}
            </div>
            <div className="bibasobtigmailcom-h8V8ZR-student-dash-s">
              {user && user.email}
            </div>
          </div>
          <img className="vector-vgli2x-student-dash-s" src={vector_5_2x} />
        </div>

        <div className="rectangle-30-C61RwL"></div>
        <div className="group-14-C61RwL">
          <div className="rectangle-30-AX7pWk"></div>
          {/* <div className="your-have-5-pen-ere-last-active-AX7pWk">
          <span className="span0-xU0UMX">Your have </span><span className="span1-xU0UMX" style={{color: "#fff"}}>{penAssigns.length > 0 && penAssigns.length}</span
          ><span className="span2-xU0UMX"> pending assignments.<br />You were working on&nbsp;&nbsp;</span
          ><span className="span3-xU0UMX" style={{color: "#fff"}}>organic-4 notes </span
          ><span className="span4-xU0UMX">when <br />you were last active.</span>
        </div> */}
          <div className="welcome-back-biba-AX7pWk montserrat-medium-blue-zodiac-24px">
            <span className="span0-BnRnwt" style={{ color: "#fff" }}>
              Welcome back,{" "}
            </span>
            <span className="span1-BnRnwt" style={{ color: "#fff" }}>
              {user && user.first_name}!
            </span>
          </div>
          <img alt="image" className="x5834-1-AX7pWk" src={img5834_1_2x} />
        </div>
        {upcomingClasses.length > 0 && (
          <>
            <div className="group-13-C61RwL">
              <div className="group-38-r5Ayig"></div>

              {upcomingClasses && upcomingClass.length > 0 ? (
                <div
                  className="upcoming-class-WQXeF1 montserrat-medium-blue-zodiac-24px"
                  style={{ color: "var(--gray)" }}
                >
                  Upcoming Class
                </div>
              ) : (
                <div
                  className="upcoming-class-WQXeF1 montserrat-medium-blue-zodiac-24px"
                  style={{ color: "var(--gray)" }}
                >
                  No Upcoming Class
                </div>
              )}

              <div
                className="subject-wise-progress-WQXeF1 montserrat-medium-blue-zodiac-24px"
                style={{ color: "#dc3545" }}
              >
                My Details
              </div>
              <div className="mathematics-section-g-WQXeF1">
                <span className="span0-SSwiU9">{upcomingClasses[0].subject_id} -</span>
                <span className="span1-SSwiU9">&nbsp;</span>
                <span className="span2-SSwiU9" style={{ color: "#000" }}>
                  {upcomingClasses[0].class_type}
                </span>
              </div>
              <div className="friday-WQXeF1">
                {moment(upcomingClasses[0].datetime).format("dddd").toUpperCase()}
              </div>
              <div className="x15-january-WQXeF1">
                {moment(upcomingClasses[0].datetime).format("LL").toUpperCase()}
              </div>
              <div className="x0800-am-0900-am-WQXeF1" style={{ color: "#000" }}>
                {moment(upcomingClasses[0].datetime).format("LT")}
              </div>
            </div>
          </>
        )}
        <div className="group-13-C61RwT">
          <ClassTable title="Upcoming Classes" data={upClass} />
        </div>
        <div
          className="pending-assignments-C61RwL montserrat-semi-bold-blue-zodiac-18px"
          style={{ color: "#dc3545" }}
        >
          Pending Assignments
        </div>
        {penAssigns.length > 0 ? (
          <div className="rectangle-32-C61RwL">
            <MdAssignment size={30} color="rgba(126,126,126,1.0)" />
            <div className="assignment-rowCol">
              <div
                className="montserrat-semi-bold-blue-zodiac-18px"
                style={{ color: "rgba(126,126,126,1.0)" }}
              >
                {penAssigns[0].assignment_name}
              </div>
              <div className="montserrat-semi-bold-gray-12px">
                Marks: {penAssigns[0].total_marks}
              </div>
            </div>
          </div>
        ) : (
          <div className="rectangle-32-C61RwL">
            <div className="montserrat-semi-bold-blue-zodiac-18px">
              You have no pending assignment.
            </div>
          </div>
        )}

        {penAssigns.length > 1 ? (
          <div className="rectangle-33-C61RwL">
            <MdAssignment size={30} color="rgba(126,126,126,1.0)" />
            <div className="assignment-rowCol">
              <div
                className="montserrat-semi-bold-blue-zodiac-18px"
                style={{ color: "rgba(126,126,126,1.0)" }}
              >
                {penAssigns[1].assignment_name}
              </div>
              <div className="montserrat-semi-bold-gray-12px">
                Marks: {penAssigns[1].total_marks}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
        <div className="group-106-C61RwL">
          {upcomingClasses.length > 0 ? (
            <Button
              variant="success"
              onClick={() => joinClass()}
              className="rectangle-40-PwO50P"
              style={{
                fontFamily: "'Montserrat', Helvetica",
                fontWeight: "600",
              }}
            >
              Join Class
            </Button>
          ) : (
            <div></div>
          )}
        </div>
        {/* <div className="rectangle-42-C61RwL"></div> */}

        {/* <div className="supportagent-24px-1-C61RwL">
        <BiSupport size={24} color="rgba(126,126,126,1.0)" />
      </div> */}
        <div className="rectangle-43-C61RwL">
          <div
            className="doubt-forum-C61RwL montserrat-semi-bold-blue-zodiac-18px"
            style={{ color: "#dc3545" }}
          >
            Doubt Forum
          </div>
          <Link to="/student-doubt-list">
            <div className="view-all-VMr6Om montserrat-semi-bold-gray-12px">
              View all
            </div>
          </Link>

          {doubts.length > 0 ? (
            doubts.slice(0, 4).map((doubt) => {
              return (
                <div className="doubt-row">
                  <FaRegQuestionCircle size={30} />
                  <Link>
                    <div
                      className="montserrat-semi-bold-blue-zodiac-18px"
                      style={{ color: "rgba(126,126,126,1.0)" }}
                    >
                      {doubt.doubt_text.length > 31
                        ? doubt.doubt_text.substring(0, 27) + "..."
                        : doubt.doubt_text}
                    </div>
                  </Link>
                  {/* <div className="view-forum-C61RwL montserrat-semi-bold-gray-16px">View</div> */}
                </div>
              );
            })
          ) : (
            <div />
          )}
        </div>

        <div className="subject-C61RwL montserrat-semi-bold-blue-zodiac-16px">
          Number: {user.mobile_number}
        </div>
        <div className="assignment-solved-C61RwL montserrat-semi-bold-blue-zodiac-16px">
          Country: {user.country}
        </div>
        <div className="number-of-tests-taken-C61RwL montserrat-semi-bold-blue-zodiac-16px">
          State: {user.state}
        </div>
        {/* <div className="number-of-tests-given-C61RwL montserrat-semi-bold-blue-zodiac-16px">
          Number of Tests Given
        </div> */}
        <div className="assignment-provided-C61RwL montserrat-semi-bold-blue-zodiac-16px">
          City: {user.city}
        </div>
      </div>
      <Popup />
    </>
  );
}





export default Studentdashboard;
