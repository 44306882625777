import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import styles from "./common_css.module.css";

Modal.setAppElement("#root");

function NdaCds() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>
      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>NDA & CDS EXAM</h1>
        </div>
        <div className={styles.sections}>
          <h2>NDA (National Defense Academy)</h2>
          <div className={styles.innerSection}>
            <li>
              Purpose: Trains cadets for the Indian Armed Forces (Army, Navy,
              Air Force).
            </li>
            <li>
              Conducted By: Union Public Service Commission (UPSC) twice a year.
            </li>
            <li>
              Eligibility: Candidates aged 16.5 to 19.5 years, with 12th
              standard or equivalent education.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CDS (Combined Defense Services)</h2>
          <div className={styles.innerSection}>
            <li>
              Purpose: Recruits officers for Indian Military Academy (IMA),
              Indian Naval Academy (INA), Air Force Academy (AFA), and Officers
              Training Academy (OTA).
            </li>
            <li>Conducted By: UPSC twice a year.</li>
            <li>
              Eligibility: Graduates aged 19 to 25 years, specific to branch
              requirements.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            Both exams include a written test followed by SSB interviews and
            medical examinations. For more details, refer to the official UPSC
            website/TRG website.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>NDA Exam Details</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.upsc.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.upsc.gov.in
                </a>
              </td>
            </tr>
            <tr>
              <td>Name of the Exam</td>
              <td>National Defence Academy & Naval Academy (NDA)</td>
            </tr>
            <tr>
              <td>Conducting Body</td>
              <td>Union Public Service Commission (UPSC)</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>National</td>
            </tr>
            <tr>
              <td>Exam Frequency</td>
              <td>Twice a year</td>
            </tr>
            <tr>
              <td>Exam Levels</td>
              <td>10+2/HSC</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>NDA</td>
            </tr>
            <tr>
              <td>Exam category</td>
              <td>National</td>
            </tr>
            <tr>
              <td>Exam mode</td>
              <td>Offline</td>
            </tr>
            <tr>
              <td>Stages</td>
              <td>Written exam and SSB Interview</td>
            </tr>
            <tr>
              <td>Exam Cities</td>
              <td>41</td>
            </tr>
            <tr>
              <td>Scope of NDA</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>Admission to</p>
                  <p>1. Army</p>
                  <p>2. Air Force</p>
                  <p>3. Navy</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>As per notification</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>All Over India</td>
            </tr>
            <tr>
              <td>NDA Syllabus</td>
              <td>
                <a href=" https://bit.ly/4f2PlP3" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>CDS Exam Details</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.upsc.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.upsc.gov.in
                </a>
              </td>
            </tr>
            <tr>
              <td>Name of the Exam</td>
              <td>Combined Defense Services Examination</td>
            </tr>
            <tr>
              <td>Conducting Body</td>
              <td>Union Public Service Commission (UPSC)</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>National</td>
            </tr>
            <tr>
              <td>Exam Frequency</td>
              <td>Twice a year</td>
            </tr>
            <tr>
              <td>CDS 2 Vacancy</td>
              <td>According to notification</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Registration Date</td>
              <td>20th December to 9th January</td>
            </tr>
            <tr>
              <td>CDS 1 Exam Date</td>
              <td>Depend on declation</td>
            </tr>
            <tr>
              <td>Selection Process</td>
              <td>Written Test-Interview – Medical Examination</td>
            </tr>
            <tr>
              <td>Number of Sections</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>
                    Indian Military Academy (IMA), Indian Naval Academy (INA)
                    and
                  </p>
                  <p>Air Force Academy (AFA) – Three</p>
                  <p>Officers’ Training Academy (OTA) – Two</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Language of Written Exam</td>
              <td>Bilingual</td>
            </tr>
            <tr>
              <td>Mode of Examination</td>
              <td>Offline</td>
            </tr>
            <tr>
              <td>Type of Question</td>
              <td>Multiple – Choice Questions</td>
            </tr>
            <tr>
              <td>Eligibility</td>
              <td>Unmarried Males & Females</td>
            </tr>
            <tr>
              <td>CDS Exam Duration</td>
              <td>2 Hours (each paper)</td>
            </tr>
            <tr>
              <td>Total marks of the test</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>IMA, INA and AFA – 300</p>
                  <p>OTA - 200</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Making Scheme</td>
              <td>1/3 for each incorrect answer</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>All Over India</td>
            </tr>
            <tr>
              <td>CDS Syllabus</td>
              <td>
                <a href=" https://bit.ly/4f2PlP3" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>ROLE OF TRG IN NDA & CDS</h2>
          <p>
            The TRG Hybrid Coaching Program for NDA and CDS provides a flexible
            blend of online and offline classes, expert faculty guidance,
            comprehensive study materials, interactive sessions, regular mock
            tests, and personalized doubt-solving. This ensures thorough
            preparation, effective time management, and tailored support for all
            aspects of the NDA and CDS exams.
          </p>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default NdaCds;
