import React, { useState } from "react";
import classes from './index.module.css';

import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Message } from 'semantic-ui-react';
import pic_2 from "../student/dashboard/img/pic_2.png";
// import { normalizeUnits } from "moment";


function NewCreds(props) {
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className={classes.Login_outer}>
            <div className={classes.Login_Layout}>
                <img className={classes.Logo_Margin} src={pic_2} width="208px" height="185px" alt="TheRightGuru Logo" />
                <p className={classes.Login_Page_Heading}>Create New Credentials</p>
                <Form className={classes.Login_Form_Handler}>
                    <Form.Input
                        fluid icon='user'
                        iconPosition='left'
                        placeholder='Email Address'
                        onChange={e => setEmail(e.target.value)}
                        className={classes.Login_Form_Input}
                    />
                    <Form.Input
                        fluid icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        onChange={e => setPassword(e.target.value)}
                        className={classes.Login_Form_Input}
                    />

                    <button
                        onClick={() => {}}
                        e='password'
                        onChange={e => setPassword(e.target.value)}
                        disabled={loading}
                        className={classes.Login_Butt_Sub}
                    >
                        Submit
                    </button>
                </Form>
            </div>
        </div>
    )
}

export default NewCreds;