import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import styles from "./common_css.module.css";
Modal.setAppElement("#root");

function Dsssb() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.section}>
          <h1>DSSSB</h1>
        </div>
        <div className={styles.section}>
          <p>
            The Delhi Subordinate Services Selection Board (DSSSB) is an
            organization responsible for recruiting eligible candidates for
            various posts in the departments of the Government of the National
            Capital Territory of Delhi (GNCT of Delhi). Established to ensure a
            transparent and efficient recruitment process, DSSSB conducts
            examinations and interviews for positions in teaching, clerical,
            engineering, and other administrative roles.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>THE RIGHT GURU HYBRID DSSSB CLASSROOM PROGRAM</h2>
          <div className={styles.innerSection}>
            <p>
              The TRG Hybrid DSSSB Classroom Program is a comprehensive
              preparation course tailored for the DSSSB exams, with a structured
              schedule and exclusive features designed to enhance learning and
              ensure success. Here are the details and exclusive features:
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Program Details</h3>
            <li>Duration: 6 months</li>
            <li>Class Hours: 3 hours per day</li>
            <li>Frequency: 4 days a week</li>
          </div>
          <div className={styles.innerSection}>
            <h3>Exclusive Features</h3>
          </div>
          <div className={styles.innerSection}>
            <p>1. Hybrid Learning:</p>
            <li>
              Combination of in-person classroom sessions and online resources.
            </li>
            <li>Flexibility to access study materials online/offline.</li>
          </div>
          <div className={styles.innerSection}>
            <p>2. Experienced Faculty:</p>
            <li>
              Expert instructors with extensive experience in DSSSB exam
              preparation.
            </li>
            <li>Personalized guidance and mentorship.</li>
          </div>
          <div className={styles.innerSection}>
            <p>3. Comprehensive Study Material:</p>
            <li>Updated and meticulously curated study materials.</li>
            <li>
              Topic-wise notes, practice papers, and previous year question
              papers.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>4. Regular Assessments:</p>
            <li>
              Weekly tests to track progress and identify areas for improvement.
            </li>
            <li>Full-length mock tests to simulate exam conditions.</li>
          </div>
          <div className={styles.innerSection}>
            <p>5. Doubt Resolution Sessions:</p>
            <li>Dedicated sessions for clearing doubts and queries.</li>
            <li>
              One-on-one interaction with faculty for personalized assistance.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>6. Performance Analytics:</p>
            <li>Detailed performance analysis and feedback after each test.</li>
            <li>Customized study plans based on individual performance.</li>
          </div>
          <div className={styles.innerSection}>
            <p>7. Interactive Learning Environment:</p>
            <li>
              Interactive classroom sessions to encourage active participation.
            </li>
            <li>Group discussions and peer learning activities.</li>
          </div>
          <div className={styles.innerSection}>
            <p>8. Time Management and Strategy Sessions:</p>
            <li>
              Special sessions on effective time management and exam strategies.
            </li>
            <li>Tips and tricks to enhance speed and accuracy.</li>
          </div>
          <div className={styles.innerSection}>
            <p>9. Supportive Infrastructure:</p>
            <li>Well-equipped classrooms with modern teaching aids.</li>
            <li>Access to library and online resources.</li>
          </div>
          <p>
            This program aims to provide a balanced approach to DSSSB exam
            preparation, combining the benefits of traditional classroom
            learning with the convenience and flexibility of online resources.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB Exam Highlights</h2>
          <p>Check out DSSSB highlights in the table below:</p>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: 600 }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: 600 }}>Details</td>
            </tr>
            <tr>
              <td>
                <p>Name of the Exam</p>
              </td>
              <td>
                <p>DSSSB</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Conducting Body</p>
              </td>
              <td>
                <p>Delhi Subordinate Service Selection Board (DSSSB)</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Exam Level</p>
              </td>
              <td>
                <p>State Level</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Exam frequency</p>
              </td>
              <td>
                <p>Once a year</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Application Mode</p>
              </td>
              <td>
                <p>Online</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Application Fees</p>
              </td>
              <td>
                <p>INR 100</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Mode of Examination</p>
              </td>
              <td>
                <p>Online</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Exam Duration</p>
              </td>
              <td>
                <p>Tier 1: 2 hours Tier 2: 2/ 3 hours</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Language</p>
              </td>
              <td>
                <p>English and Hindi</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Post Name</p>
              </td>
              <td>
                <p>
                  PGT & TGT, Assistant Teacher (Primary & Nursery), Counselor,
                  Junior Secretariat Assistant (LDC), Head Clerk, and Patwari.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Exam Helpdesk No.</p>
              </td>
              <td>
                <p>+91-011-22370309</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Official Website</p>
              </td>
              <td>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://dsssb.delhi.gov.in/home/Delhi-Subordinate-Services-Selection-Board"
                  >
                    http://dsssb.delhi.gov.in
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td>DSSSB Syllabus</td>
              <td>
                <a href="https://bit.ly/trg-dsssb-syllabus" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB Registration Process</h2>
          <div className={styles.innerSection}>
            <p>1. Visit the official DSSSB website.</p>
            <p>2. Click on the "DSSSB Recruitment Online Application" link.</p>
            <p>
              3. Complete the registration by providing essential details like
              your name, date of birth, email address, and mobile number.
            </p>
            <p>
              4. Upon successful registration, you will receive a unique
              registration number and password.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Steps to Apply for DSSSB Application Form</h2>
          <div className={styles.innerSection}>
            <p>
              1. Log in using your assigned registration number and password.
            </p>
            <p>
              2. Fill in the application form with your personal, educational,
              and professional information.
            </p>
            <p>
              3. Upload scanned copies of your recent passport-size photograph
              and signature, adhering to the specified format.
            </p>
            <p>
              4. After filling in all mandatory fields, review the application
              form for accuracy.
            </p>
            <p>5. Submit the application form.</p>
            <p>6. Pay the application fee using the SBI e-pay option.</p>
            <p>
              7. Save a printout of the application form for future reference.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB Application Fee</h2>
          <p>
            The DSSSB Application Fee is Rs. 100/-. However, candidates from
            SC/ST/PH/Ex-servicemen categories and female candidates are exempt
            from paying this fee.
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: 600 }}>Category</td>
              <td style={{ width: "50%", fontWeight: 600 }}>
                Application Fees
              </td>
            </tr>
            <tr>
              <td>General and OBC</td>
              <td>INR 100</td>
            </tr>
            <tr>
              <td>Women/SC/ST/PH/Ex-servicemen</td>
              <td>Exempted</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB Age Limit</h2>
          <p>
            Regarding age limits, these are determined based on the Recruitment
            Rules of different User Departments.
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: 600 }}>
                Name of the Post
              </td>
              <td style={{ width: "50%", fontWeight: 600 }}>
                Maximum Age limit
              </td>
            </tr>
            <tr>
              <td>Trained Graduate Teacher (TGT)</td>
              <td>32 years</td>
            </tr>
            <tr>
              <td>Post Graduate Teacher (PGT)</td>
              <td>36 years</td>
            </tr>
            <tr>
              <td>Assistant Teacher (Nursery)</td>
              <td>30 years</td>
            </tr>
            <tr>
              <td>Assistant Teacher (Primary)</td>
              <td>30 years</td>
            </tr>
            <tr>
              <td>Junior Secretarial Assistant (LDC)</td>
              <td>17-27 years</td>
            </tr>
            <tr>
              <td>Counselor</td>
              <td>30 years</td>
            </tr>
            <tr>
              <td>Head Clerk</td>
              <td>30 years</td>
            </tr>
            <tr>
              <td>Patwari</td>
              <td>21-27 years</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB Educational Qualification</h2>
          <p>
            DSSSB sets specific educational criteria for each position like PGT,
            TGT, Librarian, etc. Refer to the official notification to confirm
            eligibility before applying.
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "30%", fontWeight: 600 }}>Post</td>
              <td style={{ width: "70%", fontWeight: 600 }}>
                Educational Criteria
              </td>
            </tr>
            <tr>
              <td>Librarian</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>Degree from a recognized University or Equivalent</li>
                  <li>
                    Bachelor's Degree or Equivalent Diploma in Library Science
                    or a recognized University/ Institute or Equivalent
                  </li>
                  <li>
                    Experience of Two Years in Library/ computerization of a
                    Library (OR) One year Certificate in Computer Application
                    from a recognized institute or equivalent
                  </li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Assistant Teacher (Nursery)</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>
                    Senior Secondary School (Class Twelve) certificate or
                    Intermediate or its equivalent with at least 45% marks from
                    a recognized Board/ University
                  </li>
                  <li>
                    Diploma/ Certificate in Nursery Teacher Education program of
                    a duration of not less than two years or B.Ed. (Nursery)
                    from a recognized Institute
                  </li>
                  <li>Must have passed Hindi at the secondary level</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Trained Graduate Teacher (Computer Science)</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>
                    Bachelor's degree in computer application (BCA) from a
                    recognized university (OR)
                  </li>
                  <li>
                    Graduation in computer science from a recognized university
                    (provided that the computer science subject must be studied
                    in all years as the main subject) (OR)
                  </li>
                  <li>
                    B.E/B. Tech (Computer Science/Information Technology) from a
                    recognized university (OR)
                  </li>
                  <li>
                    Graduation in any subject and ‘A’ level course from DOEACC,
                    Ministry of Information & Communication and Technology,
                    Govt. of India
                  </li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Physical Education Teacher</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>
                    Graduate with Bachelor of Physical Education (BP. Ed) or its
                    equivalent
                  </li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Manager (Accounts)</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>
                    Qualified Chartered Accountant from Institute of Chartered
                    Accountants of India (OR) MCom from a recognized
                    University/Institute
                  </li>
                </div>
              </td>
            </tr>
            <tr>
              <td>PGT</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>
                    Graduate/master’s degree in the concerned subject.
                    Degree/Diploma in Training/Education
                  </li>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB Exam Pattern</h2>
          <p>
            The DSSSB exam pattern, outlined in the following table, provides a
            reference for candidates. Notably, the question paper for the
            examination is available in both Hindi and English languages,
            ensuring accessibility for all applicants.
          </p>
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ fontWeight: 600 }}>Tier</td>
                <td style={{ fontWeight: 600 }}>Posts</td>
                <td style={{ fontWeight: 600 }}>Total Questions</td>
                <td style={{ fontWeight: 600 }}>Total Marks</td>
              </tr>
              <tr>
                <td>
                  <p>One Tier (General Posts)</p>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>LDC</p>
                    <p>Steno</p>
                    <p>Steno-typist</p>
                    <p>Warder</p>
                    <p>Patwari</p>
                    <p>Driver</p>
                  </div>
                </td>
                <td>
                  <p>Objective Test- 200</p>
                </td>
                <td>
                  <p>200</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>One Tier (Technical/Teaching Posts)</p>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Security Supervisor</p>
                    <p>DEO</p>
                    <p>Librarian</p>
                    <p>Assistant Sanitary Inspector</p>
                    <p>Veterinary and Livestock Inspector</p>
                    <p>Trained Graduate Teacher (TGT)</p>
                    <p>Post Graduate Teacher (PGT)</p>
                    <p>Nurse</p>
                    <p>Sound Technician</p>
                    <p>Primary Teacher</p>
                    <p>Nursery Teacher</p>
                    <p>Lab. Technician</p>
                    <p>Technical Assistant</p>
                  </div>
                </td>
                <td>
                  <p>Objective Test- 200</p>
                </td>
                <td>
                  <p>200</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Two Tier (General Posts)</p>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Grade II-DASS</p>
                    <p>Head Clerk</p>
                    <p>Assistants</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Tier - 1 Objective Test- 200</p>
                    <p>Tier - 2 Objective Test- 200 Descriptive Test- 75</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>200</p>
                    <p>275</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Two Tier (Technical Posts 1)</p>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Junior Engineer</p>
                    <p>Sub Officer</p>
                    <p>Motor Vehicle Inspector</p>
                    <p>Food Inspector</p>
                    <p>Wildlife Inspector</p>
                    <p>Section Officer (Horticulture)</p>
                    <p>Junior Manager (DFC)</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Tier - 1 Objective Test- 200</p>
                    <p>Tier - 2 Objective Test- 200</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>200</p>
                    <p>200</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Two Tier (Technical Posts 2)</p>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Junior Law Officer</p>
                    <p>Welfare Officer Gr. II</p>
                    <p>Publicity Officer</p>
                    <p>District Staff Officer</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Tier - 1 Objective Test - 200</p>
                    <p>Tier - 2 Objective Test- 200</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>200</p>
                    <p>250</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Three Tier (General Posts)</p>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Assistant Assessor & Collector</p>
                    <p>Admin. Officer</p>
                    <p>Zonal Revenue Officer</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Tier - 1 Objective Test- 200</p>
                    <p>
                      Tier - 2 Objective Test- 200 Descriptive Test- 75
                      Interview - 25
                    </p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>200</p>
                    <p>300</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Three Tier (Technical Posts)</p>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Assistant Director Assistant Engineer</p>
                    <p>Assistant Law Officer</p>
                    <p>Manager (DSIIDC)</p>
                    <p>Deputy Manager (DFC)</p>
                    <p>Manager Mech. (DTC)</p>
                    <p>Assistant Architect</p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Tier - 1 Objective Test- 200</p>
                    <p>
                      Tier - 2 Objective Test- 200 Descriptive Test- 50
                      Interview - 25
                    </p>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>200</p>
                    <p>275</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB Selection Process</h2>
          <p>
            The DSSSB Selection Process is a multi-tiered approach that involves
            written examinations. These tiers are designed to evaluate and
            select candidates effectively. The first tier comprises a Computer
            Based Examination, while the second tier is conducted offline. The
            written tests encompass both objective and descriptive questions,
            covering subjects such as English, General Awareness, Mathematics,
            and Reasoning Ability.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>DSSSB POSTS & THEIR QUALIFICATIONS</h2>
          <p>
            The DSSSB Exam offers recruitment opportunities for various
            positions. Here is a list of the positions for which recruitment is
            conducted:
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "30%", fontWeight: 600 }}>Post</td>
              <td style={{ width: "70%", fontWeight: 600 }}>
                Qualification(s) Essential
              </td>
            </tr>
            <tr>
              <td>
                <p>(PRT) Primary Teacher</p>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>
                    Essential requirement: Senior Secondary examination with at
                    least 50% marks and a 2-year Diploma in Elementary Education
                  </p>
                  <p>OR</p>
                  <p>
                    Senior Secondary examination with at least 50% marks and
                    4-year Bachelor of Elementary Education
                  </p>
                  <p>OR</p>
                  <p>
                    Senior Secondary with at least 50% marks and a 2-year
                    Diploma in Special Education OR 50% marks in Bachelor of
                    Education (B.Ed.)
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p>TGT (Any Subject)</p>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>Bachelor's degree from a recognized University.</p>
                  <p>OR</p>
                  <p>
                    BE/B. Tech (for the post/ subject applying for) from a
                    recognized university
                  </p>
                  <p>AND</p>
                  <p>50% marks in Bachelo r of Education (B.Ed.)</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p>TGT Special Education Teacher</p>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>
                    Graduate with Bachelor in Education or B.Ed. Diploma in
                    Special Education
                  </p>
                  <p>OR</p>
                  <p>
                    Exam conducted by CBSE: Centra l Teacher Eligibility Test
                    (CTET)
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p>PGT (Post Graduate Teacher)</p>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>Master’s degree in any one of the following</p>
                  <li>Chemistry</li>
                  <li>Biology</li>
                  <li>Commerce</li>
                  <li>English</li>
                  <li>Geography</li>
                  <li>Punjabi</li>
                  <li>Sociology</li>
                  <li>Economics</li>
                  <li>Hindi</li>
                  <li>Political Science</li>
                  <li>Agriculture</li>
                  <li>Urdu</li>
                  <li>History</li>
                  <li>Math</li>
                  <li>Physics</li>
                  <li>Sanskrit</li>
                  <li>Any Other Subject</li>
                  <p>AND</p>
                  <p>50% marks in Bachelor of Education (B.Ed.)</p>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>Realise Your Dream with TRG Hybrid Program</h2>
          <p>
            The TRG Hybrid Program for DSSSB Exams offers a blend of in-person
            and online coaching to prepare candidates comprehensively. Key
            features include expert instruction, interactive learning tools,
            regular assessments, and personalized guidance. This hybrid approach
            ensures flexible, effective preparation tailored to individual
            needs, enhancing the chances of success in the DSSSB exams.
          </p>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Dsssb;
