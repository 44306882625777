import React, { useState, useEffect } from "react";
import { teacherStudents } from "../../../crud/teacher.crud";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Modal, Card, Form, Input, Checkbox, Spin, notification, DatePicker } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import "./index.css"; // Import the CSS file

const TeacherstudentsModal = ({ visible, onClose }) => {
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [classType, setClassType] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [classDateTime, setClassDateTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.teacherDetails.teacher);

  useEffect(() => {
    if (user.teacher_id) {
      setLoading(true);
      teacherStudents(user.teacher_id)
        .then((res) => {
          setStudents(res.hasOwnProperty("message") ? [] : res);
        })
        .catch((err) => console.error("Error fetching students:", err))
        .finally(() => setLoading(false));
    }
  }, [user.teacher_id]);

  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prev) =>
      prev.includes(studentId) ? prev.filter((id) => id !== studentId) : [...prev, studentId]
    );
  };

  const handleCreateClass = async () => {
    if (!classType) {
      notification.error({ message: "Please select a class type." });
      return;
    }
    if (selectedStudents.length === 0) {
      notification.error({ message: "Please select at least one student." });
      return;
    }
    if (!classDateTime) {
      notification.error({ message: "Please select a class date and time." });
      return;
    }

    // Ensure proper formatting before sending
  const formattedDateTime = moment(classDateTime).format("YYYY-MM-DD HH:mm:ssZ");
    const classData = {
      class_type: classType,
      teacher_id: user.teacher_id,
      datetime: formattedDateTime,
      status: "Scheduled",
      student_ids: selectedStudents,
      meeting_link: meetingLink,
    };

    try {
      setLoading(true);
      await axios.put("https://therightguru.com/api/class-student/0", classData);
      notification.success({ message: "Class created successfully!" });
      setClassType("");
      setMeetingLink("");
      setSelectedStudents([]);
      setClassDateTime(null);
      onClose();
    } catch (error) {
      notification.error({ message: "Failed to create class" });
      console.error("Error:", (error.response && error.response.data) || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Schedule a Class"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
      destroyOnClose
    >
      <Card bordered={false}>
        <Form layout="vertical">
          <Form.Item label="Class Type">
            <Input
              value={classType}
              onChange={(e) => setClassType(e.target.value)}
              placeholder="Enter class type (e.g., Math)"
            />
          </Form.Item>

          <Form.Item label="Meeting Link (Optional)">
            <Input
              type="text"
              placeholder="Enter meeting link"
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Class Date & Time">
            <DatePicker
              showTime
              value={classDateTime ? moment(classDateTime) : null}
              onChange={(date) => setClassDateTime(date)}
              placeholder="Select class date and time"
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <h3 className="mb-3">Select Students</h3>
          {loading ? (
            <div className="text-center">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </div>
          ) : (
            <div className="student-checkbox-container">
              {students.length > 0 ? (
                <div className="students-list">
                  {students.map((student) => (
                    <div key={student.enrolled_student_id} className="student-checkbox">
                      <Checkbox
                        onChange={() => handleStudentSelection(student.enrolled_student_id)}
                        checked={selectedStudents.includes(student.enrolled_student_id)}
                      />
                      <span>{student.first_name} {student.last_name}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No enrolled students found.</p>
              )}
            </div>
          )}

          <Button type="primary" onClick={handleCreateClass} loading={loading} block>
            Create Class
          </Button>
        </Form>
      </Card>
    </Modal>
  );
};

export default TeacherstudentsModal;
