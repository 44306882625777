import React, { useLayoutEffect, useState } from "react";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { useHistory, useLocation } from "react-router-dom";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import styles from "./app.module.css";
import Lead from "./Lead";
import Trialform from "./Trialform";

Modal.setAppElement("#root");

export default function Landing() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;

  const [activeForm, setActiveForm] = useState(
    currentPath.includes("lead-form") ? "lead" : "trial"
  );

  const switchToLeadForm = () => {
    setActiveForm("lead");
    history.push("/lead-form");
  };

  const switchToTrialForm = () => {
    setActiveForm("trial");
    history.push("/trial-student-form");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>
      <section className={styles.main}>
        <div>
          <button
            className={`${styles.toggleButton} ${
              activeForm === "trial" ? styles.activeButton : ""
            }`}
            onClick={switchToTrialForm}
          >
            School
          </button>
          <button
            className={`${styles.toggleButton} ${
              activeForm === "lead" ? styles.activeButton : ""
            }`}
            onClick={switchToLeadForm}
          >
            Competitive exams
          </button>
        </div>
      </section>
      <Trialform /> :
      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}
