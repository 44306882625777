import React, { useLayoutEffect, useState } from "react";
import styles from "./policy.module.css";
import { Link } from "react-router-dom";

import CustomNav from "../../../components/header/Navbar";
import Footer from "../../../components/Footer";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";

const PrivacyPolicy = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }
  const closeModal = () => setIsModalOpen((previousState) => !previousState);

  return (
    <div style={{ width: "100%" }}>
      <section style={{ width: "100%" }}>
        <CustomNav Open={closeModal} />
      </section>
      <section className={styles.main}>
        <div>
          <h1>PRIVACY POLICY</h1>
        </div>
        <div className={styles.sections}>
          <h2>INTRODUCTION</h2>
          <p>
            TRG Hybrid Tuition Platform ("we," "us," "our") is committed to
            protect your privacy. This Privacy Policy outlines how we collect,
            use, disclose, and safeguard your information when you use our
            platform.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>INFORMATION WE COLLECT</h2>
          <div>
            <li>
              <span className={styles.bold}>Personal Information:</span> We
              collect personal information such as name, email address, phone
              number, and payment details when you register for our services.
            </li>
            <li>
              <span className={styles.bold}>Usage Data:</span> We collect data
              on how you use our platform, including IP address, browser type,
              access times, and pages viewed.
            </li>
            <li>
              <span className={styles.bold}>Cookies:</span> We use cookies
              and/or similar technologies to enhance or smoothing your
              experience on our platform.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>USE OF YOUR INFORMATION</h2>
          <div>
            <li>
              We use your personal information to provide, operate, and maintain
              our services.
            </li>
            <li>
              To communicate with you, including sending updates, notifications,
              and promotional materials.
            </li>
            <li>To improve our platform and develop new services.</li>
            <li>To process transactions and manage billing.</li>
            <li>For advertising our services- physically or electronically.</li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>SHARING YOUR INFORMATION</h2>
          <div>
            <p>
              We do not knowingly sell, trade, or otherwise transfer your
              personal information to outside parties, except as described
              below:
            </p>

            <li>
              <span className={styles.bold}>Service Providers:</span> We may
              share your information with third-party service providers who
              perform services to us or you on our behalf.
            </li>
            <li>
              <span className={styles.bold}>Legal Requirements: </span>We
              may/need to disclose your information if required by law or in
              response to valid requests by public authorities with or without
              your consent.
            </li>
            <li>In case/s when we take legal action against you.</li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>DATA SECURITY</h2>
          <p>
            We implement appropriate technical and organizational
            measures/barriers to protect your personal information from
            unauthorized access, alteration, disclosure, or destruction.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>YOUR RIGHTS</h2>
          <div>
            <li>
              You have a right to access, update, or delete your personal
              information. You can manage your account settings and/or contact
              us directly for assistance for this.
            </li>
            <li>
              You have a right to opt-out of receiving marketing communications
              from us.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CHILDREN’S PRIVACY</h2>
          <p>
            All our services are not intended for children under the age of 13.
            We do not knowingly collect personal information from children under
            13. If we become aware that we have collected personal information
            from a child under 13, we will take steps to delete such information
            within our system/s.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>CHANGES TO THIS PRIVACY POLICY</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you (by email or notification/s on our website- so please read
            updates regularly) of any changes by posting the new Privacy Policy
            on our platform and updating the "Effective Date". We do not do this
            by post.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>CONTACT US</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@therightguru.com"
            >
              contact@therightguru.com
            </a>
            .
          </p>
        </div>
        <div className={styles.sections}>
          <h2>EFFECTIVE DATE</h2>
          <div>
            <p>This Privacy Policy is effective as of 01/01/2024.</p>
            <p>
              This Privacy Policy ensures that your personal information is
              handled in a secure and responsible manner while using the TRG
              Hybrid Tuition Platform.
            </p>
          </div>
        </div>
      </section>
      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
};

export default PrivacyPolicy;
