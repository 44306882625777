import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import styles from "./common_css.module.css";
Modal.setAppElement("#root");

function HealthRecreation() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>Health, Fitness, and Recreation Activities</h1>
        </div>
        <div className={styles.sections}>
          <p>
            Engaging in a variety of health, fitness, and recreational
            activities can greatly enhance physical and mental well-being. Here
            is a comprehensive list of activities:
          </p>
        </div>

        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <h3>Health Activities</h3>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>1. Yoga</p>
            <li>Enhances flexibility, strength, and mental clarity.</li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>2. Meditation</p>
            <li>
              Reduces stress, improves concentration, and promotes mental
              well-being.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>3. Pilates</p>
            <li>
              Focuses on core strength, flexibility, and overall body
              conditioning.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>4. Tai Chi</p>
            <li>
              Improves balance, reduces stress, and enhances overall health.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>5. Nutritional Planning</p>
            <li>
              Developing and following a balanced diet to support overall
              health.
            </li>
          </div>
          <div className={styles.innerSection}>
            <h3>Fitness Activities</h3>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>1. Cardio Workouts</p>
            <li>
              Running, jogging, cycling, swimming, and aerobic classes to
              improve cardiovascular health.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>2. Strength Training</p>
            <li>
              Weightlifting, resistance band exercises, and bodyweight workouts
              to build muscle strength.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>
              3. High-Intensity Interval Training (HIIT)
            </p>
            <li>
              Short bursts of intense exercise followed by rest periods to boost
              fitness and burn calories.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>4. Dance Classes</p>
            <li>
              Zumba, hip-hop, ballet, and other dance forms to enhance fitness
              and coordination.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>5. Sports</p>
            <li>
              Basketball, soccer, tennis, badminton, and other team sports to
              improve physical fitness and teamwork skills.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>6. Walking/Hiking</p>
            <li>
              Walking in parks, trails, or hiking in natural environments for
              cardiovascular health and relaxation.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>7. Swimming</p>
            <li>
              A full-body workout that improves cardiovascular health, muscle
              strength, and endurance.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>8. Cycling</p>
            <li>
              Enhances cardiovascular fitness, leg strength, and endurance.
            </li>
          </div>
          <div className={styles.innerSection}>
            <h3>Recreational Activities</h3>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>1. Gardening</p>
            <li>
              Provides moderate physical activity and relaxation, enhancing
              mental wellbeing.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>2. Fishing</p>
            <li>Promotes relaxation and patience while enjoying nature.</li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>3. Photography</p>
            <li>
              Combines creativity with physical activity, especially in nature
              or urban exploration.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>4. Arts and Crafts</p>
            <li>
              Painting, knitting, pottery, and other crafts to stimulate
              creativity and relaxation.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>5. Playing Musical Instruments</p>
            <li>
              Enhances coordination, concentration, and mental well-being.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>6. Board Games and Puzzles</p>
            <li>
              Stimulates cognitive function and provides social interaction.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>7. Traveling</p>
            <li>
              Exploring new places, cultures, and experiences for personal
              growth and relaxation.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>8. Camping</p>
            <li>
              Combines physical activity with relaxation and enjoyment of
              nature.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>9. Bird Watching</p>
            <li>Encourages outdoor activity and mindfulness.</li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>10. Volunteering</p>
            <li>
              Engages in community service, promoting social interaction and a
              sense of purpose.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>11. Rock Climbing</p>
            <li>Develops strength, endurance, and problem-solving skills.</li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>12. Martial Arts</p>
            <li>
              Improves physical fitness, discipline, and self-defence skills.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>13. Skating/Rollerblading</p>
            <li>Enhances balance, coordination, and cardiovascular health.</li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>14. Horseback Riding</p>
            <li>
              Combines physical activity with the enjoyment of working with
              animals.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>15. Surfing/Kayaking</p>
            <li>Provides a full-body workout and a connection with nature.</li>
          </div>
          <div className={styles.innerSection}>
            <p>
              Engaging in these activities can significantly contribute to
              overall health, fitness, and recreational enjoyment, leading to a
              more balanced and fulfilling lifestyle.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>
            Why Choose TRG Hybrid for Health, Fitness, and Recreational
            Activities?
          </h2>
          <p>
            TRG Hybrid is dedicated to promoting holistic development through a
            balanced approach that includes health, fitness, and recreational
            activities. Here’s why TRG Hybrid stands out in this area:
          </p>
          <div className={styles.innerSection}>
            <h3>Key Benefits:</h3>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>1. Holistic Development:</p>
            <li>
              Physical Well-being: Encourages physical activity to enhance
              overall health and fitness.
            </li>
            <li>
              Mental Wellness: Recreational activities that reduce stress and
              promote mental well-being.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>2. Expert Guidance:</p>
            <li>
              Qualified Instructors: Experienced trainers and instructors to
              guide students in fitness and recreational activities.
            </li>
            <li>
              Personalized Programs: Tailored fitness plans and recreational
              activities to meet individual needs and goals.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>3. Comprehensive Programs:</p>
            <li>
              Diverse Activities: Wide range of activities including sports,
              yoga, dance, and outdoor games.
            </li>
            <li>
              Skill Development: Focus on developing physical skills, teamwork,
              and leadership.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>4. Blended Learning Approach:</p>
            <li>
              In-Person Training: Hands-on sessions and classes for physical
              activities.
            </li>
            <li>
              Online Resources: Access to digital content, tutorials, and
              virtual classes for flexibility.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>5. State-of-the-Art Facilities:</p>
            <li>
              Modern Infrastructure: Equipped with advanced fitness equipment
              and facilities.
            </li>
            <li>
              Safe Environment: Ensuring a safe and conducive environment for
              all activities.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>6. Community and Social Interaction:</p>
            <li>
              Group Activities: Promotes social interaction and team spirit
              through group exercises and games.
            </li>
            <li>
              Community Engagement: Opportunities to participate in community
              events and sports meets.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>7. Balanced Lifestyle:</p>
            <li>
              Time Management: Integrating fitness and recreation into daily
              schedules for a balanced lifestyle.
            </li>
            <li>
              Stress Relief: Activities designed to help students unwind and
              manage stress egectively.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p className={styles.bold}>8. Continuous Support and Motivation</p>
            <li>
              Regular Assessments: Monitoring progress and providing feedback to
              keep students motivated.
            </li>
            <li>
              Encouragement: Continuous encouragement and support from trainers
              and peers.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>
              By incorporating health, fitness, and recreational activities, TRG
              Hybrid ensures a wellrounded educational experience that fosters
              physical fitness, mental well-being, and personal growth. Join TRG
              Hybrid to achieve a balanced and healthy lifestyle.
            </p>
          </div>
        </div>

        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default HealthRecreation;
