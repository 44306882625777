import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import styles from "./common_css.module.css";

Modal.setAppElement("#root");

function GreGmatSat() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>
      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>SAT, GMAT & GRE EXAMS</h1>
        </div>
        <div className={styles.sections}>
          <p>
            Competitive exams play a pivotal role in evaluating skills and
            aptitude for various educational programs abroad. The GRE, GMAT, and
            SAT serve distinct purposes:
          </p>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>1. Purpose of Examination</p>
            <li>GRE: Admission to master's and doctoral programs.</li>
            <li>GMAT: Admission to graduate management programs.</li>
            <li>SAT: Admission to undergraduate programs.</li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>2. Acceptance and Scope</p>
            <li>GRE: Primarily used in the USA and a few other countries.</li>
            <li>GMAT: Widely accepted globally.</li>
            <li>SAT: Mainly used by US undergraduate colleges.</li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>3. Aspects Tested</p>
            <li>GRE: Analytical writing, quantitative and verbal reasoning.</li>
            <li>
              GMAT: Quantitative and verbal reasoning, integrated reasoning,
              analytical writing.
            </li>
            <li>SAT: Writing, critical reading, mathematics.</li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>4. Test Formats and Durations</p>
            <li>
              GRE: Computer-based or paper-based, approximately 3 hours and 45
              minutes.
            </li>
            <li>GMAT: Completely computer-based, around 3 hours.</li>
            <li>
              SAT: Solely paper-based, 3 hours or 3 hours 50 minutes with essay.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>5. Scoring Criteria</p>
            <li>GRE: Ranges from 260 to 340, with varying section scores.</li>
            <li>GMAT: Ranges from 200 to 800, with separate section scores.</li>
            <li>
              SAT: Ranges from 400 to 1600, with individual section scores.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>6. Eligibility and Validity</p>
            <li>GRE, GMAT, and SAT are valid for 5 years.</li>
            <li>
              Requirements vary from bachelor's degree graduates to high school
              students.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>SAT Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.collegeboard.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.collegeboard.org/
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of the Exam</td>
              <td>SAT (Digital)</td>
            </tr>
            <tr>
              <td>Conducting Body</td>
              <td>College Board</td>
            </tr>
            <tr>
              <td>Frequency of Conduct</td>
              <td>5 times a year</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>International Level Exam</td>
            </tr>
            <tr>
              <td>Languages</td>
              <td>English</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Mode of Exam</td>
              <td>Online and Offline</td>
            </tr>
            <tr>
              <td>Exam Duration</td>
              <td>2 Hour</td>
            </tr>
            <tr>
              <td>SAT Fee for Indian Students</td>
              <td>Rs. 8,5408* (approx.)</td>
            </tr>
            <tr>
              <td>Score Range</td>
              <td>On a 1600 scale point</td>
            </tr>
            <tr>
              <td>SAT India Country Code</td>
              <td>260</td>
            </tr>
            <tr>
              <td>SAT Syllabus</td>
              <td>
                <a href="https://bit.ly/3Y6fsxB" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>GMAT Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.mba.com/exams/gmat-exam"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.mba.com/exams/gmat-exam
                </a>
              </td>
            </tr>

            <tr>
              <td>Exam name</td>
              <td>Graduate Management Admission Test</td>
            </tr>
            <tr>
              <td>Commonly known as</td>
              <td>GMAT</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>International Level Exam</td>
            </tr>
            <tr>
              <td>Conducting Body</td>
              <td>Graduate Management Admission Council (GMAC)</td>
            </tr>
            <tr>
              <td>Language</td>
              <td>English</td>
            </tr>
            <tr>
              <td>GMAT 2024 Registration mode</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>GMAT 2024 Exam Mode</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>GMAT Current version test duration</td>
              <td>3 hours and 7 min</td>
            </tr>
            <tr>
              <td>GMAT 2024 focus Edition test duration</td>
              <td>2 hours 15 min</td>
            </tr>
            <tr>
              <td>GMAT cost(approx.)</td>
              <td>Rs. 21000*/-</td>
            </tr>
            <tr>
              <td>GMAT Focus Edition exam fee</td>
              <td>Rs. 21000*/-</td>
            </tr>
            <tr>
              <td>GMAT exam fee payment options</td>
              <td>Credit Card and Debit Card</td>
            </tr>
            <tr>
              <td>GMAT Syllabus</td>
              <td>
                <a href="https://bit.ly/3Y6fsxB" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>GRE Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>

            <tr>
              <td>Exam name</td>
              <td>Graduate Record Examinations</td>
            </tr>
            <tr>
              <td>Commonly known as</td>
              <td>GRE</td>
            </tr>
            <tr>
              <td>Exam level</td>
              <td>International Level Exam</td>
            </tr>
            <tr>
              <td>GRE exam conducting body</td>
              <td>Educational Testing Service (ETS)</td>
            </tr>
            <tr>
              <td>GRE 2024 exam format</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>1. GRE General Test</p>
                  <p>2. GRE Subject Tests</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>GRE 2024 registration mode</td>
              <td>Online through an ETS account</td>
            </tr>
            <tr>
              <td>GRE exam modes</td>
              <td>Online/ Offline</td>
            </tr>
            <tr>
              <td>GRE General test duration</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>Online - 3 hours and 45 min</p>
                  <p>Paper-based - 3 hours and 30 min</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>GRE subject test duration</td>
              <td>2 hours and 50 min</td>
            </tr>
            <tr>
              <td>GRE cost - general</td>
              <td>Rs. 22,550*</td>
            </tr>
            <tr>
              <td>GRE fee - subject test</td>
              <td>Rs. 14,500*</td>
            </tr>
            <tr>
              <td>GRE exam fee payment options</td>
              <td>Credit Card/Debit Card, UPI, and Bharat QR payments</td>
            </tr>
            <tr>
              <td>GRE Syllabus</td>
              <td>
                <a href="https://bit.ly/3Y6fsxB" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>TRG WILL HELP YOU REALIZING YOUR DREAMS</h2>
          <p>
            TRG Hybrid Classroom Program is ideal for SAT, GMAT, and GRE
            preparation because it offers flexible online and in-person classes,
            access to experienced faculty, comprehensive study materials,
            interactive sessions, regular mock tests, and personalized support,
            ensuring thorough and tailored preparation for these standardized
            tests.
          </p>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default GreGmatSat;
