import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { TbPointFilled } from "react-icons/tb";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";

import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import styles from "./common_css.module.css";
Modal.setAppElement("#root");

function Other_Cs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>GOVERNMENTS JOBS</h1>
        </div>
        <div className={styles.sections}>
          <p>
            Government jobs in India offer secure employment with various
            benefits across different sectors and departments. These jobs are
            typically divided into central and state government jobs, including
            roles in administrative services, police and law enforcement, public
            sector undertakings (PSUs), defense services, railways, banking,
            education, health, engineering, agriculture, and social services.
            Each job category has specific eligibility criteria, usually
            requiring candidates to pass competitive exams followed by
            interviews and other selection processes.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>Government Jobs After 10th</h2>
          <div
            className={styles.innerSection}
            style={{ marginLeft: "10px", gap: "8px" }}
          >
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>Central Government Jobs</h3>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Indian Railways
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Group D Posts</li>
                  <li>Trackman</li>
                  <li>Helper</li>
                  <li>Porter</li>
                  <li>Assistant Points man</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Indian Army
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Soldier General Duty</li>
                  <li>Tradesman</li>
                  <li>Clerk</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Indian Navy
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Sailor</li>
                  <li>Steward</li>
                  <li>Chef</li>
                  <li>Sanitary Hygienist</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Indian Air Force
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Airman Group Y (Non-Technical Trades)</li>
                  <li>Multi-Tasking Staff</li>
                  <li>Civilian Jobs</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Postal Department
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Postman</li>
                  <li>Mail Guard</li>
                  <li>Multi-Tasking Staff (MTS)</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Staff Selection Commission (SSC)
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Multi-Tasking Staff (MTS)</li>
                </div>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>State Government Jobs</h3>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  State Police Departments
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Constable</li>
                  <li>Driver</li>
                  <li>Peon</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  State Forest Departments
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Forest Guard</li>
                  <li>Wildlife Guard</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  State Transport Departments
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Driver</li>
                  <li>Conductor</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  State Health Departments
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Health Worker</li>
                </div>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>Other Government Jobs</h3>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Public Sector Undertakings (PSUs)
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Helper</li>
                  <li>Attendant</li>
                  <li>Cleaner</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Municipal Corporations
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <li>Sweeper</li>
                  <li>Cleaner</li>
                  <li>Maintenance Worker</li>
                </div>
              </div>
              <div>
                <p style={{ marginLeft: "10px", fontWeight: "500" }}>
                  Gramin Dak Sevak (GDS)
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Government Jobs After 12th</h2>
          <div
            className={styles.innerSection}
            style={{ marginLeft: "10px", gap: "8px" }}
          >
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>Central Government Jobs</h3>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>
                  Staff Selection Commission (SSC)
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <p>SSC Combined Higher Secondary Level (CHSL)</p>
                  <li>Lower Division Clerk (LDC)</li>
                  <li>Data Entry Operator (DEO)</li>
                  <li>Postal Assistant (PA)</li>
                  <li>Sorting Assistant (SA)</li>
                  <p>SSC GD Constable</p>
                  <p>SSC Stenographer (Grade C and D)</p>
                </div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>Indian Railways</p>
                <div style={{ marginLeft: "10px" }}>
                  <p>Railway Recruitment Board (RRB) NTPC</p>
                  <li>Junior Clerk cum Typist</li>
                  <li>Accounts Clerk cum Typist</li>
                  <li>Junior Timekeeper</li>
                  <li>Commercial cum Ticket Clerk</li>
                  <p>Railway Protection Force (RPF) Constable</p>
                </div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>Indian Army</p>
                <li>Technical Entry Scheme (TES)</li>
                <li>Soldier Clerk/Storekeeper Technical</li>
                <li>Soldier Tradesman</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>Indian Navy</p>
                <li>Sailor (SSR/AA)</li>
                <li>Artificer Apprentice</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>Indian Air Force</p>
                <li>Airman Group X (Technical Trades)</li>
                <li>Airman Group Y (Non-Technical Trades)</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>Postal Department</p>
                <li>Gramin Dak Sevak (GDS)</li>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>State Government Jobs</h3>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>
                  State Public Service Commissions (PSC)
                </p>
                <li>Junior Assistant</li>
                <li>Data Entry Operator</li>
                <li>Lower Division Clerk</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>State Police Services</p>
                <li>Police Constable</li>
                <li>Driver Constable</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>State Forest Departments</p>
                <li>Forest Guard</li>
                <li>Wildlife Guard</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>State Transport Departments</p>
                <li>Driver</li>
                <li>Conductor</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>State Health Departments</p>
                <li>Auxiliary Nurse Midwife (ANM)</li>
                <li>Health Worker</li>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>Other Government Jobs</h3>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>
                  Public Sector Undertakings (PSUs)
                </p>
                <li>Clerk</li>
                <li>Assistant</li>
                <li>Technician</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>Banking Sector</p>
                <li>IBPS Clerk</li>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ fontWeight: "500" }}>Municipal Corporations</p>
                <li>Junior Assistant</li>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Government Jobs After Graduation</h2>
          <div
            className={styles.innerSection}
            style={{ marginLeft: "10px", gap: "8px" }}
          >
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>Central Government Jobs</h3>
              <div style={{ marginLeft: "10px", gap: "8px" }}>
                <div>
                  <p style={{ fontWeight: "500" }}>UPSC Civil Services Exam</p>
                  <li>Indian Administrative Service (IAS)</li>
                  <li>Indian Police Service (IPS)</li>
                  <li>Indian Foreign Service (IFS)</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    SSC Combined Graduate Level (CGL) Exam
                  </p>
                  <li>Assistant</li>
                  <li>Sub-Inspector</li>
                  <li>Auditor</li>
                  <li>Tax Assistant</li>
                </div>
                <p style={{ fontWeight: "500" }}>
                  UPSC Engineering Services Exam
                </p>
                <p style={{ fontWeight: "500" }}>RBI Grade B Officer</p>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>State Government Jobs</h3>
              <div style={{ marginLeft: "10px", gap: "8px" }}>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    State Public Service Commissions (PSC)
                  </p>
                  <li>Deputy Collector</li>
                  <li>Block Development Officer</li>
                  <li>State Civil Services</li>
                  <li>State Police Services</li>
                </div>

                <p style={{ fontWeight: "500" }}>State Judicial Services</p>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    State Educational Services
                  </p>
                  <li>Primary School Teacher</li>
                  <li>Lecturer</li>
                  <li>Professor</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Public Sector Undertakings (PSUs)
                  </p>
                  <li>ONGC</li>
                  <li>BHEL</li>
                  <li>SAIL</li>
                  <li>NTPC</li>
                  <li>IOCL</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>Defense Services</p>
                  <li>Indian Army</li>
                  <li>Indian Navy</li>
                  <li>Indian Air Force</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>Railway Jobs</p>
                  <li>Traffic Assistant</li>
                  <li>Senior Clerk cum Typist</li>
                  <li>Junior Account Assistant cum Typist</li>
                  <li>Station Master</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>Banking Sector Jobs</p>
                  <li>IBPS Probationary Officer (PO)</li>
                  <li>SBI PO</li>
                  <li>IBPS Specialist Officer (SO)</li>
                  <li>RBI Assistant</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Police and Law Enforcement Jobs
                  </p>
                  <div style={{ marginLeft: "10px" }}>
                    <p style={{ fontWeight: "500" }}>Banking Sector Jobs</p>
                    <li>Sub-Inspector</li>
                  </div>
                  <li>State Police Services</li>
                  <li>CAPF (Central Armed Police Forces)</li>
                </div>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>Educational Services</h3>
              <div style={{ marginLeft: "10px", gap: "8px" }}>
                <p style={{ fontWeight: "500" }}>
                  UGC NET for Assistant Professor
                </p>
                <p style={{ fontWeight: "500" }}>
                  Central School Teachers (KVS/NVS)
                </p>
                <p style={{ fontWeight: "500" }}>State School Teachers</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>Government Jobs in the Financial Sector</h2>
          <div
            className={styles.innerSection}
            style={{ marginLeft: "10px", gap: "8px" }}
          >
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>
                Central Government Financial Jobs
              </h3>
              <div style={{ marginLeft: "10px", gap: "8px" }}>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Reserve Bank of India (RBI)
                  </p>
                  <li>RBI Grade B Officer</li>
                  <li>RBI Assistant</li>
                  <li>Legal Officer</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Securities and Exchange Board of India (SEBI)
                  </p>
                  <li>SEBI Grade A Officer</li>
                  <li>Assistant Manager</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Insurance Regulatory and Development Authority of India
                    (IRDAI)
                  </p>
                  <li>IRDAI Assistant Manager</li>
                  <li>IRDAI Manager</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>Public Sector Banks</p>
                  <div
                    className={styles.innerSection}
                    style={{ gap: "8px", marginLeft: "10px" }}
                  >
                    <div>
                      <p style={{ fontWeight: "500" }}>
                        State Bank of India (SBI)
                      </p>
                      <li>Probationary Officer (PO)</li>
                      <li>Clerk</li>
                      <li>Specialist Officer (SO)</li>
                    </div>
                    <div>
                      <p style={{ fontWeight: "500" }}>
                        Institute of Banking Personnel Selection (IBPS)
                      </p>
                      <li>Probationary Officer (PO)</li>
                      <li>Clerk</li>
                      <li>Specialist Officer (SO)</li>
                    </div>
                    <div>
                      <p style={{ fontWeight: "500" }}>
                        Regional Rural Banks (RRBs)
                      </p>
                      <li>Office Assistant (Multipurpose)</li>
                      <li>Officer Scale I, II, III</li>
                    </div>
                    <div>
                      <p style={{ fontWeight: "500" }}>
                        Export-Import Bank of India (EXIM Bank)
                      </p>
                      <li>Management Trainee</li>
                      <li>Officer</li>
                    </div>
                  </div>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    National Bank for Agriculture and Rural Development (NABARD)
                  </p>
                  <li>NABARD Grade A Officer</li>
                  <li>NABARD Grade B Officer</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Small Industries Development Bank of India (SIDBI)
                  </p>
                  <li>SIDBI Grade A Officer</li>
                  <li>SIDBI Grade B Officer</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>Ministry of Finance</p>
                  <li>Economic Officer</li>
                  <li>Financial Analyst</li>
                  <li>Tax Assistant</li>
                </div>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>
                State Government Financial Jobs
              </h3>
              <div style={{ marginLeft: "10px", gap: "8px" }}>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    State Public Service Commissions (PSC)
                  </p>
                  <li>Assistant Accounts Officer</li>
                  <li>Finance Officer</li>
                  <li>Tax Inspector</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>State Cooperative Banks</p>
                  <li>Clerk</li>
                  <li>Officer</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    State Treasury Departments
                  </p>
                  <li>Assistant Treasury Officer</li>
                  <li>Accounts Officer</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>State Finance Departments</p>
                  <li>Finance Analyst</li>
                  <li>Budget Officer</li>
                </div>
              </div>
            </div>
            <div style={{ gap: "8px" }} className={styles.innerSection}>
              <h3 style={{ fontWeight: "600" }}>
                Other Financial Regulatory Bodies
              </h3>
              <div style={{ marginLeft: "10px", gap: "8px" }}>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Pension Fund Regulatory and Development Authority (PFRDA)
                  </p>
                  <li>PFRDA Assistant Manager</li>
                  <li>PFRDA Manager</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Insurance Companies (LIC, GIC, etc.)
                  </p>
                  <li>Administrative Officer (AO)</li>
                  <li>Assistant Administrative Officer (AAO)</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    National Housing Bank (NHB)
                  </p>
                  <li>Assistant Manager</li>
                  <li>Manager</li>
                </div>
                <div>
                  <p style={{ fontWeight: "500" }}>
                    Industrial Finance Corporation of India (IFCI)
                  </p>
                  <li>Officer</li>
                  <li>Manager</li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>HOW TRG HELP YOU IN REALISING YOUR DREAMS</h2>
          <p>
            TRG Hybrid Classroom Program helps you realize your dreams by
            offering a flexible blend of online and offline classes, expert
            faculty guidance, comprehensive study materials, personalized
            learning plans, interactive sessions, and regular updates. Our
            dedicated team ensure thorough preparation and effective support for
            achieving your academic and career goals.
          </p>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Other_Cs;
