import React, { useLayoutEffect, useState, useRef } from "react";
import styles from "./internship.module.css";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "./footer.css";
import axios from "axios";

const position = [
  {
    value: "Teachers (PRT, TGT, PGT)",
    label: "Teachers (PRT, TGT, PGT)",
  },
  {
    value:
      "Teachers for Competitive Exams (English, Hindi, GK, Reasoning, Math)",
    label:
      "Teachers for Competitive Exams (English, Hindi, GK, Reasoning, Math)",
  },
  {
    value:
      "Digital Marketing (SEO, social media marketing, email marketing, and analytics.)",
    label:
      "Digital Marketing (SEO, social media marketing, email marketing, and analytics.)",
  },
  {
    value: "Office Coordinator",
    label: "Office Coordinator",
  },
  {
    value: "Content Designer",
    label: "Content Designer",
  },
  {
    value: "Graphic Designer",
    label: "Graphic Designer",
  },
  {
    value: "Customer Service (Counselling)",
    label: "Customer Service (Counselling)",
  },
  {
    value: "Finance and Accounting",
    label: "Finance and Accounting",
  },
  {
    value: "Sales (Field Marketing)",
    label: "Sales (Field Marketing)",
  },
  {
    value: "Tele-call Marketing",
    label: "Tele-call Marketing",
  },
  {
    value: "Receptionist",
    label: "Receptionist",
  },
];

const exams = [
  {
    value: "IIT JEE",
    label: "IIT JEE",
  },
  {
    value: "NEET UG",
    label: "NEET UG",
  },
  {
    value: "CUET",
    label: "CUET",
  },
  {
    value: "SSC",
    label: "SSC",
  },
  {
    value: "CLAT",
    label: "CLAT",
  },
  {
    value: "DSSSB",
    label: "DSSSB",
  },
  {
    value: "Defence Exams",
    label: "Defence Exams",
  },
  {
    value: "State PSC exams",
    label: "State PSC exams",
  },
  {
    value: "NEET PG",
    label: "NEET PG",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const languages = [
  {
    value: "Hinglish",
    label: "Hinglish",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Others",
    label: "Others",
  },
];
const qualifications = [
  {
    value: "Class 12 / Diploma",
    label: "Class 12 / Diploma",
  },
  {
    value: "Graduate",
    label: "Graduate",
  },
  {
    value: "Post Graduate",
    label: "Post Graduate",
  },
  {
    value: "PhD / Doctorate",
    label: "PhD / Doctorate",
  },
];
const experiences = [
  {
    value: "None",
    label: "None",
  },
  {
    value: "0-1 Years",
    label: "0-1 Years",
  },
  {
    value: "1-3 Years",
    label: "1-3 Years",
  },
  {
    value: "3-5 Years",
    label: "3-5 Years",
  },
  {
    value: ">5 Years",
    label: ">5 Years",
  },
];

Modal.setAppElement("#root");

function Intpro() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [emaill, setEmaill] = useState("");
  const [address, setAddress] = useState("");
  const [positionSelected, setPositionSelected] = useState("");
  const [exam, setExam] = useState("");
  const [language, setLanguage] = useState("");
  const [qualification, setQualification] = useState("");
  const [subject, setSubject] = useState("");
  const [experience, setExperience] = useState("");
  const [demolink, setDemoLink] = useState("");
  const [youtubelink, setYoutubeLink] = useState("");
  const formRef = useRef(null);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://api.therightguru.com/api/internship-form/`, {
        name: name,
        phone: mobile,
        email: emaill,
        address: address,
        position: positionSelected,
        exam: exam,
        language: language,
        qualification: qualification,
        subject: subject,
        experience: experience,
        demolink: demolink,
        youtubelink: youtubelink,
      });

      // Action to take when the form is submitted successfully
      alert(
        "Form submitted successfully! We will contact you in the next 48 hours."
      );
      setName("");
      setEmaill("");
      setMobile("");
      setAddress("");
      setPositionSelected("");
      setExam("");
      setLanguage("");
      setQualification("");
      setSubject("");
      setExperience("");
      setDemoLink("");
      setYoutubeLink("");
      // Reset the form using the ref
      formRef.current.reset();
    } catch (err) {
      alert("Something went wrong. Please carefully fill out the form again.");
      console.log(err);
    }
  };

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>
      <section className={styles.main}>
        <div>
          <h1 className={styles.heading}>RIGHT GURU. COM PVT. LTD.</h1>
          <h2 className={styles.heading2}>INTERNSHIP PROGRAM</h2>
        </div>
        <div>
          <h2 className={styles.title}>
            TRG Hybrid Internships Program (THIP)
          </h2>
          <p>
            The THIP has been designed to provide students and recent graduates
            with practical work experience through a blend of inperson and
            online activities. This program aims to enhance professional skills,
            provide industry exposure, and facilitate career development. Here
            are the key features and components of THIP:
          </p>
        </div>
        <div>
          <h2 className={styles.title}>Key features-</h2>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>1. Flexible Structure:</span>
            </p>
            <li>
              Hybrid Model: Combination of on-site and remote work accommodating
              different schedules and preferences.
            </li>
            <li>
              Customizable Duration: Internships of varying lengths to suit the
              needs of participants (e.g., 6 weeks, 3 months, 6 months).
            </li>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>2. Diverse Opportunities:</span>
            </p>
            <li>
              Variety of Fields: Internships available in multiple domains such
              as teaching, content creation, digital marketing, graphic design,
              and more.
            </li>
            <li>
              Cross-Disciplinary Exposure: Opportunities to work on
              interdisciplinary projects and gain holistic experience.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>3. Comprehensive Training:</span>
            </p>
            <li>
              Onboarding Sessions: Initial training to familiarize interns with
              the organization and their roles.
            </li>
            <li>
              Skill Development Workshops: Regular workshops and seminars on
              relevant skills and industry trends.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>4. Mentorship and Guidance:</span>
            </p>
            <li style={{ marginLeft: "10px" }}>
              Experienced Mentors: Guidance from seasoned professionals and
              industry experts.
            </li>
            <li>
              One-on-One Mentoring: Personalized support and feedback throughout
              the internship period.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>5. Real-World Projects:</span>
            </p>
            <li>
              Hands-On Experience: Involvement in actual projects to apply
              theoretical knowledge.
            </li>
            <li>
              Collaborative Work: Opportunities to work in teams and collaborate
              with other interns and professionals.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>6. Performance Evaluation:</span>
            </p>
            <li>
              Regular Assessments: Periodic evaluations to monitor progress and
              provide constructive feedback.
            </li>
            <li>
              Goal Setting and Review: Setting personal and professional goals
              and reviewing achievements regularly.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>7. Networking Opportunities:</span>
            </p>
            <li>
              Professional Connections: Interaction with industry professionals
              and peers.
            </li>
            <li>
              Career Events: Participation in career fairs, networking events,
              and guest lectures.
            </li>
          </div>

          <p>
            <span className={styles.bold}>
              8. Certification and Recognition:
            </span>
          </p>
          <li>
            Internship Certificate: Certification upon successful completion of
            the internship program.
          </li>
          <li>
            Letters of Recommendation: Recommendations based on performance and
            contributions.
          </li>
        </div>
        <div>
          <h2 className={styles.title}>Program Components</h2>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>1. Project-Based Learning:</span>
            </p>
            <li>
              Real Projects: Engaging in meaningful projects that contribute an
              organizations goals.
            </li>
            <li>
              Innovation and Creativity: Encouraging innovative thinking and
              creative problem-solving.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>2. Skill Enhancement:</span>
            </p>
            <li>
              Technical Skills: Training in specific tools, software, and
              methodologies relevant to the internship field.
            </li>
            <li>
              Soft Skills: Development of communication, teamwork, leadership,
              and time management skills.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>3. Industry Exposure:</span>
            </p>
            <li>
              Company Visits: On-site visits to partner companies and industry
              leaders (where applicable).
            </li>
            <li>
              Guest Lectures: Sessions with experts sharing insights and
              experiences from their careers.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>4. Research and Analysis:</span>
            </p>
            <li>
              Market Research: Conducting research and analysis on industry
              trends, competitors, and market conditions.
            </li>
            <li>
              Report Writing: Preparing detailed reports and presentations on
              research findings.
            </li>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p>
              <span className={styles.bold}>5. Professional Development:</span>
            </p>
            <li>
              Career Coaching: Guidance on resume building, interview
              preparation, and job search strategies.
            </li>
            <li>
              Personal Development Plans: Creating plans for continuous
              professional growth and development.
            </li>
          </div>

          <p>
            <span className={styles.bold}>6. Community Engagement:</span>
          </p>
          <li>
            Social Responsibility Projects: Participation in community service
            and social responsibility initiatives.
          </li>
          <li>
            Cultural Activities: Involvement in cultural and team-building
            activities to foster a positive work environment.
          </li>
        </div>

        <div>
          <h2 className={styles.title}>Benefits of the Program</h2>
          <li>
            <span className={styles.bold}> Practical Experience:</span> Gaining
            hands-on experience in a real-world setting.
          </li>
          <li>
            <span className={styles.bold}>Skill Development:</span>Enhancing
            both technical and soft skills relevant to various industries.
          </li>
          <li>
            <span className={styles.bold}>Professional Networking:</span>{" "}
            Building a network of professional contacts and mentors.
          </li>
          <li>
            <span className={styles.bold}>Career Advancement:</span> Improving
            employability and readiness for future career opportunities.
          </li>
          <li>
            <span className={styles.bold}>Holistic Growth:</span> Personal and
            professional growth through a well-rounded internship experience.
          </li>

          <p>
            The THIP is designed to equip participants with the necessary
            skills, knowledge, and experience to excel in their future careers,
            providing a valuable bridge between academic learning and
            professional employment.
          </p>
        </div>
        <div>
          <h2 className={styles.title}>
            <u>TERMS AND CONDITIONS</u>{" "}
          </h2>

          <p>
            <span className={styles.bold}>1. </span> Flexible 6 weeks, 3 months,
            6 months.
          </p>
          <p>
            <span className={styles.bold}>2. </span> Job opportunities in
            future.
          </p>
          <p>
            <span className={styles.bold}>3. </span> Flexible stipend for all
            interns.
          </p>
          <p>
            <span className={styles.bold}>4. </span> Standard incentives and
            rewards apply across all fields.
          </p>
        </div>
        <h2 className={styles.title}>
          Fill the form, our team will contact you.
        </h2>

        {/* form starts from here --- */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <div className={styles.fromWraper}>
            <form ref={formRef} onSubmit={submitForm}>
              <div>
                <p style={{ fontWeight: 500 }}>
                  <span>1. </span>Please enter your name (as per Govt. Id proof)
                  :
                </p>
                <TextField
                  style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                  id="standard-basic"
                  label="Full Name"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>2. </span>Please enter your Contact Number :
                </p>
                <TextField
                  style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                  id="standard-basic"
                  label="Phone Number"
                  variant="standard"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  fullWidth
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>3. </span>Please enter your Email Id :
                </p>
                <TextField
                  style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                  id="standard-basic"
                  label="Email Id"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setEmaill(e.target.value)}
                  required
                />
              </div>
              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>4. </span>Please enter your current residential address
                  :
                </p>
                <TextField
                  style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                  id="standard-basic"
                  label="Address"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>5. </span>Please choose desired position :
                </p>
                <TextField
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    width: "calc(100% - 20px)",
                  }}
                  id="standard-select-position"
                  select
                  label="Select"
                  variant="standard"
                  value={positionSelected}
                  fullWidth
                  onChange={(e) => setPositionSelected(e.target.value)}
                  required
                >
                  {position.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>6. </span>Which exam are you interested in teaching :
                </p>
                <TextField
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    width: "calc(100% - 20px)",
                  }}
                  id="standard-select-position"
                  select
                  label="Select"
                  value={exam}
                  variant="standard"
                  helperText="Required only if 'Teacher' is selected as the desired position."
                  fullWidth
                  onChange={(e) => setExam(e.target.value)}
                >
                  {exams.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>7. </span>Please choose your preferred teaching language
                  :
                </p>
                <TextField
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    width: "calc(100% - 20px)",
                  }}
                  id="standard-select-position"
                  select
                  label="Select"
                  value={language}
                  variant="standard"
                  helperText="Required only if 'Teacher' is selected as the desired position."
                  fullWidth
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  {languages.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>9. </span>Please metion the subject you want to teach :
                </p>
                <TextField
                  style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                  id="standard-basic"
                  label="Subject Name"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setSubject(e.target.value)}
                  helperText="Required only if 'Teacher' is selected as the desired position."
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>8. </span>Please mention your highest educational
                  qualification :
                </p>
                <TextField
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    width: "calc(100% - 20px)",
                  }}
                  id="standard-select-position"
                  select
                  label="Select"
                  value={qualification}
                  variant="standard"
                  fullWidth
                  onChange={(e) => setQualification(e.target.value)}
                  required
                >
                  {qualifications.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>10. </span>Please specify the duration of your relevant
                  experience:
                </p>
                <TextField
                  style={{
                    marginLeft: 20,
                    marginTop: 10,
                    width: "calc(100% - 20px)",
                  }}
                  id="standard-select-position"
                  select
                  label="Select"
                  variant="standard"
                  value={experience}
                  fullWidth
                  onChange={(e) => setExperience(e.target.value)}
                  required
                >
                  {experiences.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>11. </span>Please provide the link of your demo video :
                </p>
                <TextField
                  style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                  id="standard-basic"
                  label="Enter Link"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setDemoLink(e.target.value)}
                  helperText="Any type of media is acceptable to showcase your skills."
                />
              </div>

              <div style={{ marginTop: 30 }}>
                <p style={{ fontWeight: 500 }}>
                  <span>12. </span>Please provide the link of your YouTube
                  Channel(if any ) :
                </p>
                <TextField
                  style={{ marginLeft: 20, width: "calc(100% - 20px)" }}
                  id="standard-basic"
                  label="Enter Link"
                  variant="standard"
                  fullWidth
                  onChange={(e) => setYoutubeLink(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#dc3545",
                    padding: 15,
                    borderRadius: 8,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    cursor: "pointer",
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <p>
            <span className={styles.bold}>For any query,</span>
          </p>

          <p className={styles.content_text}>
            <span className={styles.bold}>Mobile No: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="tel:+919570424242"
            >
              9570-424242
            </a>
          </p>
          <p>
            <span className={styles.bold}>WhatsApp: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/917419197878"
            >
              74-1919-7878
            </a>
          </p>
          <p>
            <span className={styles.bold}>Email: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:contact@therightguru.com"
            >
              contact@therightguru.com
            </a>
          </p>
          <p>
            <span className={styles.bold}>Website: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://therightguru.com"
            >
              https://therightguru.com
            </a>
          </p>

          <p>
            For a more personalized and detailed understanding, we encourage you
            to visit our centers and consult our dedicated counselors.
          </p>
        </div>
      </section>
      <section id="footer">
        <Footer />
      </section>

      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Intpro;
