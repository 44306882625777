import React, { useLayoutEffect, useState } from "react";
import Footer from "../../../components/Footer";
import Modal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginModal from "../../../components/Login_modal/loginModal";
import Popup2 from "../new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import styles from "./about.module.css";

Modal.setAppElement("#root");

export default function AboutUs() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div style={{ width: "100%" }}>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.about_main}>
        <div className={styles.sections}>
          <h1>RIGHT GURU.COM PVT. LTD.</h1>
          <h2 className={styles.about_subTitle}>A hybrid tuition centre</h2>
        </div>
        <div className={styles.sections}>
          <p>
            TRG Hybrid Tuition Centre was established in 2021 by eminent
            personalities in the field of education. Our founders bring a wealth
            of experience and expertise, ensuring high-quality education and
            innovative learning solutions for students.
          </p>
        </div>
        <div className={styles.sections}>
          <p>
            <span className={styles.bold}>Established in 2021:</span> Founded by
            distinguished educators with a long history of excellence in
            education.
          </p>
          <p>
            <span className={styles.bold}>Experienced Leadership:</span> Led by
            experts who have extensive experience in teaching and educational
            administration.
          </p>
          <p>
            <span className={styles.bold}>Innovative Learning:</span> Combines
            traditional classroom learning with modern online resources to
            provide a comprehensive and flexible educational experience.
          </p>
          <p>
            <span className={styles.bold}>Holistic Approach:</span> Focuses on
            academic excellence, personal growth, and career development through
            tailored programs and personalized support.
          </p>
        </div>
        <div className={styles.sections}>
          <p>
            <span className={styles.bold}>TRG Hybrid Tuition Centre</span> and
            benefit from the experience and expertise of our esteemed founders
            and faculty. Our innovative programs are designed to help you
            achieve your academic and career goals effectively and efficiently.
          </p>
        </div>
        <div className={styles.sections}>
          <p>
            <span className={styles.bold}>Right Guru. Com Pvt. Ltd.</span>,
            where traditional learning meets modern technology to create a
            dynamic and flexible educational experience. We are a pioneering
            institution committed to delivering quality education through a
            blend of in-person and online learning methodologies, tailored to
            meet the diverse needs of today's learners.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>Our Vision</h2>
          <p>
            At Right Guru. Com Pvt. Ltd., our vision is to revolutionize
            education by integrating the best of both worlds - the structured,
            immersive environment of classroom learning and the accessibility
            and flexibility of online education. We aim to empower students with
            the knowledge, skills, and confidence they need to succeed in an
            ever-evolving global landscape. TRG empowers students to achieve
            their academic goals in CBSE/DBSE/ICSE Bords exams, after that
            students able clear various competitive examinations i.e. SSC,
            DSSSB, CLAT, NDA & CDS, CUET & many more.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>Our Mission</h2>
          <h3>
            Our mission is to provide a comprehensive educational platform that:
          </h3>
          <ul>
            <li>
              Delivers high-quality, accessible education to students regardless
              of their location.
            </li>
            <li>
              Fosters a collaborative and interactive learning environment.
            </li>
            <li>
              Utilizes cutting-edge technology to enhance learning experiences.
            </li>
            <li>Encourages lifelong learning and personal growth.</li>
          </ul>
        </div>
        <div className={styles.sections}>
          <h2>What We Offer</h2>
          <div>
            <h3>Blended Learning Programs:</h3>
            <p>
              Our courses are designed to combine face-to-face instruction with
              online resources, ensuring that students receive a balanced and
              enriching education. This approach allows for greater flexibility,
              enabling students to learn at their own pace while benefiting from
              direct interaction with instructors and peers.
            </p>
          </div>
          <div>
            <h3>Expert Faculty: </h3>
            <p>
              Our team of experienced educators and industry experts are
              dedicated to providing personalized instruction and support. They
              bring a wealth of knowledge and practical experience, ensuring
              that our students are well-prepared for academic and professional
              success.
            </p>
          </div>
          <div>
            <h3>Interactive Learning Environment</h3>
            <p>
              We leverage the latest educational technologies to create
              interactive and engaging learning experiences. Our platform
              supports a variety of multimedia content, live sessions, and
              collaborative tools, making learning more effective and enjoyable.
            </p>
          </div>
          <div>
            <h3>Flexible Schedules: </h3>
            <p>
              Understanding the diverse needs of our students, we offer flexible
              scheduling options that accommodate different lifestyles and
              commitments. Whether you are a working professional, a student, or
              a lifelong learner, our programs are designed to fit seamlessly
              into your life.
            </p>
          </div>
          <div>
            <h3>Affordable Education: </h3>
            <p>
              We believe that quality education should be accessible to all. Our
              competitive pricing and various financial aid options ensure that
              students can achieve their educational goals without financial
              burden.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Our Values</h2>

          <ul>
            <li>
              <span className={styles.bold}>Innovation:</span> We embrace new
              ideas and technologies to continuously improve our educational
              offerings.
            </li>
            <li>
              <span className={styles.bold}>Excellence:</span> We strive for the
              highest standards in teaching, learning, and student outcomes.
            </li>
            <li>
              <span className={styles.bold}>Accessibility:</span> We are
              committed to making education accessible to everyone, regardless
              of geographical or financial barriers.
            </li>
            <li>
              <span className={styles.bold}>Integrity:</span> We uphold the
              highest ethical standards in all our operations and interactions.
            </li>
            <li>
              <span className={styles.bold}>Community:</span> We foster a
              supportive and inclusive community where students can thrive.
            </li>
          </ul>
        </div>
        <div className={styles.sections}>
          <h2>Join Us</h2>
          <p>
            At Right Guru. Com Pvt. Ltd., we are passionate about education and
            dedicated to helping our students reach their full potential. Join
            us on this exciting journey and experience the future of learning
            today. You can join TRG as{" "}
            <span className={styles.bold}>
              {" "}
              a student, a teacher, a home tutor, a graphic designer, a content
              writer, and many more.
            </span>
          </p>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <p>
              <span className={styles.bold}>Call : </span>9570-424242,
              74-1919-7878
            </p>
            <p>
              <span className={styles.bold}>Email : </span>{" "}
              <a href="mailto:contact@therightguru.com">
                contact@therightguru.com{" "}
              </a>
            </p>
            <p>
              <span className={styles.bold}>Web : </span>{" "}
              <a
                href="https://therightguru.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                https://therightguru.com/{" "}
              </a>
            </p>
            <p>
              <span className={styles.bold}>Visit : </span>42 SF Omaxe Galleria,
              Jhajjar Road Sector 14, Bahadurgarh (NCR REGION) Haryana 124-507
            </p>
          </div>
        </div>
      </section>

      <section id="footer">
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}
