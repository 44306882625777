import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import styles from "./services.module.css";

import { Container, Row, Col } from "react-bootstrap";

// cards
import study from "./cards_pic/studymaterial.png";
import cbse from "./cards_pic/cbse.png";
import ssc from "./cards_pic/ssc.png";
import cuet from "./cards_pic/cuet.png";
import clat from "./cards_pic/clat.png";
import dsssb from "./cards_pic/dsssb.png";
import ndacds from "./cards_pic/ndacds.png";
import english from "./cards_pic/english.png";
import health from "./cards_pic/health.png";
import competitive from "./cards_pic/competitive.png";
import acturial from "./cards_pic/acturial.jpg";
import gmat from "./cards_pic/gmat.jpg";

function OurServices_Courses() {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.cards}>
          <img src={study} alt="image cards" />
          <div className={styles.links}>
            <Link
              className={styles.link}
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  "https://materials.therightguru.com/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              STUDY MATERIALS
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={cbse} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/cbse-dbse-icse-others">
              CBSE & OTHER BOARDS
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={ssc} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/ssc">
              SSC EXAM
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={cuet} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/cuet">
              CUET EXAM
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={clat} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/clat">
              CLAT EXAM
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={dsssb} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/dsssb">
              DSSSB EXAM
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={ndacds} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/nda-cds">
              NDA AND CDS
            </Link>
          </div>
        </div>

        <div className={styles.cards}>
          <img src={acturial} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/acturial-science">
              ACTUARIAL SCIENCE EXAM
            </Link>
          </div>
        </div>

        <div className={styles.cards}>
          <img src={gmat} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/gre-gmat-sat">
              GRE, GMAT & SAT EXAM
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={english} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/english-speaking">
              ENGLISH SPEAKING
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={health} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/health-recreation">
              HEALTH & RECREATION
            </Link>
          </div>
        </div>
        <div className={styles.cards}>
          <img src={competitive} alt="image cards" />
          <div className={styles.links}>
            <Link className={styles.link} to="/other-competitive-services">
              OTHER COMPETITIVE SERVICES
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices_Courses;
