import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import styles from "./common_css.module.css";
Modal.setAppElement("#root");

function Ssc() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>SSC (Staff Selection Commission)</h1>
        </div>
        <div className={styles.sections}>
          <div className={styles.infoBox}>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Class Timing
                </h2>
              </div>
              <p style={{ fontWeight: "600", textAlign: "center" }}>
                8:00 AM to 8:00 PM
              </p>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Home Tuitions
                </h2>
              </div>
              <p style={{ fontWeight: "600", textAlign: "center" }}>
                SSC CGL, CHSL, CPO, JE, MTS
              </p>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Contact Us
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  margin: "auto",
                }}
              >
                <p style={{ fontWeight: "600" }}>9570-424242</p>
                <p style={{ fontWeight: "600" }}>74-1919-7878</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sections} style={{ alignItems: "center" }}>
          <div className={styles.btnDiv}>
            <Link to="/trial-student-form">
              <h2 style={{ color: "white" }} className={styles.book_btn}>
                Book Free Trial
              </h2>
            </Link>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>About ‘TRG’ SSC CGL, CHSL, CPO, JE, MTS 6 Months Program</h2>
          <p>
            Since 2021, The Right Guru (TRG) has been a prominent player in the
            field of quality education, particularly for competitive exams like
            the SSC CGL, CHSL, JE, CPO, MTS. With a dedicated team and a
            commitment to excellence, TRG understands the unique requirements of
            aspirants striving to excel in these competitive exams and
            contribute to their career growth.
          </p>
          <p>
            Introducing the TRG SSC 6-months Classroom Program for various post,
            our aim is to address the specific needs of aspirants preparing for
            the prestigious SSC CGL examination. This program is designed to
            equip students with a solid foundation and advanced knowledge,
            ensuring their preparedness for all stages of the SSC examinations,
            from the Tier I Prelims to the Tier III Descriptive Paper and the
            subsequent skill tests.
          </p>
          <p>
            Our pedagogical approach is carefully crafted to facilitate a smooth
            learning curve, starting from the fundamentals and progressing to
            more advanced concepts, in alignment with the SSC syllabus. Through
            a comprehensive and well-structured schedule, we ensure timely
            syllabus coverage and allow ample time for thorough revision. Our
            program is not just about academic excellence; it also fosters
            discipline, dedication, and resilience among our students.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>What ‘TRG’ Provide</h2>
          <p>
            The TRG SSC 6-months Program is meticulously crafted to offer
            aspiring candidates a comprehensive and highly effective preparation
            experience for the SSC affiliated examinations, namely the CGL,
            CHSL, CPO, JE, MTS. Herein, we outline the distinguishing features
            that underscore the excellence of our program:
          </p>

          <table className={styles.verticalTable} style={{ width: "100%" }}>
            <tr>
              <th>Duration and Frequency</th>
              <td>
                <p>
                  Each class in our program is scheduled for 2 hours, and
                  classes are conducted every 5 days a week, ensuring consistent
                  and regular learning.
                </p>
              </td>
            </tr>
            <tr>
              <th>Experienced Instructors</th>
              <td>
                <p>
                  Our program features teaching delivered by subject specialists
                  who are well-versed in the SSC CGL exam syllabus, ensuring
                  students receive expert guidance and instruction.
                </p>
              </td>
            </tr>
            <tr>
              <th>Comprehensive Coverage</th>
              <td>
                <p>
                  The program includes 120 Classroom Lectures, ensuring that all
                  relevant topics are thoroughly covered.
                </p>
              </td>
            </tr>
            <tr>
              <th>Study Material</th>
              <td>
                <p>
                  To aid in focused study, we provide a set of Books that
                  comprehensively cover General Studies, Mathematics, English
                  subjects for both Tier I Prelims to the Tier III Descriptive
                  Paper and the subsequent skill tests syllabus.
                </p>
              </td>
            </tr>
            <tr>
              <th>Current Affairs</th>
              <td>
                <p>
                  Students receive monthly Current Affairs Magazines, either in
                  hard copy or PDF format, to stay updated with the latest
                  events and developments.
                </p>
              </td>
            </tr>
            <tr>
              <th>Test Series</th>
              <td>
                <p>
                  We offer Tests Series for both Tier I Prelims to the Tier III
                  Descriptive Paper and current affairs, enabling students to
                  practice and assess their progress.
                </p>
              </td>
            </tr>
            <tr>
              <th>Student Portal Access</th>
              <td>
                <p>
                  Our student portal provides access to classes, objective and
                  descriptive tests, supplemental study materials, daily current
                  affairs, articles, class schedules, and announcements, all in
                  one place.
                </p>
              </td>
            </tr>
            <tr>
              <th>Mentorship</th>
              <td>
                <p>
                  For those who qualify the Tier I Prelims Test, we offer
                  mentorship
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Why Choose the TRG SSC HYBRID 6-months Program?</h2>
          <p>
            Aspirants who aspire to excel in the SSC examinations and similar
            competitive exams should consider our program for the following
            reasons:
          </p>
          <table className={styles.verticalTable} style={{ width: "100%" }}>
            <tr>
              <th>Clear Understanding of Syllabus</th>
              <td>
                <p>
                  Our program ensures aspirants thoroughly understand the SSC
                  syllabus, leaving no room for confusion.
                </p>
              </td>
            </tr>
            <tr>
              <th>Efficient Preparation</th>
              <td>
                <p>
                  With a well-structured curriculum and regular classes,
                  students can efficiently prepare for both Tier I Prelims and
                  Tier III Descriptive Paper.
                </p>
              </td>
            </tr>
            <tr>
              <th>Strong Foundation</th>
              <td>
                <p>
                  We focus on building a solid foundation in essential subjects,
                  making it easier for aspirants to grasp advanced concepts.
                </p>
              </td>
            </tr>
            <tr>
              <th>Discipline and Dedication</th>
              <td>
                <p>
                  Our program instills discipline and dedication through regular
                  assessments and a rigorous schedule.
                </p>
              </td>
            </tr>
            <tr>
              <th>Expert Guidance</th>
              <td>
                <p>
                  Our experienced instructors guide students at every step of
                  their preparation journey, from the basics to advanced topics.
                </p>
              </td>
            </tr>
            <tr>
              <th>Determination</th>
              <td>
                <p>
                  We motivate students to stay determined and committed to their
                  goals, even during challenging times.
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Who Should Join the TRG SSC 6-months Program?</h2>
          <div className={styles.innerSection}>
            <h3>Our program tailored for:</h3>
            <p>
              Graduates and post-graduates planning to take the SSC CGL exam in
              near future.
            </p>
            <p>
              Aspirants aiming to build a strong foundation for Prelims and
              Mains.
            </p>
            <p>
              Those looking to increase their chances of success in the SSC CGL
              exam.
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>How will the TRG SSC CGL 6-months Program Help You?</h3>
            <p>Ensuring timely and comprehensive syllabus coverage.</p>
            <p>Providing high-quality lectures delivered by expert teachers.</p>
            <p>
              Offering focused study material in an easy-to-understand language.
            </p>
            <p>
              Cultivating the 4Ds: Desire, Dedication, Discipline, and
              Determination, essential for success in competitive exams.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h1>SSC CHSL</h1>
          <p>
            Staff Selection Commission (SSC) has unveiled the Tier I Exam
            Schedule for the SSC Combined Higher Secondary Level (CHSL) Exam.
          </p>
          <div className={styles.innerSection}>
            <h3>What is SSC CHSL?</h3>
          </div>
          <div className={styles.innerSection}>
            <h3>Examination Structure:</h3>
            <p>
              The examination will be administered in two tiers: Preliminary and
              Main
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Tier-I Examination Scheme:</h3>
          </div>
          <div className={styles.innerSection}>
            <h3>The Tier-I examination will include the following sections:</h3>
            <li>English Language (Basic Knowledge): 25 questions, 50 marks</li>
            <li>General Intelligence: 25 questions, 50 marks</li>
            <li>
              Quantitative Aptitude (Basic Arithmetic Skills): 25 questions, 50
              marks
            </li>
            <li>General Awareness: 25 questions, 50 marks</li>
            <li>Total: 100 questions, 200 marks</li>
            <li>Time Allotted: 60 minutes</li>
          </div>
          <div className={styles.innerSection}>
            The Tier-I exam will consist of objective-type multiple-choice
            questions. For each incorrect answer, 0.50 marks will be deducted,
            so candidates should exercise caution while responding to questions.
          </div>
          <div className={styles.innerSection}>
            <h3>Tier-II Examination Scheme:</h3>
            <p>Tier-II will be conducted in two sessions on the same day.</p>
          </div>
          <div className={styles.innerSection}>
            <h3>Session I (2 hours 15 minutes):</h3>
            <p>
              Section I: Module-I: Mathematical Abilities: 30 questions, 90
              marks
            </p>
            <p>
              Section I: Module-II: Reasoning and General Intelligence: 30
              questions, 90 marks
            </p>
            <p>
              Section II: Module-I: English Language and Comprehension: 40
              questions, 120 marks
            </p>
            <p>
              Section II: Module-II: General Awareness: 20 questions, 60 marks
            </p>
            <p>
              Section III: Module-I: Computer Knowledge Module: 15 questions, 45
              marks
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Session II (10 minutes):</h3>
            <p>Section-III: Module-II: Skill Test/Typing Test Module</p>
            <p>
              Candidates must pass all sections of Tier-II. Except for Module-II
              of Section-III, Tier-II questions will be available in both
              English and Hindi. Negative marking of 1 mark applies to
              Section-I, Section-II, and Module-I of Section-III.
            </p>
          </div>
          <div className={styles.innerSection}>
            <p>
              Module-I of Section-III (Computer Knowledge Test) is mandatory but
              purely qualifying in nature.
            </p>
          </div>
          <div className={styles.innerSection}>
            <p>Module-II of Section-III: Skill Test/Typing Test:</p>
            <p>
              Module-II will entail Skill Test/Typing Test during Session-II on
              the same day. This aspect is of a qualifying nature.
            </p>
            <p>
              Errors in the Skill Test will be calculated to two decimal places.
              The structure of the Skill Test/Typing Test is as follows:
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Skill Test for Data Entry Operator:</h3>
            <p>
              Data Entry Operators must take the Skill Test, with no exceptions.
              The Skill Test is vital for evaluating candidates' data entry
              abilities.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h1>SSC MTS</h1>
          <div className={styles.innerSection}>
            <h3>What is SSC MTS?</h3>
          </div>
          <div className={styles.innerSection}>
            <h3>Position Details:</h3>
            <p>
              Multi-Tasking (Non-Technical) Staff: A General Central Service
              Group 'C' Non-Gazette, Non-Ministerial post in various
              Ministries/Departments/Offices of the Government of India, and
              various Constitutional Bodies/Statutory Bodies/Tribunals across
              different States/Union Territories.
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Age Limit</h3>
            <p>
              Multi-Tasking Staff in CBN (Department of Revenue): 18-25 years
              (born between January 2, 1998, and January 1, 2005). and in
              certain MTS posts: 18-27 years (born between January 2, 1996, and
              January 1, 2005).
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Educational Qualifications (As of February 17, 2023):</h3>
            <p>
              Candidates must have passed the Matriculation Examination or its
              equivalent from a recognized Board on or before February 17, 2023.
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>Examination Scheme:</h3>
          </div>
          <div className={styles.innerSection}>
            <h3>Computer Based Examination:</h3>
            <p>Conducted in Hindi, English, and 13 regional languages.</p>
            <p>Two sessions: Session-I and Session-II (both mandatory).</p>
            <p>Failure to attempt any session results in disqualification.</p>
          </div>
          <div className={styles.innerSection}>
            <h3>
              Session-I (Duration: 45/60 minutes for scribe-eligible
              candidates):
            </h3>
            <p>Numerical and Mathematical Ability: 20 questions, 60 marks.</p>
            <p>
              Reasoning Ability and Problem Solving: 20 questions, 60 marks.
            </p>
          </div>
          <div className={styles.innerSection}>
            <h3>
              Session-II (Duration: 45/60 minutes for scribe-eligible
              candidates):
            </h3>
            <p>General Awareness: 25 questions, 75 marks.</p>
            <p>English Language and Comprehension: 25 questions, 75 marks.</p>
          </div>
          <div className={styles.innerSection}>
            <h3>Important Notes:</h3>
            <p>The examination schedule is tentative and subject to change.</p>
            <p>No re-evaluation/re-checking of scores is allowed.</p>
            <p>
              Further updates and changes will be communicated only through the
              Commission's official website.
            </p>
          </div>
          <div className={styles.innerSection}>
            <p>
              This SSC MTS a promising opportunity for 10th pass candidates
              seeking stable job prospects within various governmental bodies.
              The new selection process and exam pattern aim to identify capable
              individuals to serve in these positions effectively. If you meet
              the eligibility criteria, consider applying and preparing for the
              examination following the outlined syllabus and exam pattern.
            </p>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>Role of TRG in Achieving SSC Goals</h2>
          <div className={styles.innerSection}>
            <p style={{ marginBottom: 0 }}>
              TRG Hybrid Tuition Centre offers a comprehensive preparation
              program for SSC exams, combining expert faculty, a robust
              curriculum, and innovative learning tools to ensure success.
            </p>
            <p style={{ marginBottom: 0 }}>
              TRG have Expert Faculty, specific study material, Completion of
              syllabus on time, Blended Hybrid Learning, Regular Assessment and
              Mock Test of all subjects, Personalized attention.
            </p>
            <p>
              Join us on a journey of growth, learning, and achievement. Whether
              you're aiming for top grades, entrance exams, or a bright future,
              we are here to provide the guidance and support you need to
              succeed.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>SSC Syllabus</h2>
          <a href=" https://bit.ly/3U8vxlp" target="_blank">
            <p>Download Syllabus</p>
          </a>
        </div>

        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Ssc;
