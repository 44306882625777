import React, { useLayoutEffect, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import CustomNav from "../../../components/header/Navbar";
import Background from "./bgcanvas.png";
import Footer from "../../../components/Footer";
import pic_5 from "../../student/dashboard/img/pic_5.png";
import Popup2 from "../../non_auth/new_popup/popup2";
import styles from "./common_css.module.css";
Modal.setAppElement("#root");

function Cuet() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <h1>COMMON UNIVERSTY ENTRANCE TEST (UG & PG)</h1>
        <div className={styles.sections}>
          <p>
            The Common University Entrance Test (CUET) is a national-level exam
            in India for admission to undergraduate, postgraduate, and research
            programs at various central, state universities, and other
            participating institutions. It assesses students' knowledge and
            aptitude in subjects relevant to their chosen course of study.
          </p>
        </div>
        <div className={styles.sections}>
          <h3 style={{ textAlign: "center", fontWeight: "600" }}>
            Enroll in CUET Coaching Classes for Comprehensive Preparation!
          </h3>
        </div>
        <div className={styles.sections}>
          <div className={styles.infoBox}>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Class Timing
                </h2>
              </div>
              <p style={{ fontWeight: "600", textAlign: "center" }}>
                8:00 AM to 8:00 PM
              </p>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Hybrid Coaching Classes
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  margin: "auto",
                  alignItems: "center",
                }}
              >
                <p style={{ fontWeight: "600" }}>
                  12th Pass or along with 11th & 12th
                </p>
                <p style={{ fontWeight: "600" }}>(Home Tuition)</p>
                <p style={{ fontWeight: "600" }}>Any time</p>
              </div>
            </div>
            <div
              style={{
                border: "3px solid #000",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                padding: "12px 24px",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  border: "3px solid #000",
                  marginTop: "-32px",
                  backgroundColor: "#1d74ca",
                }}
              >
                <h2 style={{ color: "white", textAlign: "center" }}>
                  Contact Us
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  margin: "auto",
                }}
              >
                <p style={{ fontWeight: "600" }}>9570-424242</p>
                <p style={{ fontWeight: "600" }}>74-1919-7878</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sections} style={{ alignItems: "center" }}>
          <div className={styles.btnDiv}>
            <Link to="/trial-student-form">
              <h2 style={{ color: "white" }} className={styles.book_btn}>
                Book Free Trial
              </h2>
            </Link>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>TRG CUET Program Main Highlights</h2>
          <div className={styles.innerSection}>
            <p>1. Hybrid Learning Model: </p>
            <li>
              Guidance from experienced educators specializing in CUET
              preparation.
            </li>
            <li>
              Real-time doubt-clearing sessions to ensure thorough
              understanding.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>2. Expert Faculty:</p>
            <li>
              Guidance from experienced educators specializing in CUET
              preparation.
            </li>
            <li>
              Real-time doubt-clearing sessions to ensure thorough
              understanding.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>3. Comprehensive Study Materials:</p>
            <li>
              Detailed, subject-specific materials tailored to the CUET
              syllabus.
            </li>
            <li>Includes e-books, PDFs, and practice papers.</li>
          </div>
          <div className={styles.innerSection}>
            <p>4. Practice and Mock Tests:</p>
            <li>
              Regular unit-wise and full-length mock tests to simulate the exam
              environment.
            </li>
            <li>Practice papers aligned with CBSE/DBSE patterns.</li>
          </div>
          <div className={styles.innerSection}>
            <p>5. Personalized Learning Plans:</p>
            <li>Custom study plans based on performance analytics.</li>
            <li>
              Focus on individual strengths and areas needing improvement.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>6. Interactive Learning Environment:</p>
            <li>Engaging video lessons and interactive quizzes.</li>
            <li>
              Discussion forums for peer interaction and collaborative learning.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>7. Regular Updates and Notifications:</p>
            <li>
              Timely alerts about exam schedules, application deadlines, and new
              course offerings.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p>
              These features ensure a comprehensive and effective preparation
              strategy for the CUET, catering to diverse learning needs and
              helping students achieve their academic goals.
            </p>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>Why Choose TRG?</h2>
          <p>
            At TRG, we are committed to nurturing academic growth, fostering a
            deep understanding of subjects, and providing personalized attention
            to every student. Our diverse teaching methods and experienced
            educators create an enriching learning environment.
          </p>
        </div>

        <div className={styles.sections}>
          <h2>Overview of CUET Exam</h2>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Purpose</p>
            <p>
              The Common University Entrance Test (CUET) is an entrance exam
              conducted for admission to various undergraduate programs in
              central universities and other participating institutions in
              India.
            </p>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Structure</p>
            <div className={styles.innerSection}>
              <p style={{ fontWeight: "600" }}> 1. Sections:</p>
              <li>
                Section I (Languages): Divided into Section IA (13 languages)
                and Section IB (20 additional languages). Tests reading
                comprehension, vocabulary, and grammar.
              </li>
              <li>
                Section II (Domain-Specific Subjects): Covers subjects like
                Physics, Chemistry, Mathematics, Biology, Economics, and others
                based on the chosen program.
              </li>
              <li>
                Section III (General Test): Assesses general knowledge, current
                affairs, quantitative reasoning, logical and analytical
                reasoning, and numerical ability.
              </li>
            </div>

            <div className={styles.innerSection}>
              <p style={{ fontWeight: "600" }}> 2. Format:</p>
              <li> The exam is conducted in a computer-based mode (CBT).</li>
              <li>Objective type questions (Multiple Choice Questions).</li>
            </div>
            <div className={styles.innerSection}>
              <p style={{ fontWeight: "600" }}>3. Scoring and Duration:</p>
              <li>
                Each section has a specific number of questions and allotted
                time.
              </li>
              <li>
                Scores are calculated based on correct responses, with potential
                negative marking for incorrect answers.
              </li>
            </div>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Eligibility</p>
            <p>
              Candidates who have completed or are appearing for their 12th
              standard or equivalent examination are eligible to apply for CUET.
            </p>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Participating Universities</p>
            <p>
              CUET is used for admissions to several central universities,
              including but not limited to:
            </p>
            <li>University of Delhi (DU)</li>
            <li>Jawaharlal Nehru University (JNU)</li>
            <li>Banaras Hindu University (BHU)</li>
            <li>Central University of Punjab (CUP)</li>
            <li>Aligarh Muslim University (AMU)</li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Application Process</p>
            <li>The application process is conducted online.</li>
            <li>
              Candidates must fill out the application form, upload required
              documents, and pay the application fee.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Preparation Tips</p>
            <p>
              1. Understand the Syllabus: Thoroughly review the syllabus for
              each section.
            </p>
            <p>
              2. Practice Regularly: Use practice papers and mock tests to build
              familiarity with the exam format.
            </p>
            <p>
              3. Focus on Weak Areas: Identify and work on areas where
              improvement is needed.
            </p>
            <p>
              4. Time Management: Practice managing time effectively to complete
              each section within the allotted time.
            </p>
          </div>
          <p>
            For more detailed information, candidates can visit the official
            CUET website or educational portals that provide resources and
            updates on the exam.
          </p>
        </div>

        <div className={styles.sections}>
          <h2>CUET (UG) Highlights</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td>Full Exam Name</td>
              <td>Common University Entrance Test (UG)</td>
            </tr>
            <tr>
              <td>Short Exam Name</td>
              <td>CUET (UG)</td>
            </tr>
            <tr>
              <td>Conducting Body</td>
              <td>National Testing Agency (NTA)</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>National Level Exam</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Application Fee (General)</td>
              <td>750 Rs [Offline]</td>
            </tr>
            <tr>
              <td>CUET Syllabus</td>
              <td>
                <a href="https://bit.ly/trg-cuet-syllabus" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>CUET (UG) Eligibility Criteria</h2>
          <p>
            Prospective candidates aiming to appear for the CUET UG exam must
            have passed the Class 12 or equivalent. Familiarity with CUET UG
            eligibility criteria is vital. No age bar exists; individuals of all
            ages can partake. Keep in mind that eligibility terms may differ by
            course. Noteworthy institutions like Delhi University and Jamia
            Millia exhibit specific criteria, as outlined in the table. It's
            advisable to review prerequisites for desired courses, ensuring
            alignment before applying for CUET UG. This approach aids in
            pursuing educational aspirations effectively.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>DU Admissions based on CUET exam</h2>
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%" }}>
              <tr style={{ width: "100%" }}>
                <td style={{ width: "20%", fontWeight: "600" }}>Program</td>
                <td style={{ width: "40%", fontWeight: "600" }}>Eligibility</td>
                <td style={{ width: "40%", fontWeight: "600" }}>CUET Papers</td>
              </tr>
              <tr>
                <td>B.SC</td>
                <td>
                  Must have passed 10+ 2 or appear in previous years, Physics,
                  Chemistry, Mathematics or Biology.
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <li>Physics, Chemistry, Mathematics or Biology.</li>
                    <li>One language</li>
                    <p>
                      Candidates must have obtained at least 30% marks in any
                      one language in CUET for admissions
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>B.A</td>
                <td>Must have passed 10+ 2 or appearing</td>
                <td>
                  Any one language from section one and in any three subjects
                  from the other sections.
                </td>
              </tr>
              <tr>
                <td>BA (Hons) Economics</td>
                <td>Must have passed 10+ 2 or appearing</td>
                <td>Any one language, Mathematics and any two subjects</td>
              </tr>
              <tr>
                <td>BBA, BMS, BBE</td>
                <td>
                  Must be appearing for 10+2 or has already passed the same.
                </td>
                <td>
                  Any one language, Mathematics and as per section 3 of CUET
                  exam.
                </td>
              </tr>
              <tr>
                <td>BA, B. Voc., B.Com.</td>
                <td>Must have passed 10+ 2 or appearing.</td>
                <td>
                  Choice of a language plus one subject and a general
                  examination or a three-subject test.
                </td>
              </tr>
              <tr>
                <td>Linguistic program</td>
                <td>
                  Must be appearing for 10+2 or has already passed the same.
                </td>
                <td>
                  A particular language at CUET or any other language.
                  Preference will be given to applicants appearing in the
                  particular language
                </td>
              </tr>
            </table>
          </div>
          <p>
            Applicants opting for Sports and Extra-Curricular Activities (ECA)
            must undertake a performance test alongside CUET. Selection is
            reliant on CUET merit and performance test scores combined.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>
            Jamia Milia Islamia - Program wise Eligibility Criteria + CUET Paper
          </h2>
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ fontWeight: "600" }}>Program</td>
                <td style={{ fontWeight: "600" }}>Eligibility</td>
                <td style={{ fontWeight: "600" }}>CUET Papers</td>
              </tr>
              <tr>
                <td>B.A. (Hons) (History)</td>
                <td>
                  Senior Sec. School Certificate or an equivalent examination
                  with not less than 50% marks in aggregate or in the subject
                  concerned.
                </td>
                <td>History in CUET exam</td>
              </tr>
              <tr>
                <td>B.A. (Hons.) French & Francophone Studies*</td>
                <td>
                  Senior Sec. School Certificate or an equivalent examination
                  with not less than 50% marks in aggregate or in the subject
                  concerned.
                </td>
                <td>General Test</td>
              </tr>
              <tr>
                <td>B.A. (Hons.) Spanish & Latin American Studies*</td>
                <td>
                  Senior Sec. School Certificate or an equivalent examination
                  with not less than 50% marks in aggregate or in the subject
                  concerned.
                </td>
                <td>General Test</td>
              </tr>
              <tr>
                <td>B.A.(Hons) (Economics)</td>
                <td>
                  Senior Secondary School Certificate or an equivalent
                  examination with not less than 50% marks in aggregate of best
                  5-papers
                </td>
                <td>CUET - Economics</td>
              </tr>
              <tr>
                <td>B.B.A.</td>
                <td>
                  Senior Secondary School Certificate or an equivalent
                  examination with not less than 50% marks in aggregate of best
                  5-papers
                </td>
                <td>To be announced</td>
              </tr>
              <tr>
                <td>B.Com.(Hons)</td>
                <td>
                  Senior Sec. School Certificate or an equivalent examination
                  with not less than 50% marks in aggregate of best 5-papers or
                  in the subject concerned
                </td>
                <td>Commerce</td>
              </tr>
              <tr>
                <td>B.A.(Hons) (Sociology)</td>
                <td>
                  Senior Secondary School Certificate or an equivalent
                  examination with not less than 50% marks in aggregate of best
                  5-papers.
                </td>
                <td>To be announced</td>
              </tr>
              <tr>
                <td>B.A.(Hons) (Psychology)</td>
                <td>
                  Senior Secondary School Certificate or an equivalent
                  examination with not less than 50% marks in aggregate of best
                  5-papers.
                </td>
                <td>To be announced</td>
              </tr>
              <tr>
                <td>B.A./B.Sc.*(Hons) (Geography)</td>
                <td>
                  Senior Secondary School Certificate or an equivalent
                  examination with not less than 50% marks in aggregate of best
                  5-papers or in the subject concerned
                </td>
                <td>To be announced</td>
              </tr>
              <tr>
                <td>B.VOC (Solar Energy) (Self- Financed)</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>
                      Category 1: Students who have already acquired NSQF
                      certification level 4 in the in the industry sector
                      belonging to Solar Energy
                    </p>
                    <p>
                      Category 2: Students who have acquired NSQF certification
                      level 4 but may like to change their sector and pursue
                      Vocational course in Solar Energy. Category 3: Students
                      who have passed 10+2 level examination (or equivalent)
                      without any background of vocational training. Such
                      students must have secured at least 50% marks at the 10+2
                      level. Besides, they must have also studied Physics and
                      Mathematics at the 10+2 (or Equivalent) level.
                    </p>
                  </div>
                </td>
                <td>Physics in CUET exam</td>
              </tr>
              <tr>
                <td>B.Sc. (Hons) (Physics)</td>
                <td>
                  Senior Secondary School Certificate or an equivalent
                  examination with not less than 50% marks in aggregate of
                  Physics, Chemistry and Mathematics and in the subject
                  concerned will be
                </td>
                <td>Physics</td>
              </tr>
              <tr>
                <td>B.Sc. (Biotechnology)</td>
                <td>
                  Senior Secondary School Certificate or an equivalent
                  examination with not less than 50% marks in each science
                  subjects i.e. Physics, Chemistry and Biology/ Mathematics.
                </td>
                <td>Biology / Biochemistry / Biotechnology in CUET</td>
              </tr>
              <tr>
                <td>B.A.(Hons.) (Turkish Language & Literature)</td>
                <td>
                  Senior Sec. School Certificate or an equivalent examination
                  with not less than 50% marks in aggregate of best 5-papers or
                  in the subject concerned
                </td>
                <td>CUET General Test</td>
              </tr>
              <tr>
                <td>B.A.(Hons) Hindi</td>
                <td>
                  Senior Sec. School Certificate or an equivalent examination
                  with not less than 50% marks in aggregate of best 5-papers or
                  in the subject concerned
                </td>
                <td>Hindi in CUET</td>
              </tr>
              <tr>
                <td>B.A.(Hons) Sanskrit</td>
                <td>
                  Passed 10+2 with 45% marks from a recognized Board/University.
                </td>
                <td>General Test in CUET exam</td>
              </tr>
            </table>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>
            Pondicherry University - Program Wise Eligibility Criteria + CUET
            Paper
          </h2>
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ fontWeight: "600" }}>Program name</td>
                <td style={{ fontWeight: "600" }}>Eligibility</td>
                <td style={{ fontWeight: "600" }}>CUET Domain Papers</td>
              </tr>
              <tr>
                <td>Integrated M.Sc. Applied Geology</td>
                <td>
                  Pass in +2 with 50% of marks and should have studied Physics,
                  Chemistry and Mathematics
                </td>
                <td>Physics, Chemistry & Mathematics</td>
              </tr>
              <tr>
                <td>Integrated M.Sc. Chemistry</td>
                <td>
                  Pass in +2 with 50% of marks and should have studied Physics,
                  Chemistry and Mathematics
                </td>
                <td>Physics, Chemistry & Mathematics</td>
              </tr>
              <tr>
                <td>Integrated M.Sc. Physics</td>
                <td>
                  Pass in +2 with 50% of marks and should have studied Physics,
                  Chemistry and Mathematics
                </td>
                <td>Physics, Chemistry & Mathematics</td>
              </tr>
              <tr>
                <td>Integrated M.Sc. Mathematics</td>
                <td>
                  Pass in +2 with a minimum of 50% of marks with Mathematics,
                  Physics and Chemistry as subjects of study
                </td>
                <td>Physics, Chemistry & Mathematics</td>
              </tr>
              <tr>
                <td>Integrated M.Sc. Computer Science</td>
                <td>
                  Pass in +2 with a minimum of 50% of marks with Mathematics,
                  Physics and Chemistry as subjects of study
                </td>
                <td>Physics, Chemistry & Mathematics</td>
              </tr>
              <tr>
                <td>Integrated M.Sc. Statistics</td>
                <td>
                  Pass in +2 with a minimum of 50% of marks with Mathematics,
                  Physics and Chemistry as subjects of study
                </td>
                <td>Physics, Chemistry & Mathematics</td>
              </tr>

              <tr>
                <td>Integrated M.A. History</td>
                <td>Pass in +2 with a minimum of 50% of marks</td>
                <td>General Test in CUET exam</td>
              </tr>
              <tr>
                <td>Integrated M.A. Political Science</td>
                <td>Pass in +2 with a minimum of 50% of marks</td>
                <td>General Test in CUET exam</td>
              </tr>
              <tr>
                <td>Integrated M.A. Sociology</td>
                <td>Pass in +2 with a minimum of 50% of marks</td>
                <td>General Test in CUET exam</td>
              </tr>
              <tr>
                <td>
                  Integrated M.A. Social & Economic Administration and Law
                  (SEAL)
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>Candidates must have passed</p>
                    <p>
                      i) The Higher Secondary, Intermediate or Senior Secondary
                      School Certificate Exam, conducted by the Centre or State
                      Government Board
                    </p>
                    <p>OR</p>
                    <p>
                      ii) The Pre- University or Pre-Degree Examination of a
                      recognized university
                    </p>
                    <p>OR</p>
                    <p>
                      iii) The French Baccalaureate or any other qualification
                      equivalent to the above-mentioned qualifications approved
                      by the Academic Council of the University. And
                    </p>
                    <p>
                      They should have a minimum score of 50% in the qualifying
                      examination.
                    </p>
                  </div>
                </td>
                <td>CUET 2024 General Test</td>
              </tr>
            </table>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CUET (UG) Application Procedure</h2>
          <div className={styles.innerSection}>
            <p>Mode of Application: Online</p>
            <p>Mode of Payment: Net Banking, Credit/Debit Card, Paytm, BHIM</p>
            <p>Application form release: Official website cuet.samarth.ac.in</p>
            <p>Submission: Online before deadline</p>
            <p>Corrections: Possible during specified window</p>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Application Steps:</p>
            <p>1. Visit cuet.samarth.ac.in</p>
            <p>2. Click "Apply Online"</p>
            <p>
              3. Input program, personal info (name, email, mobile, DOB), and
              verification code
            </p>
            <p>4. Post registration, receive Application ID and password</p>
            <p>5. Log in using these credentials and complete form</p>
            <p>6. Upload required scanned documents</p>
            <p>7. Choose exam centers in order of preference</p>
            <p>8. Enter university and program specifics</p>
            <p>9. Pay fees online and print application form.</p>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Application Fees</p>
            <div style={{ overflowX: "auto", width: "100%" }}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ fontWeight: "600" }}>Category</td>
                  <td style={{ fontWeight: "600" }}>Quota</td>
                  <td style={{ fontWeight: "600" }}>Mode</td>
                  <td style={{ fontWeight: "600" }}>Gender</td>
                  <td style={{ fontWeight: "600" }}>Amount</td>
                </tr>
                <tr>
                  <td>EWS, OBC</td>
                  <td></td>
                  <td>Online</td>
                  <td>Male, Transgender, Female</td>
                  <td>₹ 700</td>
                </tr>
                <tr>
                  <td>SC, ST</td>
                  <td>PWD</td>
                  <td>Online</td>
                  <td>Male, Transgender, Female</td>
                  <td>₹ 650</td>
                </tr>
                <tr>
                  <td>General</td>
                  <td></td>
                  <td>Offline</td>
                  <td>Male, Transgender, Female</td>
                  <td>₹ 700</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CUET Helpline Number 2024</h2>
          <div>
            <li>CUET National Helpline Number - 1800 572 7348</li>
            <li>
              Official website -{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://cuet.samarth.ac.in"
              >
                https://cuet.samarth.ac.in
              </a>
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>CUET (UG) Exam Detailed Pattern</h2>
          <p>
            Prospective test-takers preparing for the CUET UG exam are advised
            to familiarize themselves with the examination pattern, as this
            knowledge can significantly enhance their performance. To gain
            insights into the CUET exam pattern for the year, refer to the
            tabulated information provided below. This understanding will prove
            invaluable in achieving a higher score during the examination.
          </p>
          <div style={{ overflowX: "auto" }}>
            <table>
              <tr>
                <td style={{ fontWeight: "600" }}>Section</td>
                <td style={{ fontWeight: "600" }}>Subjects/ Tests</td>
                <td style={{ fontWeight: "600" }}>Questions to attempt</td>
                <td style={{ fontWeight: "600" }}>Question Type</td>
                <td style={{ fontWeight: "600" }}>Duration</td>
              </tr>
              <tr>
                <td>IA - Languages</td>
                <td>13 Languages</td>
                <td>40 questions to be attempted out of 50 in each language</td>
                <td>
                  Reading Comprehension (based on different types of
                  passages-Factual, Literary and Narrative), (Literary Aptitude
                  and Vocabulary)
                </td>
                <td>45 Minutes for each language</td>
              </tr>
              <tr>
                <td>IB - Languages</td>
                <td>
                  20 languages (languages to be attempted are other than the
                  ones in IA)
                </td>
                <td>40 questions to be attempted out of 50 in each language</td>
                <td>
                  Reading Comprehension (based on different types of
                  passages-Factual, Literary and Narrative), (Literary Aptitude
                  and Vocabulary)
                </td>
                <td>45 Minutes for each language</td>
              </tr>
              <tr>
                <td>Section II - Domain</td>
                <td>
                  27 Domain specific subjects out of which a maximum of Six (06)
                  Domains are to be chosen
                </td>
                <td>40 Questions to be attempted out of 50</td>
                <td>MCQs. Based on NCERT syllabus</td>
                <td>45 minutes for each subject</td>
              </tr>
              <tr>
                <td>Section III - General Test</td>
                <td>
                  As per respective University norms where a General Test is
                  being used for admission.
                </td>
                <td>60 Questions to be attempted out of 75</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <p>MCQ Based Questions</p>
                    <li>
                      General Knowledge, Current Affairs, General Mental
                      Ability, Numerical Ability, Quantitative Reasoning (Simple
                      application of basic mathematical concepts
                      arithmetic/algebra geometry/mensuration/s tat taught till
                      Grade 8), Logical and Analytical Reasoning
                    </li>
                  </div>
                </td>
                <td>45 minutes for each subject</td>
              </tr>
            </table>
          </div>
        </div>

        <div className={styles.sections}>
          <h2>In the CUET exam</h2>
          <div className={styles.innerSection}>
            <p style={{ marginBottom: 0 }}>
              Candidates are allowed to select a total of 3 languages from
              Section IA and Section 1B combined. One of these languages should
              substitute the 6th Domain-specific subject.
            </p>
            <p style={{ marginBottom: 0 }}>
              Among the 27 subjects in Section II, candidates can choose up to 6
              subjects.
            </p>
            <p>
              Section III of the CUET involves the General Test, assessing
              candidates' overall aptitude and knowledge across different areas.
            </p>
            <p>
              Familiarity with these exam sections and choices can greatly
              assist test-takers in optimizing their performance.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default Cuet;
