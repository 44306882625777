import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import styles from "./common_css.module.css";

Modal.setAppElement("#root");

const acturialScienceData = [
  {
    id: 1,
    data1: "Branch Name 	",
    data2: "Actuarial Science",
  },
  {
    id: 2,
    data1: "Degree 	",
    data2: "BA, BBA, B.Sc, MA, M.Com",
  },
  {
    id: 3,
    data1: "Average Salary 	",
    data2: "Rs. 14 LPA ( for Actuary)",
  },
  {
    id: 4,
    data1: "Duration	",
    data2: "UG: 3 years, PG: 2 years",
  },
  {
    id: 5,
    data1: "Eligibility Criteria 	",
    data2: "UG: 10+2, PG:  Bachelor’s Degree",
  },
  {
    id: 6,
    data1: "Top Entrance Exams ",
    data2: "CUET UG, ACET, and CUET PG",
  },
  {
    id: 7,
    data1: "Career Options	",
    data2:
      "Actuary, Accountants and Auditors, Budget Analysts, Financial Analyst, and Cost Estimates, and Underwriter.",
  },
  {
    id: 8,
    data1: "Recruiting Companies 	",
    data2:
      "ICICI Prudential, ING, E&Y, PwC, Allstate, Wills Towers Watson, The Hartford	",
  },
  {
    id: 9,
    data1: "Admission Process	",
    data2: "Entrance Examination/ Direct Admission",
  },
];

function ActurialScience() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>About Actuarial Science</h1>
        </div>
        <div className={styles.sections}>
          <p>
            Actuarial Science is a specialized field within statistics and
            mathematics focused on the evaluation of financial risks prevalent
            in the insurance and finance sectors. This discipline employs
            diverse statistical and mathematical methods to quantify and analyze
            financial risks effectively. By utilizing probability analysis and
            statistics, actuarial science aims to address and solve
            uncertainties related to future financial events.
          </p>
          <p>
            Professionals specializing in actuarial science play a pivotal role
            in advising both companies and individuals on potential investment
            risks that may arise in the future, utilizing a comprehensive
            understanding of statistics and probability. The field encompasses a
            multidisciplinary approach, integrating subjects such as
            Mathematics, Probability, Statistics, Finance, Economics, and
            Computer Science.
          </p>
          <p>
            If you possess a solid foundation in statistics and mathematics, a
            career in actuarial science can be a promising and rewarding choice,
            allowing you to contribute to informed decision-making in the face
            of financial uncertainties.
          </p>
        </div>
        <div className={styles.sections}>
          <h2>Highlights – Actuarial Science</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ fontWeight: "600", width: "50%" }}>Particulars</td>
              <td style={{ fontWeight: "600", width: "50%" }}>Details</td>
            </tr>
            {acturialScienceData.map((data) => {
              return (
                <tr>
                  <td>{data.data1}</td>
                  <td>{data.data2}</td>
                </tr>
              );
            })}
            <tr>
              <td>Actuarial Syllabus</td>
              <td>
                <a href="https://bit.ly/trg-actuarial-syllabus" target="_blank">
                  <p>Download Syllabus</p>
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Eligibility Criteria for Actuarial Science Programs (UG & PG)</h2>
          <p>
            Actuarial Science programs are available at both the undergraduate
            and postgraduate levels, catering to candidates with a strong
            interest in finance and mathematics. The eligibility criteria vary
            based on the educational level and the specific requirements of the
            institution. Below outline the eligibility criteria for Actuarial
            Science courses.
          </p>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <div>
              <h3>Eligibility Criteria for UG Courses</h3>
              <p>
                For undergraduate programs, candidates are generally required to
                successfully complete their 10+2 education with a specified
                valid score as mandated by the desired institute. It is
                recommended that candidates thoroughly review the eligibility
                criteria before applying for admission. The eligibility criteria
                for undergraduate Actuarial Science courses include:
              </p>
            </div>
            <div className={styles.innerSection}>
              <p>1. Educational Qualifications:</p>
              <li>
                Candidates must have completed their 10+2 examinations in either
                the Commerce or Mathematics stream.
              </li>
              <li>
                Proficiency in English, with it being one of the subjects
                studied as a first language.
              </li>
            </div>
            <div className={styles.innerSection}>
              <p>2. Age Restrictions:</p>
              <li>
                There are typically no age restrictions for candidates appearing
                for the Actuarial Science exam.
              </li>
            </div>
            <div className={styles.innerSection}>
              <p>3. Aggregate Percentage:</p>
              <li>
                Candidates are required to have a minimum aggregate score of 60%
                in their 10+2 examinations.
              </li>
            </div>
            <div className={styles.innerSection}>
              <p>4. Mathematics Score:</p>
              <li>
                Specifically, candidates should have obtained a minimum of 70%
                in Mathematics.
              </li>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <div>
              <h3>Eligibility Criteria for PG Courses</h3>
              <p>
                The eligibility criteria for postgraduate Actuarial Science
                programs may vary among institutions. However, common
                requirements include:
              </p>
            </div>
            <div className={styles.innerSection}>
              <p>1. Educational Background:</p>
              <li>
                Candidates should hold a relevant undergraduate degree, usually
                in Actuarial Science, Mathematics, Statistics, Finance, or a
                related field.
              </li>
            </div>
            <div className={styles.innerSection}>
              <p>2. Minimum Academic Standards:</p>
              <li>
                Candidates are generally expected to demonstrate a satisfactory
                academic record with a minimum percentage as determined by the
                institution.
              </li>
            </div>
            <div className={styles.innerSection}>
              <p>3. Entrance Exams:</p>
              <li>
                Some institutions may necessitate candidates to clear specific
                entrance exams for admission to postgraduate Actuarial Science
                programs.
              </li>
            </div>
          </div>
          <div>
            <h3>Eligibility Criteria for PG Courses</h3>
            <p>
              The eligibility criteria for postgraduate courses in Actuarial
              Science may vary depending on the institute, although there is a
              general consistency across institutions. Candidates are typically
              required to have successfully completed their bachelor's degree
              with a minimum aggregate score, as specified by the respective
              institute.
            </p>
            <div className={styles.innerSection}>
              <li>
                Candidates must hold a Bachelor’s degree in a related discipline
                to qualify for admission.
              </li>
              <li>
                A minimum aggregate score of 50 percent or the percentage
                specified by the institute is usually required for eligibility.
              </li>
              <li>
                Additionally, candidates are often mandated to appear for
                relevant entrance examinations.
              </li>
            </div>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Actuarial Science Syllabus for UG Courses</h2>
          <p>
            Actuarial Science programs are available at the undergraduate level,
            encompassing disciplines such as BA, BBA, and B.Sc., with a duration
            typically spanning 3-4 years depending on the specific degree. The
            curriculum outlined below pertains to Hindustan University.
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - I
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Regional Language- I</td>
              <td style={{ width: "50%" }}>Foreign Language- I</td>
            </tr>
            <tr>
              <td>English-I</td>
              <td>Introduction to Actuarial Mathematics</td>
            </tr>
            <tr>
              <td>Differential and Integral Calculus</td>
              <td>Descriptive Statistics</td>
            </tr>
            <tr>
              <td>Environmental Studies</td>
              <td>Value Education</td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - II
              </td>
            </tr>
            <tr>
              <td>English- II</td>
              <td>Introduction to Insurance</td>
            </tr>
            <tr>
              <td>Optimisation Techniques</td>
              <td>Probability Theory and Discrete Distribution</td>
            </tr>
            <tr>
              <td>Start Lab- Statistical Software</td>
              <td>-</td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - III
              </td>
            </tr>
            <tr>
              <td>Mathematics of Finance- I</td>
              <td>Business Economics</td>
            </tr>
            <tr>
              <td>Principles of Insurance</td>
              <td>Sampling Theory and Its Applications</td>
            </tr>
            <tr>
              <td>Estimation Theory</td>
              <td>-</td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - IV
              </td>
            </tr>
            <tr>
              <td>Financial Markets in India</td>
              <td>Mathematics of Finance</td>
            </tr>
            <tr>
              <td>Insurance Underwriting</td>
              <td>Introduction to Stochastic Process and Markov Model</td>
            </tr>
            <tr>
              <td>Programming Using R</td>
              <td>-</td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - V
              </td>
            </tr>
            <tr>
              <td>Numerical Analysis</td>
              <td>Basic Accounting Concepts</td>
            </tr>
            <tr>
              <td>Life and Health Contingencies</td>
              <td>Actuarial Profession</td>
            </tr>
            <tr>
              <td>Time Series Analysis</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Group Insurance Retirement Benefit</td>
              <td>Reinsurance Management</td>
            </tr>
            <tr>
              <td>Project</td>
              <td>-</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Actuarial Science Syllabus for PG Courses</h2>
          <p>
            The syllabus for Actuarial Science at the postgraduate level may
            exhibit slight variations based on the institute, yet it generally
            maintains consistency across institutions. Candidates are encouraged
            to explore the specific syllabus for the course by referring to the
            official website of their chosen institute. Below is an illustrative
            representation of the M.Sc. Actuarial Science syllabus at Mahatma
            Gandhi University:
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - I
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Regional Language- I</td>
              <td style={{ width: "50%" }}>Foreign Language- I</td>
            </tr>
            <tr>
              <td>Actuarial Statistics- I</td>
              <td>Financial Mathematics- I</td>
            </tr>
            <tr>
              <td>Business Economics- I</td>
              <td>Survival Models</td>
            </tr>
            <tr>
              <td>Actuarial Computing- I</td>
              <td>-</td>
            </tr>

            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - II
              </td>
            </tr>

            <tr>
              <td>Actuarial Statistics- I</td>
              <td>Financial Mathematics- II</td>
            </tr>
            <tr>
              <td>Business Economics- II</td>
              <td>Contingencies- I</td>
            </tr>
            <tr>
              <td>Actuarial Computing</td>
              <td>-</td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - III
              </td>
            </tr>
            <tr>
              <td>Contingencies- II</td>
              <td>Risk Modelling- I</td>
            </tr>
            <tr>
              <td>Risk Modelling- I</td>
              <td>Actuarial Computing-III</td>
            </tr>

            <tr>
              <td
                colSpan={2}
                style={{ fontWeight: "600", textAlign: "center" }}
              >
                Semester - IV
              </td>
            </tr>
            <tr>
              <td>Pricing and Reserving of Life</td>
              <td>Risk Modelling- II</td>
            </tr>
            <tr>
              <td>Assurances and Annuities</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Business Management</td>
              <td>Actuarial Computing- IV</td>
            </tr>

            <tr>
              <td colSpan={2} style={{ fontWeight: "600" }}>
                Group A- Electives
              </td>
            </tr>
            <tr>
              <td>Risk Management</td>
              <td>Insurance and Retirement Benefits</td>
            </tr>
            <tr>
              <td>Financial Engineering</td>
              <td>-</td>
            </tr>
            <tr>
              <td colSpan={2} style={{ fontWeight: "600" }}>
                Group B - Electives
              </td>
            </tr>
            <tr>
              <td>Actuarial Risk Management</td>
              <td>Research Methodology</td>
            </tr>
            <tr>
              <td>Marketing of Services</td>
              <td>-</td>
            </tr>
          </table>
        </div>

        <div className={styles.sections}>
          <h2>Career Opportunities in Actuarial Science</h2>
          <p>
            As the corporate landscape continues to evolve and with a rise in
            commerce-based enterprises, the actuarial science sector presents
            abundant job opportunities. Graduates in this field can explore
            diverse career paths across various sectors:
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ fontWeight: "600", width: "30%" }}>Job Position</td>
              <td style={{ fontWeight: "600", width: "70%" }}>
                Job Description
              </td>
            </tr>
            <tr>
              <td>Actuary</td>
              <td>
                Actuaries are professionals who analyze risk possibilities of
                financial activity and solve the involved risks. They are mostly
                employed in Insurance sectors and pension programmed to foresee
                future risks.
              </td>
            </tr>
            <tr>
              <td>Accountants</td>
              <td>
                Accountants are in charge of financial records and they ensure
                that financial records are accurate and the taxes are being paid
                properly and timely.
              </td>
            </tr>
            <tr>
              <td>Financial Analyst</td>
              <td>
                Financial Analysts guide businesses and individuals in
                investment decisions based on the performance of stocks, bonds,
                and other types of investments.
              </td>
            </tr>
            <tr>
              <td>Underwriter</td>
              <td>
                Underwriters are professionals who decide whether the insurance
                company should provide insurance to a certain individual or
                business and if yes, then under what terms, they also determine
                coverage amounts and premiums.
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Essential Skills for Actuarial Science</h2>
          <p>
            Actuarial science is a challenging field that demands a diverse
            skill set. Actuaries often find themselves leading teams and
            managing various projects, necessitating the ability to navigate a
            wide range of situations. To excel in this profession, actuaries
            must possess the following key skills:
          </p>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>1. Problem-Solving Skills:</p>
            <li style={{ listStyle: "none" }}>
              Actuaries are tasked with solving complex problems related to risk
              and finance. Strong analytical and critical thinking skills are
              crucial for assessing intricate scenarios and developing effective
              solutions.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>2. Mathematical Aptitude:</p>
            <li style={{ listStyle: "none" }}>
              Actuarial work heavily relies on mathematical models and
              statistical analysis. A solid foundation in mathematics, including
              calculus, probability, and statistics, is essential for actuaries
              to perform accurate calculations and assessments.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>3. Technical Aptitude:</p>
            <li style={{ listStyle: "none" }}>
              Actuaries use various software tools and programming languages to
              analyze data and create financial models. Proficiency in technical
              skills, including the use of actuarial software, programming
              languages like Python or R, and spreadsheet applications, is vital
              for success in this field.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>
              4. Good Communication and Interpersonal Skills:
            </p>
            <li style={{ listStyle: "none" }}>
              Actuaries often collaborate with diverse teams, communicate
              complex findings to non-experts, and interact with clients. Strong
              communication skills, both written and verbal, are essential for
              conveying technical information in a clear and understandable
              manner.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>
              5. Interest and Aptitude for Finance and Business:
            </p>
            <li style={{ listStyle: "none" }}>
              Actuaries work at the intersection of finance and business
              strategy. An inherent interest and understanding of financial
              markets, insurance principles, and business dynamics are crucial
              for making informed decisions and providing valuable insights.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>6. Attention to Detail:</p>
            <li style={{ listStyle: "none" }}>
              Given the precision required in actuarial calculations, an eye for
              detail is paramount. Actuaries must meticulously analyze data and
              ensure accuracy in their assessments to mitigate financial risks
              effectively.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>7. Adaptability:</p>
            <li style={{ listStyle: "none" }}>
              Actuaries often encounter dynamic and evolving situations. The
              ability to adapt to changes in regulations, market conditions, and
              industry trends is essential for staying ahead in this field.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>8. Project Management Skills:</p>
            <li style={{ listStyle: "none" }}>
              Actuaries frequently lead and contribute to multiple projects
              simultaneously. Effective project management skills, including
              prioritization, time management, and coordination, are valuable in
              ensuring successful outcomes.
            </li>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Role of TRG Hybrid in Actuarial Science</h2>
          <p>
            TRG Hybrid provides comprehensive support for actuarial science
            preparation through a blended learning approach.
          </p>
          <div className={styles.innerSection}>
            <h3>Main feature: -</h3>
            <p>
              1. Expert Faculty: Learn from experienced professionals in
              actuarial science.
            </p>
            <p>
              2. Comprehensive Curriculum: Covers all essential topics,
              including mathematics, statistics, and financial theory.
            </p>
            <p>
              3. Blended Learning: Combines interactive in-person classes with
              flexible online resources.
            </p>
            <p>
              4. Regular Assessments: Mock tests and continuous feedback to
              track progress.
            </p>
            <p>
              5. Personalized Support: Individual guidance to address specific
              learning needs.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            Join TRG Hybrid to excel in actuarial science exams with a
            structured and effective preparation strategy.
          </p>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default ActurialScience;
