import React from "react";
import styles from "./trgApp.module.css";
import { Container, Row, Col } from "react-bootstrap";
import trgApp from "./trgApp.webp";
import appStore from "../../../components/icons/appStore.png";
import googlePlay from "../../../components/icons/googlePlay.png";

export default function TrgApp() {
  return (
    <Container fluid>
      <h1 className={styles.headers}>DOWNLOAD TRG LEARNING APP</h1>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "24px 0",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1000px" }}>
          <img style={{ width: "100%", height: "auto" }} src={trgApp} alt="" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row", // Stack links vertically on small screens
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: 50,
        }}
      >
        <a href="https://bit.ly/trg-playstore" target="_blank" rel="noreferrer">
          <img src={googlePlay} width="150" alt="Google Play Store Link" />
        </a>
        <a href="https://bit.ly/trg-appstore" target="_blank" rel="noreferrer">
          <img src={appStore} width="150" height="45" alt="App Store Link" />
        </a>
      </div>
    </Container>
  );
}
