import React, { useState, useEffect, useLayoutEffect } from "react";
import "./style.css";
import { NavLink, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import TeacherstudentsModal from "../../teacher/class/TeacherstudentsClass";
// import { CgProfile } from "react-icons/cg";
// import { AiTwotoneBank } from "react-icons/ai";
// import { SiGoogleclassroom } from "react-icons/si";
import pic_2 from "../../student/dashboard/img/pic_2.png";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import HelpIcon from "@material-ui/icons/Help";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import NotesIcon from "@material-ui/icons/Notes";
import { Button } from "antd";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { teacherAnalytics } from "../../../crud/teacher.crud";

// import line_5_2x from "./img/line-5@2x.svg";
import vector_138_2x from "./img/vector-138@2x.svg";
// import rectangle_7_1x from "./img/rectangle-7@1x.png";
// import ellipse_2_2_2x from "./img/ellipse-2-2@2x.svg";
// import ellipse_3_1_2x from "./img/ellipse-3-1@2x.svg";
import Popup from "../popup/popup";
import { useSelector, useDispatch } from "react-redux";
import { removeToken } from "../../../utils";
import { deleteTeacher } from "../../../redux/actions/teacher";
import SideDrawer from "../../../UI/sideDrawer/sideDrawer";

function Teacherdatecalendar(props) {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility

  // Function to show the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const history = useHistory();
  const user = useSelector((state) => state.teacherDetails.teacher);

  const [selDate, setSelDate] = useState("");
  const [analData, setAnalData] = useState(null);

  useEffect(() => {
    teacherAnalytics(user.teacher_id)
      .then((res) => {
        setAnalData(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.teacherDetails.teacher);
  const handleLogout = () => {
    dispatch(deleteTeacher(reduxUser.teacher_id));
    removeToken();
  };

  const onClickDay = (value, event) => {
    setSelDate(moment(value).format("YYYY-MM-DD"));
    history.push(
      `/teacher-time-calendar/${moment(value).format("YYYY-MM-DD")}`
    );
  };

  const [displaySideDrawer, setDisplaySideDrawer] = useState(false);

  const sideDrawerToggler = () => {
    setDisplaySideDrawer((previousState) => !previousState);
  };

  return (
    <>
      <input
        type="hidden"
        id="anPageName"
        name="page"
        value="teacher-date-calendar"
      />
      <div className="container-center-horizontal">
        <div className="teacher-calendar-dates-mobile screen">
          <div className="component-1-C61RwL">
            <img
              alt="image"
              src={pic_2}
              style={{ height: "90px", marginTop: "-8%" }}
            />
          </div>

          {/* This is the Nav Bar */}
          <SideDrawer
            open={displaySideDrawer}
            closeSideDrawer={sideDrawerToggler}
          />

          <div className="component-6-C61RwL" onClick={sideDrawerToggler}>
            <div className="rectangle-48-5mfU7d"></div>
            <div className="rectangle-49-5mfU7d"></div>
            <div className="rectangle-50-5mfU7d"></div>
          </div>

          <div className="group-124-C61RwL">
            <div className="flex-col-OgaGtS">
              <div className="component-7-x75Vd1">
                <div className="sections-teachi-g-i650263053102-s8ffFt montserrat-semi-bold-gray-20px">
                  Students
                </div>
                <div className="x4-i650263053103-s8ffFt">
                  {analData
                    ? analData.students
                      ? analData.students.length
                      : ""
                    : ""}
                </div>
              </div>
              <div className="component-4-x75Vd1">
                <div className="sections-teachi-g-i650262853102-KyDRqz montserrat-semi-bold-gray-20px">
                  Total
                  <br />
                  Classes
                </div>
                <div className="x4-i650262853103-KyDRqz">
                  {analData && analData.scheduled}
                </div>
              </div>
            </div>
            <div className="flex-col-P2Vk9m">
              <div className="component-8-gT8vIR">
                <div className="sections-teachi-g-i650263153102-87a2V4 montserrat-semi-bold-gray-20px">
                  Classrooms
                </div>
                <div className="x4-i650263153103-87a2V4">
                  {analData
                    ? analData.classrooms
                      ? analData.classrooms.length
                      : ""
                    : ""}
                </div>
              </div>
              <div className="component-5-gT8vIR">
                <div className="sections-teachi-g-i650262953102-xVxRxD montserrat-semi-bold-gray-20px">
                  Completed <br />
                  Classes
                </div>
                <div className="x4-i650262953103-xVxRxD">
                  {analData && analData.completed}
                </div>
              </div>
            </div>
          </div>
          <div className="choose-date-for-opening-slots-C61RwL montserrat-medium-blue-zodiac-20px">
            Choose date for opening slots
          </div>
          <div className="feb-2021-C61RwL">
            <Calendar onClickDay={onClickDay} />
          </div>
        </div>
      </div>
      <div className="container-center-horizontal">
        <div className="teacher-date-calendar screen">
          <div className="sider-teacher-general-div">
            <div className="sider-teacher-in-general-80">
              <div style={{ marginTop: "120px" }} />
              <NavLink to="/teacher-dashboard" exact>
                <DashboardIcon
                  data-tip="Dashboard"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-date-calendar" exact>
                <EventIcon
                  data-tip="Scheduler"
                  className="icon-in-hc-color-2"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-previous-classes">
                <ScheduleIcon
                  data-tip="Previous Class"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-students">
                <SchoolIcon
                  data-tip="Enrolled Student"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-curriculum-list">
                <DescriptionIcon
                  data-tip="Curriculum"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-doubt-list">
                <HelpIcon data-tip="Doubts" className="icon-in-hc-color" />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-analytics">
                <AssessmentIcon
                  data-tip="Analytics"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-assignments">
                <AssignmentIcon
                  data-tip="Assignments"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-quizzes">
                <LibraryBooksIcon
                  data-tip="Quizzes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              <NavLink to="/teacher-lecture-notes">
                <NotesIcon
                  data-tip="Lecture Notes"
                  className="icon-in-hc-color"
                />
                <ReactTooltip />
              </NavLink>
              {/* <NavLink to="/teacher-payments"><AccountBalanceIcon data-tip="Payment" className="icon-in-hc-color" /><ReactTooltip /></NavLink> */}
              <div style={{ marginTop: "10px" }} />
              <NavLink to="/" onClick={() => handleLogout()}>
                {" "}
                <PowerSettingsNewIcon
                  data-tip="Logout"
                  className="icon-in-hc-color"
                />{" "}
              </NavLink>
              <div style={{ marginTop: "1px" }} />
            </div>
          </div>
          <div className="flex-col-C61RwL">

            <div className="flex-row-0xy0vn">
              <div className="component-1-GyJUNH">
                <img src={pic_2} style={{ height: "115px", margin: "-8%" }} />
              </div>

              <div className="group-22-GyJUNH">
                <div className="flex-col-vgli2x">
                  <div className="biba-sobti-h8V8ZR">
                    {user.first_name + " " + user.last_name}
                  </div>
                  <div className="bibasobtigmailcom-h8V8ZR">{user.emailID}</div>
                </div>
                <img className="vector-vgli2x" src={vector_138_2x} />
              </div>
            </div>
            <div className="flex-row-oV5UrE">

              <div className="flex-col-QkTteQ">
                <div className="overlap-group2-Fjgz1C">
                  <div className="group-card-1">
                    <div className="component-9-xRyHXX">
                      <TeacherstudentsModal visible={isModalVisible} onClose={closeModal} />
                      <div className="sections-teachi-g-i650231653102-7m8xJk montserrat-semi-bold-gray-24px">
                      {/* <div className="x4-i650231653103-7m8xJk montserrat-semi-bold-blue-zodiac-64px"> */}
                      <Button
                        type="primary"
                        onClick={showModal}
                        style={{
                          marginTop: "15%",
                          height: "100px",
                          width :"130px",
                          alignItems: "center",
                          borderRadius: "20px", // Rounded corners
                          fontSize: "10px", // Larger text
                          fontWeight: "bold", // Bold text for emphasis
                          background: "linear-gradient(to right, #ff7e5f, #feb47b)", // Beautiful gradient background
                          color: "white", // Text color
                          padding: "0 0px", // Horizontal padding for extra width
                          border: "none", // Remove default border
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
                          transition: "all 0.3s ease-in-out", // Smooth transition effect
                        }}
                        onMouseOver={(e) => {
                          e.target.style.transform = "scale(1.1)"; // Slightly enlarge the button on hover
                        }}
                        onMouseOut={(e) => {
                          e.target.style.transform = "scale(1)"; // Return to original size
                        }}
                      >
                        Open Class Scheduler
                      </Button>
                      {/* </div> */}
                      </div>
                    </div>
                    {/* <div className="component-4-xRyHXX">

                      <div className="sections-teachi-g-i650231553102-LDUMpT montserrat-semi-bold-gray-24px">
                        Students
                      </div>
                      <div className="x4-i650231553103-LDUMpT montserrat-semi-bold-blue-zodiac-64px">
                        {analData
                          ? analData.students
                            ? analData.students.length
                            : ""
                          : ""}
                      </div>
                    </div> */}
                    <div className="component-7-xRyHXX">
                      <div className="sections-teachi-g-i650231653102-7m8xJk montserrat-semi-bold-gray-24px">
                        Classrooms
                      </div>
                      <div className="x4-i650231653103-7m8xJk montserrat-semi-bold-blue-zodiac-64px">
                        {analData
                          ? analData.classrooms
                            ? analData.classrooms.length
                            : ""
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="group-card-2">
                    <div className="component-6-xRyHXX">
                      <div className="sections-teachi-g-i650231753102-wsCHL7 montserrat-semi-bold-gray-24px">
                        Total
                        <br />
                        Classes
                      </div>
                      <div className="x4-i650231753103-wsCHL7 montserrat-semi-bold-blue-zodiac-64px">
                        {analData && analData.scheduled}
                      </div>
                    </div>
                    <div className="component-5-xRyHXX">
                      <div className="sections-teachi-g-i650231853102-rcScQn montserrat-semi-bold-gray-24px">
                        Completed <br />
                        Classes
                      </div>
                      <div className="x4-i650231853103-rcScQn">
                        {analData && analData.completed}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="choose-date-for-opening-slots-Fjgz1C">
                  Choose Date For Opening Slots
                </div>
                <div className="overlap-group-Fjgz1C">
                  <Calendar onClickDay={onClickDay} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}

function Component1(props) {
  const { line5, line6 } = props;

  return (
    <div className="component-1">
      <div className="overlap-group9">
        <img
          alt="image"
          src={pic_2}
          style={{ height: "115px", marginTop: "-8%" }}
        />
      </div>
    </div>
  );
}

function Component44(props) {
  const { sectionsTeachingI5, x4I556364953103, className } = props;

  return (
    <div className={`component-4 ${className || ""}`}>
      <div className="sections-teaching-i5-1 montserrat-semi-bold-gray-24px">
        {sectionsTeachingI5}
      </div>
      <h1 className="x4-i55635 montserrat-semi-bold-blue-zodiac-64px">
        {x4I556364953103}
      </h1>
    </div>
  );
}

function Component73(props) {
  const { sectionsTeachingI5, x4I556365053103 } = props;

  return (
    <div className="component-7">
      <div className="sections-teaching-i5 montserrat-semi-bold-gray-24px">
        {sectionsTeachingI5}
      </div>
      <div className="x4-i556350753103 montserrat-semi-bold-blue-zodiac-64px">
        {x4I556365053103}
      </div>
    </div>
  );
}

function Component6(props) {
  const { sectionsTeachingI5, x4I556350853103 } = props;

  return (
    <div className="component-6">
      <div className="overlap-group3-1">
        <div className="sections-teaching-i5-2 montserrat-semi-bold-gray-24px">
          {sectionsTeachingI5}
        </div>
        <div className="x4-i556350853103 montserrat-semi-bold-blue-zodiac-64px">
          {x4I556350853103}
        </div>
      </div>
    </div>
  );
}

function Component5(props) {
  const { sectionsTeachingI5, x4I556350953103 } = props;

  return (
    <div className="component-5">
      <div className="overlap-group4-1">
        <div className="sections-teaching-i5-2 montserrat-semi-bold-gray-24px">
          {sectionsTeachingI5}
        </div>
        <div className="x4-i556350953103 montserrat-semi-bold-niagara-64px">
          {x4I556350953103}
        </div>
      </div>
    </div>
  );
}

function Join4(props) {
  const {
    vector,
    vector2,
    overlapGroup5,
    vector3,
    vector4,
    vector5,
    vector6,
    vector7,
    vector8,
    vector9,
    vector10,
    vector11,
    vector12,
    vector13,
    vector14,
    vector15,
    vector16,
    vector17,
    vector18,
    vector19,
    vector20,
    vector21,
    vector22,
    vector23,
    vector24,
    vector25,
    vector26,
    vector27,
    vector28,
    vector29,
    vector30,
    vector31,
    vector32,
    vector33,
    vector34,
    vector35,
    vector36,
    vector37,
    vector38,
    vector39,
    vector40,
    vector41,
    vector42,
    vector43,
    vector44,
    vector45,
    className,
  } = props;

  return (
    <div className={`join-4 ${className || ""}`}>
      <div className="overlap-group-3">
        <img className="vector-6" src={vector} />
        <img className="vector-7" src={vector2} />
        <div className="group-1">
          <div
            className="overlap-group-4"
            style={{ backgroundImage: `url(${overlapGroup5})` }}
          >
            <img className="vector-8" src={vector3} />
          </div>
        </div>
        <img className="vector-9" src={vector4} />
        <img className="vector-10" src={vector5} />
        <img className="vector-11" src={vector6} />
        <img className="vector-12" src={vector7} />
        <img className="vector-13" src={vector8} />
        <img className="vector-14" src={vector9} />
        <img className="vector-15" src={vector10} />
        <img className="vector-16" src={vector11} />
        <img className="vector-17" src={vector12} />
        <img className="vector-18" src={vector13} />
        <img className="vector-19" src={vector14} />
        <img className="vector-20" src={vector15} />
        <img className="vector-21" src={vector16} />
        <img className="vector-22" src={vector17} />
        <img className="vector-23" src={vector18} />
        <img className="vector-24" src={vector19} />
        <img className="vector-25" src={vector20} />
        <img className="vector-26" src={vector21} />
        <img className="vector-27" src={vector22} />
        <img className="vector-28" src={vector23} />
        <img className="vector-29" src={vector24} />
        <img className="vector-30" src={vector25} />
        <img className="vector-31" src={vector26} />
        <img className="vector-32" src={vector27} />
        <img className="vector-33" src={vector28} />
        <img className="vector-34" src={vector29} />
        <img className="vector-35" src={vector30} />
        <img className="vector-36" src={vector31} />
        <img className="vector-37" src={vector32} />
        <img className="vector-38" src={vector33} />
        <img className="vector-39" src={vector34} />
        <img className="vector-40" src={vector35} />
        <img className="vector-41" src={vector36} />
        <img className="vector-42" src={vector37} />
        <img className="vector-43" src={vector38} />
        <img className="vector-44" src={vector39} />
        <img className="vector-45" src={vector40} />
        <img className="vector-46" src={vector41} />
        <img className="vector-47" src={vector42} />
        <img className="vector-48" src={vector43} />
        <img className="vector-49" src={vector44} />
        <img className="vector-50" src={vector45} />
      </div>
    </div>
  );
}
const component1Data = {
  line5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/line-5-1@2x.png",
  line6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/line-6@2x.png",
};

const component44Data = {
  sectionsTeachingI5: "Students",
  x4I556364953103: "23",
};

const component73Data = {
  sectionsTeachingI5: "Classrooms",
  x4I556365053103: "23",
};

const component6Data = {
  sectionsTeachingI5: (
    <>
      Total
      <br />
      Classes
    </>
  ),
  x4I556350853103: "23",
};

const component5Data = {
  sectionsTeachingI5: (
    <>
      Completed <br />
      Classes
    </>
  ),
  x4I556350953103: "21",
};

const component442Data = {
  sectionsTeachingI5: "Students",
  x4I556364953103: "23",
};

const component443Data = {
  sectionsTeachingI5: "Students",
  x4I556364953103: "23",
};

const join4Data = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-3@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-4@2x.png",
  overlapGroup5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-33@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-70@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-5@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-6@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-7@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-8@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-9@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-10@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-11@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-12@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-13@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-14@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-15@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-35@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-36@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-37@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-38@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-39@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-40@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-41@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-42@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-43@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-44@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-16@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-17@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-18@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-19@2x.png",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-20@2x.png",
  vector30:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-21@2x.png",
  vector31:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-22@2x.png",
  vector32:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-23@2x.png",
  vector33:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-24@2x.png",
  vector34:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-45@2x.png",
  vector35:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-46@2x.png",
  vector36:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-47@2x.png",
  vector37:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-25@2x.png",
  vector38:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-26@2x.png",
  vector39:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-27@2x.png",
  vector40:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-28@2x.png",
  vector41:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-29@2x.png",
  vector42:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-30@2x.png",
  vector43:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-31@2x.png",
  vector44:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-48@2x.png",
  vector45:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-32@2x.png",
};

const join42Data = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-3@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-4@2x.png",
  overlapGroup5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-33@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-70@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-5@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-6@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-7@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-8@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-9@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-10@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-11@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-12@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-13@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-14@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-15@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-35@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-36@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-37@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-38@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-39@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-40@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-41@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-42@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-43@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-44@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-16@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-17@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-18@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-19@2x.png",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-20@2x.png",
  vector30:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-21@2x.png",
  vector31:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-22@2x.png",
  vector32:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-23@2x.png",
  vector33:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-24@2x.png",
  vector34:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-45@2x.png",
  vector35:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-46@2x.png",
  vector36:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-47@2x.png",
  vector37:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-25@2x.png",
  vector38:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-26@2x.png",
  vector39:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-27@2x.png",
  vector40:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-28@2x.png",
  vector41:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-29@2x.png",
  vector42:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-30@2x.png",
  vector43:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-31@2x.png",
  vector44:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-48@2x.png",
  vector45:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-32@2x.png",
};

const join43Data = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-95@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-96@2x.png",
  overlapGroup5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-125@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-70@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-97@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-98@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-99@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-100@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-101@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-102@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-103@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-104@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-105@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-106@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-107@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-127@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-36@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-129@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-38@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-39@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-132@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-133@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-42@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-135@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-136@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-108@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-109@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-110@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-111@2x.png",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-112@2x.png",
  vector30:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-21@2x.png",
  vector31:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-22@2x.png",
  vector32:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-115@2x.png",
  vector33:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-116@2x.png",
  vector34:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-45@2x.png",
  vector35:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-138@2x.png",
  vector36:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-47@2x.png",
  vector37:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-117@2x.png",
  vector38:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-26@2x.png",
  vector39:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-119@2x.png",
  vector40:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-120@2x.png",
  vector41:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-121@2x.png",
  vector42:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-122@2x.png",
  vector43:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-123@2x.png",
  vector44:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-140@2x.png",
  vector45:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-124@2x.png",
};

const join44Data = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-95@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-96@2x.png",
  overlapGroup5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-125@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-70@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-97@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-98@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-99@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-100@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-101@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-102@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-103@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-104@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-105@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-106@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-107@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-127@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-36@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-129@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-38@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-39@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-132@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-133@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-42@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-135@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-136@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-108@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-109@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-110@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-111@2x.png",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-112@2x.png",
  vector30:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-21@2x.png",
  vector31:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-22@2x.png",
  vector32:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-115@2x.png",
  vector33:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-116@2x.png",
  vector34:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-45@2x.png",
  vector35:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-138@2x.png",
  vector36:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-47@2x.png",
  vector37:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-117@2x.png",
  vector38:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-26@2x.png",
  vector39:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-119@2x.png",
  vector40:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-120@2x.png",
  vector41:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-121@2x.png",
  vector42:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-122@2x.png",
  vector43:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-123@2x.png",
  vector44:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-140@2x.png",
  vector45:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598cf88a1478626e861879/img/vector-124@2x.png",
};

const TeacherdatecalendarData = {
  vector:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-35@2x.png",
  vector2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-34@2x.png",
  vector3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector4:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-153@2x.png",
  vector5:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector6:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6058d518334660f0a4896cec/img/vector-193@2x.png",
  vector7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector8:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-226@2x.png",
  vector9:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector10:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-228@2x.png",
  vector11:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector12:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-237@2x.png",
  vector13:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector14:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-230@2x.png",
  vector15:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector16:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-241@2x.png",
  vector17:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-242@2x.png",
  vector18:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector19:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-166@2x.png",
  vector20:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector21:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-163@2x.png",
  vector22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-162@2x.png",
  vector23:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60598a7321a8a6be592dab73/img/vector-164@2x.png",
  vector24:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-33@2x.png",
  vector25:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-238@2x.png",
  vector26:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-239@2x.png",
  vector27:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-37@2x.png",
  vector28:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-38@2x.png",
  ellipse2:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/ellipse-2@2x.png",
  bibaSobti: "Biba Sobti",
  bibasobtiGmailCom: "bibasobti@gmail.com",
  vector29:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/60578a9495d7e963908e5218/img/vector-36@2x.png",
  text4: "Choose Date For Opening Slots",
  rectangle7:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/605780123e397393f3ab37fb/img/rectangle-7@1x.png",
  spanText: "Feb ",
  spanText2: "2021",
  mon: "Mon",
  number: "28",
  number2: "4",
  number3: "11",
  number4: "18",
  number5: "25",
  tue: "Tue",
  number6: "29",
  number7: "5",
  number8: "12",
  number9: "19",
  ellipse22:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6057904ad14e98ed36b4236c/img/ellipse-2-4@2x.png",
  number10: "26",
  wed: "Wed",
  number11: "30",
  number12: "6",
  number13: "13",
  number14: "20",
  number15: "27",
  thu: "Thu",
  number16: "31",
  number17: "7",
  overlapGroup3:
    "https://anima-uploads.s3.amazonaws.com/projects/602ffc5712181a228ee6b891/releases/6057904ad14e98ed36b4236c/img/ellipse-3-1@2x.png",
  number18: "14",
  number19: "21",
  number20: "28",
  fri: "Fri",
  number21: "1",
  number22: "8",
  number23: "15",
  number24: "22",
  number25: "29",
  sat: "Sat",
  number26: "2",
  number27: "9",
  number28: "16",
  number29: "23",
  number30: "30",
  place: "Sun",
  number31: "3",
  number32: "10",
  number33: "17",
  number34: "24",
  number35: "31",
  date: "Next month",
  number36: "14",
  doubtForum: "Doubt Forum",
  viewAll: "View all",
  text1: "What is the SI unit of Current?",
  viewForum: "View Forum",
  text2: "What is the solution of this question?",
  viewForum2: "View Forum",
  howToSolveThis: "How to solve this?",
  viewForum3: "View Forum",
  text3: "Please check my approach.",
  viewForum4: "View Forum",
  component1Props: component1Data,
  component44Props: component44Data,
  component73Props: component73Data,
  component6Props: component6Data,
  component5Props: component5Data,
  component442Props: component442Data,
  component443Props: component443Data,
  join4Props: join4Data,
  join42Props: join42Data,
  join43Props: join43Data,
  join44Props: join44Data,
};

export default Teacherdatecalendar;
