import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import LoginModal from "../../../components/Login_modal/loginModal";
import Footer from "../../../components/Footer";
import Popup2 from "../../non_auth/new_popup/popup2";
import CustomNav from "../../../components/header/Navbar";
import styles from "./common_css.module.css";

Modal.setAppElement("#root");

function PoliceServices() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [reasonValue, setReasonValue] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  let attclasses = ["login-modal-2", "Close-Landing"];

  if (isModalOpen) {
    attclasses = ["login-modal-2", "Open-Landing"];
  }

  const closeModal = () => setIsModalOpen((previousState) => !previousState);
  return (
    <div>
      <section>
        <CustomNav Open={closeModal} />
      </section>

      <section className={styles.main}>
        <div className={styles.sections}>
          <h1>Police Services Exams</h1>
        </div>
        <div className={styles.sections}>
          <p>
            Police services exams are competitive examinations conducted to
            recruit candidates for various positions in police forces at the
            central and state levels in India. These exams assess candidates on
            various parameters, including physical fitness, mental aptitude, and
            academic knowledge, ensuring they are well-equipped to maintain law
            and order, prevent and investigate crimes, and serve the community.
          </p>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>Main Police Services Exams:</p>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>1. Indian Police Service (IPS)</p>
            <li>Conducted by: Union Public Service Commission (UPSC).</li>
            <li>Exam: Civil Services Examination (CSE).</li>
            <li>Eligibility: Graduation in any discipline.</li>
            <li>Stages: Preliminary Exam, Main Exam, Interview.</li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>2. Delhi Police (DP):</p>
            <p>
              Delhi police is the primary law enforcement agency responsible for
              maintaining law and order preventing and investigating crimes, and
              ensuring the safety and security of the citizens in the National
              Capital Territory of Delhi. It operates under the jurisdiction of
              the Ministry of Home Affairs, Government of India.
            </p>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>3. State Police Services:</p>
            <li>
              Conducted by: State Public Service Commissions (e.g., HP, RP,
              MPSC, UPPSC).
            </li>
            <li>Exam: State Police Service Examination.</li>
            <li>
              Eligibility: Varies by state, generally requires a graduation
              degree.
            </li>
            <li>
              Stages: Preliminary Exam, Main Exam, Interview, Physical Test.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>
              4. Sub-Inspector (SI) and Constable
            </p>
            <li>
              Conducted by: State Police Recruitment Boards and Staff Selection
              Commission (SSC) for Central Armed Police Forces (CAPF).
            </li>
            <li>Exam: SSC CPO Exam, State Police Recruitment Exams.</li>
            <li>
              Eligibility: Varies by position, generally requires 10+2 or
              graduation.
            </li>
          </div>
          <div className={styles.innerSection}>
            <p style={{ fontWeight: "600" }}>5. Stages:</p>
            <p>
              Written Exam, Physical Efficiency Test (PET), Medical Examination,
              Interview (for certain positions).
            </p>
          </div>
          <div className={styles.innerSection}>
            <p>
              These exams are highly competitive and require rigorous
              preparation. Success in these exams opens up rewarding careers in
              law enforcement, offering opportunities to serve the nation and
              ensure public safety.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <h2>Delhi Police Constable Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://sss.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ssc.gov.in
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of Exam</td>
              <td>Delhi Police Constable</td>
            </tr>
            <tr>
              <td>The Conducting Body</td>
              <td>Staff Selection Commission (SSC)</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>National</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>Delhi</td>
            </tr>
            <tr>
              <td>Application Mode</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>Expected: 8000-9000</td>
            </tr>
            <tr>
              <td>Eligibility Criteria</td>
              <td>NatiIntermediate (Science/ Commerce/ Arts)onal</td>
            </tr>
            <tr>
              <td>Exam Medium</td>
              <td>Hindi & English</td>
            </tr>
            <tr>
              <td>Selection Process</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>1. Computer – Based Test</p>
                  <p>2. Physical Efficiency Test</p>
                  <p>3. Physical Measurement Test</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Delhi Police Head Constable Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.delhipolice.nic.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  delhipolice.nic.in/
                </a>
              </td>
            </tr>

            <tr>
              <td>Recruiting Institution</td>
              <td>Staff Selection Commission (SSC)</td>
            </tr>
            <tr>
              <td>Category of the Jobs</td>
              <td>Central Government</td>
            </tr>
            <tr>
              <td>Application Mode</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Location of the jobs</td>
              <td>Delhi</td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>General- Rs.100</li>
                  <li>OBC / SC / ST / PWD / Women / EBC - Nil</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Selection Process</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>1. Computer-Based Online Test</p>
                  <p>2. Physical Endurance Test</p>
                  <p>3. Physical Measurement Test</p>
                  <p>4. Medical Examination</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Helpdesk</td>
              <td>18001020898</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Delhi Police (SI & ASI) Exam (SSC)</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.delhipolice.nic.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  delhipolice.nic.in/
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of Exam</td>
              <td>Delhi Police Constable Recruitment</td>
            </tr>
            <tr>
              <td>The conducting Body</td>
              <td>The Staff Selection Commission</td>
            </tr>
            <tr>
              <td>Post</td>
              <td>Constable (Executive) (Male & Female)</td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>5846 Vacancies</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>National</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>Delhi</td>
            </tr>
            <tr>
              <td>Application Mode</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>1. Unreserved - INR 100/-</p>
                  <p>2. SC/ST/PWD/Women - No Fee</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Exam Mode</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Negative Marking</td>
              <td>0.25 marks</td>
            </tr>
            <tr>
              <td>Exam Medium</td>
              <td>Hindi & English</td>
            </tr>
            <tr>
              <td>Selection Process</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>1. Computer-Based Online Test</p>
                  <p>2. Physical Endurance Test</p>
                  <p>3. Physical Measurement Test</p>
                  <p>4. Medical Examination</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Website To Apply Online</td>
              <td>
                <a
                  href="https://www.delhipolice.nic.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  delhipolice.nic.in/
                </a>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Delhi Police MTS Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>
                Examination Name
              </td>
              <td style={{ width: "50%", fontWeight: "600" }}>
                Delhi Police MTS Recruitment
              </td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://delhipolice.nic.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  delhipolice.nic.in
                </a>
              </td>
            </tr>

            <tr>
              <td>Examination Conducting Authority</td>
              <td>Delhi Police Staff Selection Committee</td>
            </tr>
            <tr>
              <td>Name of the Post</td>
              <td>Multi-Tasking Staff</td>
            </tr>
            <tr>
              <td>Total Vacancies</td>
              <td>To be Announced</td>
            </tr>
            <tr>
              <td>Selection Process</td>
              <td>Written and Trade Test</td>
            </tr>
            <tr>
              <td>Examination Centre Location</td>
              <td>Delhi</td>
            </tr>
            <tr>
              <td>Exam Mode</td>
              <td>Online and Offline</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>UP Police Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="http://uppbpb.gov.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  uppbpb.gov.in/
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of the Exam</td>
              <td>UP Police Recruitment 2022</td>
            </tr>
            <tr>
              <td>Organizing Body</td>
              <td>
                Uttar Pradesh Board Recruitment and Promotion Board (UPPRPB)
              </td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>To be announced soon</td>
            </tr>
            <tr>
              <td>Job Category</td>
              <td>Police Job</td>
            </tr>
            <tr>
              <td>Job Type</td>
              <td>Government</td>
            </tr>
            <tr>
              <td>Name of the Posts</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>Sub Inspector</li>
                  <li>Constable</li>
                  <li>Jail Warden</li>
                  <li>Firemen</li>
                  <li>Driver</li>
                  <li>Ministerial Staff</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>INR 400 (UP Police Sub-Inspector)</li>
                  <li>INR 200 (UP Police Constable)</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Exam Mode</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Duration of Exam</td>
              <td>2 Hours</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>Uttar Pradesh</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Rajasthan Police Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="www.police.rajasthan.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p style={{ wordBreak: "break-word" }}>
                    police.rajasthan.gov.in
                  </p>
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of the Exam</td>
              <td>Rajasthan Police Recruitment 2022</td>
            </tr>
            <tr>
              <td>Conducting Body</td>
              <td>Rajasthan Public Service Commission (RPSC)</td>
            </tr>
            <tr>
              <td>Exam Level</td>
              <td>State</td>
            </tr>
            <tr>
              <td>Exam Frequency</td>
              <td>Once in a year</td>
            </tr>
            <tr>
              <td>Exam Levels</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>Written test</li>
                  <li>Physical Proficiency test</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Mode of Examination</td>
              <td>Offline</td>
            </tr>
            <tr>
              <td>Exam Duration</td>
              <td>120 Minutes</td>
            </tr>
            <tr>
              <td>Language</td>
              <td>English and Hindi</td>
            </tr>
            <tr>
              <td>Post Name/Purpose of the exam</td>
              <td>
                For recruitment of candidates in various post in department of
                Rajasthan Police
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>UPSC CAPF Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.upsc.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  upsc.gov.in
                </a>
              </td>
            </tr>

            <tr>
              <td>CAPF Full Form</td>
              <td>Central Armed Police Forces (Assistant Commandant)</td>
            </tr>
            <tr>
              <td>Conducting Body</td>
              <td>Union Public Service Commission</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>Across India</td>
            </tr>
            <tr>
              <td>Exam Frequency</td>
              <td>Once in a year</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                ></div>
                <li>General/OBC – INR 200</li>
                <li>SC/ST/ Women - Exempted</li>
              </td>
            </tr>
            <tr>
              <td>Exam Mode</td>
              <td>Offline</td>
            </tr>
            <tr>
              <td>Selection Process</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>Written Test</li>
                  <li>Physical Test</li>
                  <li>Interview</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Exam Language</td>
              <td>English and Hindi</td>
            </tr>
            <tr>
              <td>Exam Helpdesk</td>
              <td>011-23098543</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>SSC CPO Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.ssc.nic.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ssc.nic.in
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of the Exam</td>
              <td>SSC CPO 2022</td>
            </tr>
            <tr>
              <td>Conducting body</td>
              <td>Staff Selection Commission (SSC)</td>
            </tr>
            <tr>
              <td>SSC CPO Full Form</td>
              <td>Staff Selection Commission Central Police Organisation</td>
            </tr>
            <tr>
              <td>Post</td>
              <td>Sub-Inspector (SI) and Assistant Sub-Inspector (ASI)</td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>According to Notification</td>
            </tr>
            <tr>
              <td>Exam level</td>
              <td>National</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>Gen/OBC- INR 100/-</td>
            </tr>
            <tr>
              <td>Negative Marking</td>
              <td>Paper-I & II - 0.25</td>
            </tr>
            <tr>
              <td>Selection Procedure</td>
              <td>
                <p>1. Paper-I</p>
                <p>2. Paper-II</p>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>SSC GD Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.ssc.nic.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ssc.nic.in
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of Exam</td>
              <td>SSC GD Constable</td>
            </tr>
            <tr>
              <td>SSC GD Full Form</td>
              <td>Staff Selection Committee General Duty Constable</td>
            </tr>
            <tr>
              <td>Organization Name</td>
              <td>Staff Selection Committee</td>
            </tr>
            <tr>
              <td>Post</td>
              <td>Constable and Rifleman (General Duty)</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>General: INR 100/-</li>
                  <li>Female/SC/ST/Ex-Servicemen: NIL</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Mode of Exam</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Exam Language</td>
              <td>English and Hindi</td>
            </tr>
            <tr>
              <td>Selection process</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <p>1. Computer-Based Examination (CBE)</p>
                  <p>
                    2. Physical Efficiency Test (PET) / Physical Standard Test
                    (PST)
                  </p>
                  <p>3. Medical Exam</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>Across nation</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>BSF Tradesman Recruitment Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.bsf.nic.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  bsf.nic.in
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of the Exam</td>
              <td>BSF Tradesman Recruitment</td>
            </tr>
            <tr>
              <td>Organizing Body</td>
              <td>Border Security Force, BSF</td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>According to notification</td>
            </tr>
            <tr>
              <td>Job Category</td>
              <td>Police Job</td>
            </tr>
            <tr>
              <td>Job Type</td>
              <td>Government</td>
            </tr>
            <tr>
              <td>Name of the Posts</td>
              <td>Constable Tradesman</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>General/OBC/EWS Rs. 100/-</li>
                  <li>SC/ST/ESM/Female Exempted</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Duration of Exam</td>
              <td>2 Hours</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>CRPF GD Constable Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.rect.crpf.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  rect.crpf.gov.in
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of the Exam</td>
              <td>CRPF GD Constable</td>
            </tr>
            <tr>
              <td>Organizing Body</td>
              <td>Staff Selection Commission</td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>According to Notification</td>
            </tr>
            <tr>
              <td>Job Category</td>
              <td>Police Job</td>
            </tr>
            <tr>
              <td>Job Type</td>
              <td>Government</td>
            </tr>
            <tr>
              <td>Name of the Posts</td>
              <td>Constable (General Duty)</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Application Fees</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <li>General/OBC/EWS Rs. 100/-</li>
                  <li>SC/ST/ESM/Female Exempted</li>
                </div>
              </td>
            </tr>
            <tr>
              <td>Online CRPF GD Constable Application Dates</td>
              <td>November 24 to December 31, 2023</td>
            </tr>
            <tr>
              <td>Duration of Exam</td>
              <td>2 Hours</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>All Over India</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>CISF Head Constable Ministerial Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://www.cisfrectt.cisf.gov.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cisfrectt.cisf.gov.in
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of the Exam</td>
              <td>CISF Head Constable Ministerial</td>
            </tr>
            <tr>
              <td>Organizing Body</td>
              <td>Central Industrial Security Force</td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>Out Soon</td>
            </tr>
            <tr>
              <td>Job Category</td>
              <td>Police Job</td>
            </tr>
            <tr>
              <td>Job Type</td>
              <td>Government</td>
            </tr>
            <tr>
              <td>Name of the Posts</td>
              <td>Head Constable (Ministerial)</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>CISF Head Constable Notification Release Status</td>
              <td>Out Soon</td>
            </tr>
            <tr>
              <td>Eligibility</td>
              <td>12th Pass, 18 years</td>
            </tr>
            <tr>
              <td>Duration of Exam</td>
              <td>2 Hours</td>
            </tr>
            <tr>
              <td>Job Location</td>
              <td>Anywhere in India</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Indian Army Agni Veer Exam</h2>
          <table style={{ width: "100%" }}>
            <tr>
              <td style={{ width: "50%", fontWeight: "600" }}>Particulars</td>
              <td style={{ width: "50%", fontWeight: "600" }}>Details</td>
            </tr>
            <tr>
              <td>Official Website</td>
              <td>
                <a
                  href="https://joinindianarmy.nic.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p style={{ wordBreak: "break-word" }}>
                    joinindianarmy.nic.in
                  </p>
                </a>
              </td>
            </tr>

            <tr>
              <td>Name of Scheme</td>
              <td>Agni veer Yojana</td>
            </tr>
            <tr>
              <td>Launched by</td>
              <td>Central Government</td>
            </tr>
            <tr>
              <td>Name of Post</td>
              <td>Various</td>
            </tr>
            <tr>
              <td>Service Duration</td>
              <td>4 years</td>
            </tr>
            <tr>
              <td>Mode of Application</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>Selection Process</td>
              <td>Common Entrance Test, Physical Test, Medical Test</td>
            </tr>
            <tr>
              <td>Vacancies</td>
              <td>To be released</td>
            </tr>
            <tr>
              <td>Training Duration</td>
              <td>10 Weeks to 6 months</td>
            </tr>
            <tr>
              <td>Qualification Required</td>
              <td>8th/10th/12th Pass</td>
            </tr>
          </table>
        </div>
        <div className={styles.sections}>
          <h2>Achieve Your Goals with THE RIGHT GURU HYBRID coaching Centre</h2>
          <p>
            The TRG Hybrid Classroom Program is designed to provide
            comprehensive and effective preparation for candidates aspiring to
            join various police services. Our innovative approach combines
            traditional classroom learning with the flexibility of online
            resources, ensuring a thorough and adaptable preparation strategy.
            By integrating these features, the TRG Hybrid Classroom Program
            ensures a robust, flexible, and supportive learning environment that
            helps candidates excel in their police services exams. Join us to
            experience a comprehensive educational journey tailored to meet your
            career goals in law enforcement.
          </p>
        </div>
        <div className={styles.sections}>
          <div className={styles.innerSection}>
            <p>
              <span className={styles.bold}>For any query,</span>
            </p>

            <p className={styles.content_text}>
              <span className={styles.bold}>Mobile No: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+919570424242"
              >
                9570-424242
              </a>
            </p>
            <p>
              <span className={styles.bold}>WhatsApp: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wa.me/917419197878"
              >
                74-1919-7878
              </a>
            </p>
            <p>
              <span className={styles.bold}>Email: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:contact@therightguru.com"
              >
                contact@therightguru.com
              </a>
            </p>
            <p>
              <span className={styles.bold}>Website: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://therightguru.com"
              >
                https://therightguru.com
              </a>
            </p>

            <p>
              For a more personalized and detailed understanding, we encourage
              you to visit our centers and consult our dedicated counselors.
            </p>
          </div>
        </div>
        <div className={styles.sections}>
          <p>
            <span
              className={styles.bold}
              style={{ textDecoration: "underline" }}
            >
              Note:
            </span>{" "}
            The information above is a publicly available information on various
            respective government portals, this information is correct at the
            time of its publication and to obtain current information please
            visit relevant portal. TRG shall not be liable in any way for any
            reliance placed on its website published content which is merely for
            information and generic guidance.
          </p>
        </div>
      </section>

      <section>
        <Footer />
      </section>
      <LoginModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Popup2 />
    </div>
  );
}

export default PoliceServices;
