import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import styles from "./carousal.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BeatLoader from "react-spinners/BeatLoader";
import Home from "./home.webp";

function Corosal() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.therightguru.com/api/carousel-entry/"
      );
      setData(response.data);
      sessionStorage.setItem("carouselData", JSON.stringify(response.data));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem("carouselData");
    if (storedData) {
      setData(JSON.parse(storedData));
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  if (loading) {
    return (
      <div className={styles.loader}>
        <div
          style={{
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
          }}
          role="status"
        >
          <BeatLoader color="#dc3545" />
          <span style={{ fontSize: "15px" }}>Loading Carousel...</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div style={{ width: "100%", height: "auto" }}>
        <div
          id="carouselExampleInterval"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="5000">
              <a href="/">
                <img src={Home} className="d-block w-100" />
              </a>
            </div>
            {data &&
              data.map((item, index) =>
                index === 0 ? (
                  <div
                    className="carousel-item "
                    data-bs-interval="5000"
                    key={index}
                  >
                    <a href={item.redirectLink}>
                      <img
                        src={item.imgUrl}
                        className="d-block w-100"
                        alt={item.name}
                      />
                    </a>
                  </div>
                ) : (
                  <div
                    className="carousel-item"
                    data-bs-interval="5000"
                    key={index}
                  >
                    <a href={item.redirectLink}>
                      <img
                        src={item.imgUrl}
                        className="d-block w-100"
                        alt={item.name}
                      />
                    </a>
                  </div>
                )
              )}
          </div>
          {/* Carousel controls */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Corosal;
